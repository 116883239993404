import React, {useEffect} from 'react';
import ArticleDeliveryStatsProperties from "./ArticleDeliveryStatsProperties";
import {useStyles} from "./ArticleDeliveryStats.style";
import ArticleDeliveryStatsStore from "./ArticleDeliveryStatsStore";
import {useCommonContext} from "../../../contexts/CommonContext";
import {Observer} from "mobx-react-lite";
import clsx from "clsx";
import PageLoading from "../../../ui_components/PageLoading/PageLoading";
import {useI18nContext} from "../../../contexts/I18nContext";
import { useUserContext } from '../../../contexts/UserContext';

const ArticleDeliveryStats: React.FC<ArticleDeliveryStatsProperties> = ({
                                                                className = '',
                                                            }) => {

    const i18n = useI18nContext();
    const commonStore = useCommonContext();
    const userStore = useUserContext();
    const store = new ArticleDeliveryStatsStore(commonStore, userStore.currentUser()?.id);
    const classes = useStyles();

    useEffect(() => {
        store.getArticleDeliveryStats();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onclick = () => {
        window.location.href = "/article-remittances";
    }

    return <Observer>{() => (
        <div className={clsx(classes.total_sales_container, classes.flex_column, className)}>
            {<div className={classes.title}>{'Servicio Artículo'}</div>}
            <div className={clsx(classes.flex_row, classes.body)}>
                {store.articleDeliveryStats &&
                    <>
                        <div className={clsx(classes.box_container, classes.flex_column)} onClick={onclick}>
                            <div>{store.articleDeliveryStats?.deliveryAmount || 0}</div>
                            <div>Pendientes</div>
                        </div>
                    </>}
                <PageLoading className={classes.loading_container} loadingSize={40} isLoading={store.loading}
                             text={`${i18n.translate("LOADING")} ${'Artículo' || ''}`}/>
            </div>
        </div>
    )}</Observer>

}


export default ArticleDeliveryStats;