import React, {useEffect} from 'react';
import {useI18nContext} from "../../contexts/I18nContext";
import {useStyles} from "./ArticleDelivery.style";
import Box from "../../ui_components/Box/Box";
import Width from "../../ui_components/common/enums/Width";
import Alignment from "../../ui_components/common/enums/Alignment";
import {Observer} from "mobx-react-lite";
import MessageCard from "../../ui_components/MessageCard/MessageCard";
import FastfoodIcon from '@mui/icons-material/Fastfood';
import {useCommonContext} from "../../contexts/CommonContext";
import ArticleDeliveryStore from './ArticleDeliveryStore';
import ArticleDeliveryRow from './ArticleDeliveryRow/ArticleDeliveryRow';
import UserStore from '../../stores/UserStore';
import ButtonType from '../../ui_components/Button/ButtonType';
import Checkbox from '../../ui_components/Checkbox/Checkbox';
import ArticleDeliveryPrintButton from '../../shared_components/Buttons/ArticleDeliveryPrintButton/ArticleDeliveryPrintButton';
import FilterInput from '../../shared_components/Inputs/FilterInput/FilterInput';
import DateTimeRangeSelect from '../../ui_components/DateTimeRageSelect/DateTimeRangeSelect';
import GeoSelect from '../../shared_components/Selects/GeoSelect/GeoSelect';
import Button from '../../ui_components/Button/Button';
import Geo from '../../entities/Geo';
import { GeoLevel } from '../../shared_components/Selects/GeoSelect/GeoSelectProperties';

const ArticleDelivery: React.FC = () => {
    const commonStore = useCommonContext();
    const userStore = new UserStore();
    const i18n = useI18nContext();
    const store = new ArticleDeliveryStore(commonStore, userStore);
    const classes = useStyles();

    useEffect(() => {
        store.getElements();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onArticleDeliveryOrderSelect = (articleRemittanceId: string) => {
        const index = store.selectedArticleRemittanceIds.findIndex(t => t === articleRemittanceId);
        store.selectedArticleRemittanceIds = index >= 0 ?
            store.selectedArticleRemittanceIds.filter(t => t !== articleRemittanceId) :
            [...store.selectedArticleRemittanceIds, articleRemittanceId];
    }

    const onDateChange = (from: number | undefined, to: number | undefined) => {
        store.setFromFilter(from);
        store.setToFilter(to);
    }

    const onGeoChange = (newGeo?: Geo) =>
        (store.setGeoFilter(newGeo));

    const clearFilter = async () => {
        await store.clearFilter()
    }

    const onUpdate = async () => {
        await store.getElements();
    }

    const onArticleDeliveryOrderToggleSelectAll = (value: boolean) => {
        store.selectedArticleRemittanceIds = value ? store.articleRemittances.map(t => t.id) : [];
    }

    return (
        <Observer>
            {() => (
                <div className={classes.article_container}>
                <Box className={classes.filter_container} width={Width.FULL_WIDTH}
                         justifyContent={Alignment.SPACE_BETWEEN}>
                        <FilterInput value={store.filter}
                                     placeholder={i18n.translate("MONEY_DELIVERY_ORDER_FILTER_PLACEHOLDER")}
                                     onChange={(value) => {
                                         store.setFilter(value)
                                     }} onEnter={() => store.search()}/>
                        <DateTimeRangeSelect selectedFrom={store.fromFilter} selectedTo={store.toFilter}
                                             label={i18n.translate("DATES")}
                                             onChange={onDateChange}/>
                        <GeoSelect selected={store.geoFilter} levels={[GeoLevel.CITY, GeoLevel.STATE]}
                                   onChange={onGeoChange}/>
                        <div className={classes.filter_buttons_container}>
                            <Button
                                onClick={() => {
                                    store.search()
                                }}
                                type={ButtonType.PRIMARY}
                                className={classes.search_btn}
                                text={i18n.translate("SEARCH")}
                            />
                            <Button
                                onClick={clearFilter}
                                className={classes.search_btn}
                                text={i18n.translate("CLEAN")}
                            />
                        </div>
                    </Box>
                    <Box className={classes.filter_container} width={Width.FULL_WIDTH}
                         justifyContent={Alignment.SPACE_BETWEEN}>                        
                        <div className={classes.options_buttons_container}>
                            <Checkbox text={''} onChange={onArticleDeliveryOrderToggleSelectAll} className={classes.select_all_checkbox}/>
                            <ArticleDeliveryPrintButton productOrderIds={store.selectedArticleRemittanceIds}
                                disabled={store.selectedArticleRemittanceIds.length === 0}
                                showText={true}
                                type={ButtonType.SECONDARY}/>
                        </div>
                        
                    </Box>
                    {!store.isEmpty &&
                    <div className={classes.article_table}>
                        <div className={classes.elements_container}>
                            {store.articleRemittances.map((articleRemittance, i) => (
                                <ArticleDeliveryRow
                                    selectedDeliveryOrderIds={store.selectedArticleRemittanceIds}
                                    onArticleDeliveryOrderSelect={onArticleDeliveryOrderSelect}
                                    articleDeliveryOrder={articleRemittance} key={i}
                                    onUpdate={onUpdate}
                                />
                            ))}
                        </div>
                    </div>}
                    {store.isEmpty &&
                    <MessageCard icon={FastfoodIcon} title={i18n.translate("NO_ARTICLE_REMITTANCES_TITLE")}
                                subtitle={i18n.translate("NO_ARTICLE_REMITTANCES_SUBTITLE")}/>}
                </div>
            )}
        </Observer>
    )
}

export default ArticleDelivery;
