import React from 'react';
import CurrencySelectProperties from "./CurrencySelectProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import SelectOption from "../../../ui_components/Select/SelectOption";
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import Select from "../../../ui_components/Select/Select";
import Currency from "../../../entities/Currency";

const CurrencySelect: React.FC<CurrencySelectProperties> = ({
                                                                        selected,
                                                                        showSelectAllOption = false,
                                                                        label = '',
                                                                        onChange,
                                                                        disabled,
                                                                        fullWidth = false,
                                                                        className = '',
                                                                        error
                                                                    }) => {

    const i18n = useI18nContext();

    let opt = showSelectAllOption ? [new SelectOption(i18n.translate("ALL_FEMALE"), "ALL", 'Todas las monedas', LocalAtmIcon)] : [];

    opt.push(...[
        new SelectOption(i18n.translate("DOLLAR"), "DOLLAR", i18n.translate("DOLLAR"), LocalAtmIcon),
        new SelectOption(i18n.translate("MN"), "MN", i18n.translate("MONEDA_NACIONAL"), LocalAtmIcon),
        new SelectOption('MLC', "MLC", 'MLC', LocalAtmIcon),
        new SelectOption(i18n.translate("EURO"), "EURO", i18n.translate("EURO"), LocalAtmIcon),
    ]);

    const selectedOption = opt.find(t => t.value === selected);

    const onStatusChange = (option: SelectOption) => {
        onChange(option.value as Currency)
    }

    return (
        <Select
            error={error}
            className={className}
            disabled={disabled}
            fullWidth={fullWidth}
            label={label && label !== '' ? label : i18n.translate("CURRENCY")}
            placeholder={i18n.translate("CURRENCY_PLACEHOLDER")}
            selected={selectedOption}
            icon={LocalAtmIcon}
            onChange={onStatusChange}
            elements={opt}
        />
    )
}


export default CurrencySelect;