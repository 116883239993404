import axios, {AxiosRequestConfig} from 'axios';
import Request from "../model/Request";
import Response from "../model/Response";
import Token from "../model/Token";

export module RestClient {

    export async function get(request: Request): Promise<Response<any>> {
        try {
            const axiosResponse = await axios.get(request.url, getConfig());
            const response = new Response<any>(true, axiosResponse.data);
            return Promise.resolve(response);
        } catch (e) {
            return getErrorResponse(e);
        }
    }

    export async function post(request: Request): Promise<Response<any>> {
        try {
            const axiosResponse = await axios.post(request.url, request.data, getConfig());
            const response = new Response<any>(true, axiosResponse.data);
            return Promise.resolve(response);
        } catch (e) {
            return getErrorResponse(e);
        }
    }

    export async function put(request: Request): Promise<Response<any>> {
        try {
            const axiosResponse = await axios.put(request.url, request.data, getConfig());
            const response = new Response<any>(true, axiosResponse.data);
            return Promise.resolve(response);
        } catch (e) {
            return getErrorResponse(e);
        }
    }
    export async function remove(request: Request): Promise<Response<any>> {
        try {
            const axiosResponse = await axios.delete(request.url, getConfig());
            const response = new Response<any>(true, axiosResponse.data);
            return Promise.resolve(response);
        } catch (e) {
            return getErrorResponse(e);
        }
    }
}

const getConfig = (): AxiosRequestConfig => {
    const token = new Token();
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token.value}`,
            'Hash': `1234`,
        }
    };
}

const getErrorResponse = (e: any): Promise<Response<any>> => {
    const message = e.response?.data?.message;
    const status = e.response?.status;
    const response = new Response<any>(false, undefined, message, status);
    return Promise.resolve(response);
}