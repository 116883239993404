import React, {useRef} from 'react';
import PackageDeliveryPrintButtonProperties from "./PackageDeliveryPrintButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import PackageDeliveryPrintStore from "./PackageDeliveryPrintStore";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./PackageDeliveryPrintButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import PrintButton from "../PrintButton/PrintButton";
import SidePanel from "../../../ui_components/SidePanel/SidePanel";
import clsx from 'clsx';
import ItemsAndCategories from '../../../model/productOrder/packageDelivery/ItemsAndCategories';
import { useUserContext } from '../../../contexts/UserContext';
import CustomCategoryType from '../../../entities/CustomCategoryType';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import Print from './Print';

const PackageDeliveryPrintButton: React.FC<PackageDeliveryPrintButtonProperties> = ({
                                                                      onClick = () => {
                                                                      },
                                                                      onSave = () => {
                                                                      },
                                                                      children,
                                                                      showIcon = true,
                                                                      showText = true,
                                                                      disabled,
                                                                      className = '',
                                                                      productOrderIds,
                                                                      rounded = false,
                                                                      showButton = true,
                                                                      size = ButtonSize.DEFAULT,
                                                                      type = ButtonType.DEFAULT
                                                                  }) => {
    const classes = useStyles(showText);

    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const userStore = useUserContext();
    const store = new PackageDeliveryPrintStore(commonContext, productOrderIds, userStore.currentUser()!!.id);
    const testRef = useRef<Print | null>(null);

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const onCloseModal = () => {
        store.closeModal();
        if (onSave) {
            onSave();
        }
    }

    const text = "Imprimir";
    const Icon = LocalPrintshopIcon;
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? <Icon/> : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? <Icon/> : <></>}
        />
    }

    function formatDate(timestamp: number): string {
        const date = new Date(timestamp);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear());

        return `${day}/${month}/${year}`;
    }

    const formatPrice = (price: number | bigint) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(price);
    };

    const InvoiceData3: React.FC<{ itemsAndCategories : ItemsAndCategories}> = ({itemsAndCategories}) => {
        const items = [];
        for (const item of itemsAndCategories.packageItem) {
            items.push({
                quantity: item.amount,
                name: item.name,
                price: item.finalItemPrice,
            });
        }

        return (
            <Table>
                <TableBody>
                    {items.map((row) => (
                        <TableRow key={row.name}>
                            <TableCell className={classes.columnOne} style={{padding: '0px', borderBottom: 'none',}}>{row.quantity}</TableCell>
                            <TableCell className={classes.columnTwo} style={{padding: '0px', borderBottom: 'none',}}>{row.name}</TableCell>
                            <TableCell className={classes.columnThree} style={{padding: '0px', borderBottom: 'none',}}>{itemsAndCategories.categoryInfo.categoryType === CustomCategoryType.INDIVIDUAL ? formatPrice(row.price) : ''}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        );
    };

    const InvoiceData: React.FC<{ signerRole: string }> = ({signerRole}) => {
        return <div className={classes.invoice_data_container}>
            {store.packageDeliveryOrders.map((packageDeliveryOrder, i) => (<div className={clsx(classes.body, i > 0 && classes.order_item_container, i > 0 && classes.page_break)}>
            <div className={classes.invoice}>
                    <div className={classes.top_header}>
                        <div className="header">
                            <div className={classes.name}><b style={{ textTransform: 'uppercase', fontSize: '20px' }}>Alocuba Travel</b></div>
                            <div className={classes.name}><b style={{ textTransform: 'uppercase' }}>7864914368</b></div>
                            <div className={classes.name}><b style={{ textTransform: 'uppercase', whiteSpace: 'nowrap' }}>9764 SW 24 ST MIAMI FL 33165</b></div>
                            <div className={classes.name}><b style={{ textTransform: 'uppercase' }}>ALOCUBATRAVEL@GMAIL.COM</b></div>

                        </div>
                        <div className={classes.resume} id="project">
                            <div className={classes.resume_container}>
                                <div className={classes.column_name}>
                                    {packageDeliveryOrder.orderNumber !== null && (
                                        <div className={classes.name}><b>{"No Orden:"}</b></div>
                                    )}
                                    {packageDeliveryOrder.createdAt !== null && (
                                        <div className={classes.name}><b>{"Fecha:"}</b></div>
                                    )}
                                </div>
                                <div className={classes.column_data}>
                                    {packageDeliveryOrder.orderNumber !== null && (
                                        <div className={classes.data}>{packageDeliveryOrder.orderNumber}</div>
                                    )}
                                    {packageDeliveryOrder.createdAt !== null && (
                                        <div className={classes.data}>{formatDate(packageDeliveryOrder.createdAt)}</div>
                                    )}
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className={clsx(classes.top_header,classes.margin_top_4_deliver)}>

                        <div className="header">
                            <div style={{ width: '600px' }}><b>{"Destinatario"}</b></div>
                            <div>{packageDeliveryOrder.contact?.name + (packageDeliveryOrder.contact?.lastName ? ' ' + packageDeliveryOrder.contact?.lastName : '')}</div>
                            <div>{packageDeliveryOrder.contact?.address + '. ' + (packageDeliveryOrder.contact?.neighborhood ? `${packageDeliveryOrder.contact?.neighborhood}. ` : '') + packageDeliveryOrder.contact?.geo.name + '. ' + packageDeliveryOrder.contact?.geo.parentInfo}</div>

                            <div className={classes.phone_text}>
                                <div>
                                    <div className={classes.spaced_text}>
                                        <div style={{marginRight:'10px'}}>{packageDeliveryOrder.contact?.mobilePhone && packageDeliveryOrder.contact?.mobilePhone.number !== "" && (<span><b>Celular:</b> {packageDeliveryOrder.contact?.mobilePhone.number}</span>)}</div>
                                        <div style={{marginRight:'10px'}}>{packageDeliveryOrder.contact?.homePhone && packageDeliveryOrder.contact?.homePhone.number !== "" && (<span><b>Fijo:</b> {packageDeliveryOrder.contact?.homePhone.number}</span>)}</div>
                                        <div >{packageDeliveryOrder.contact?.homePhone && packageDeliveryOrder.contact?.homePhone.number !== "" && (<span><b>Fijo:</b> {packageDeliveryOrder.contact?.homePhone.number}</span>)}</div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div  className={classes.resume} id="project">
                            <div className={classes.resume_container}>
                                <div className={classes.column_name}>
                                    <div><b>{"Remitente"}</b></div>
                                    <div>{packageDeliveryOrder.clientName}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {packageDeliveryOrder.itemsAndCategories.map((itemsAndCategories) => (
                        <div className={classes.margin_top_8_deliver} >
                            <div key={packageDeliveryOrder.id}>
                                {
                                    (itemsAndCategories.categoryInfo.categoryType === CustomCategoryType.GROUP ||
                                        itemsAndCategories.categoryInfo.categoryType === CustomCategoryType.INDIVIDUAL) && (
                                        <div className={classes.name}>
                                            <b>
                                                {itemsAndCategories.categoryInfo.name} ({itemsAndCategories.packageItem.length})
                                                {itemsAndCategories.categoryInfo.categoryType === CustomCategoryType.GROUP && (
                                                    ` - ${itemsAndCategories.weightInfo?.weight} lbs`
                                                )}
                                            </b>
                                        </div>
                                    )
                                }
                            </div>
                            <InvoiceData3 itemsAndCategories={itemsAndCategories}/>
                        </div>
                    ))}
                    <div className={classes.additional_text}>
                        <div>
                            <div className={classes.spaced_text}>
                                <>
                                    <div>Firma del Destinatario: ___________________</div>
                                    <div className={classes.margin_left}>Firma del Repartidor: ___________________</div>
                                </>
                            </div>
                        </div>
                    </div>

                </div>
            </div>))}
        </div>
    }

    return <Observer>
        {() => (
            <>
                <SidePanel loading={store.loading} title={`Orden de envio`}
                           onClose={onCloseModal} open={store.isModalOpen} className={classes.modal_container}>
                    <div className={classes.view_invoice_container}>
                        <div className={classes.toolbar_container}>
                            <PrintButton type={ButtonType.DEFAULT} multiPages={true} onClick={() => {
                                testRef.current?.onPrint();
                            }}/>
                        </div>
                        <Print ref={testRef} otherStyle={`.page-break { page-break-before: always; }`}>
                            <div className={classes.invoice_container}>
                                <div>
                                    <InvoiceData signerRole={i18n.translate("ADDRESSEE")}/>
                                </div>
                            </div>
                        </Print>
                    </div>
                </SidePanel>
                {showButton && <Btn/>}
                {!showButton && <div onClick={handleOnclick}>{children}</div>}
            </>
        )}
    </Observer>
}

export default PackageDeliveryPrintButton;
