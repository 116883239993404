import React, {createRef, useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import {useStyles} from "./SideMenuSmall.style";
import RouteRepository from "../../../../route/RouteRepository";
import clsx from "clsx";
import {useI18nContext} from "../../../../contexts/I18nContext";
import {useUserContext} from "../../../../contexts/UserContext";
import MenuIcon from '@material-ui/icons/Menu';
import {motion} from "framer-motion";
import ShowSellerBasicInfo from "../../../../shared_components/Info/ShowSellerBasicInfo/ShowSellerBasicInfo";
import AllowAccess from "../../../../shared_components/Access/AllowAccess/AllowAccess";
import LogoutButton from '../../../../shared_components/Buttons/LogoutButton/LogoutButton';
import ButtonSize from '../../../../ui_components/Button/ButtonSize';

const SideMenuSmall: React.FC = () => {
    const classes = useStyles();
    const [showMenu, setShowMenu] = useState(false);
    const menuRef = createRef<HTMLDivElement>();

    const i18n = useI18nContext();
    const userStore = useUserContext();
    const routes = RouteRepository.getRoutes();
    const location = useLocation();
    const user = userStore.currentUser();
    const currentPath = location.pathname;

    useEffect(() => {
        const clickOutside = (event: any) => {
            if (menuRef && !menuRef.current?.contains(event.target)) {
                setShowMenu(false);
            }
        }
        document.addEventListener('mousedown', clickOutside);
        return () => {
            document.removeEventListener('mousedown', clickOutside)
        }
    }, [menuRef])

    return (
        <div className={classes.side_menu_container}>
            <div className={classes.menu_button_container}>
                <div className={classes.menu_button} onClick={() => setShowMenu(!showMenu)}><MenuIcon/></div>
                {showMenu &&
                <motion.div className={classes.menu_items_container} ref={menuRef} exit={{opacity: 0, x: -50}} initial={{opacity: 0, x: -50}} animate={{opacity: 1, x: 0}}>
                    {/*<div className={classes.main_action_container}>*/}
                    {/*    <NewTopupButton showText={false}/>*/}
                    {/*</div>*/}
                    {/* <div className={classes.main_action_container}>
                        <NewFoodPackageRemittanceButton showText={false}/>
                    </div>
                    <div className={classes.main_action_container}>
                        <NewMoneyDeliveryButton showText={false}/>
                    </div> */}
                    <div>
                        {routes.map(r => (
                           <AllowAccess key={r.path} any={r.access}>
                               <Link onClick={() => setShowMenu(false)}  to={r.path}
                                     className={clsx(classes.menu_item_container, r.isActive(currentPath) && classes.active)}>
                                   <r.icon/>
                                   <div>{i18n.translate(r.label)}</div>
                               </Link>
                           </AllowAccess>
                        ))}
                    </div>
                </motion.div>
                }
            </div>
            <ShowSellerBasicInfo autofill light email={user?.email} name={user?.name} className={classes.user_info_container}/>
            <div className={classes.logout_btn_container}>
                <LogoutButton size={ButtonSize.SMALL} showText={false}/>
            </div>
        </div>
    );
}

export default SideMenuSmall;