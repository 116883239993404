import React from 'react';
import TransactionsRowProperties from "./TransactionsRowProperties";
import ViewMedium from "../../../ui_components/MediaView/ViewMedium";
import TransactionsRowMedium from "./TransactionsRowMedium";
import ViewLarge from "../../../ui_components/MediaView/ViewLarge";
import ViewSmall from "../../../ui_components/MediaView/ViewSmall";
import TransactionsRowSmall from "./TransactionsRowSmall";

const TransactionsRow: React.FC<TransactionsRowProperties> = ({
    transaction
                                                }) => {
    return (
        <>
            <ViewSmall>
                <TransactionsRowSmall transaction={transaction}/>
            </ViewSmall>
            <ViewMedium>
                <TransactionsRowMedium transaction={transaction}/>
            </ViewMedium>
            <ViewLarge>
                <TransactionsRowMedium transaction={transaction}/>
            </ViewLarge>
        </>
    )
}

export default TransactionsRow;
