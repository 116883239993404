import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    main_container: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        width: '100%',
        overflow: 'hidden'
    },
    content_container: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    outlet: {
        padding: '16px',
        position: 'relative',
        flexGrow: 1,
        overflow: 'scroll',
        boxSizing: 'border-box',
    }
}));

export {useStyles}