import React, {useEffect, useRef, useState} from 'react';
import ShowCommentsProperties from "./ShowCommentsProperties";
import {useStyles} from "./ShowComments.style";
import clsx from "clsx";
import ShowComment from '../ShowComment/ShowComment';
import DeliveryOrderComment from '../../../entities/productOrder/DeliveryOrderComment';
import CommentInput from '../../Inputs/CommentInput/CommentInput';
import Button from '../../../ui_components/Button/Button';

const ShowComments: React.FC<ShowCommentsProperties> = ({
                                                          loguedUserId,
                                                          comments = [],
                                                          onNewComment = () => {
                                                          },
                                                          className = '',
                                                      }) => {

    const classes = useStyles();
    const [comment, setComment] = useState('');

    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setTimeout(() => {
            if (divRef.current) {
                divRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }
        }, 100);
    }, []);

    useEffect(() => {
        setComment(comment);
    }, [comment]);

    const onCommentChange = (newComment: string) => (setComment(newComment));

    const handleNewCommentOnclick = async () => {
        if (comment !== null && comment !== '') {
            onNewComment(comment);
            setComment('');
            setTimeout(() => {
                if (divRef.current) {
                    divRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
                }
            }, 100);
        }
    }

    return (
        <div className={clsx(classes.show_comments_container, className,classes.empty)}>
            <div className={classes.comment_container}>
                {comments.map((r: DeliveryOrderComment) => (
                    <ShowComment isOwnComment={loguedUserId === r.userId} value={r.comment} createdAt={r.createdAt}/>
                ))}
                {comments && comments.length === 0 && <span>No existen comentarios para este envio</span>}
                <div ref={divRef} />
            </div>
            
            <div className={clsx(classes.new_comment_container, comments && comments.length === 0 && classes.no_item_container)}>
                <CommentInput
                    value={comment}
                    fullWidth
                    lines={1}
                    onChange={onCommentChange}
                    className={classes.input}/>
                <div className={classes.new_comment_btn_container}>
                    <Button text={'Enviar'} onClick={handleNewCommentOnclick}/>
                </div>
            </div>
        </div>
    )
}

export default ShowComments;