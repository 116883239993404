const values = {
    "PRODUCT": "Producto",
    "PRODUCT_PLACEHOLDER": "Seleccione un producto",
    "NEW_TOPUP": "Nueva Recarga",
    "TOPUPS": "Recargas",
    "NO_TOPUPS_TITLE": "No hay recargas!!!",
    "NO_TOPUPS_SUBTITLE": "Verifica si estas aplicando algún filtro",
    "NO_DO_TOPUP_TITLE": "No hay recargas por hacer",
    "NO_DO_TOPUP_SUBTITLE": "Utiliza la barra superior para adicionar recargas",
    "NO_COMMENT": "Sin comentario",
    "DO_TOPUP_ALREADY_IN_LIST": "Este número ya existe en la lista",
    "LAST_RECHARGE": "Última recarga",
    "PROMO_NUMBER_COUNT_DESCRIPTION": "Cantidad de recargas hechas en esta promo",
    "TOPUPS_DONE_TITLE": "Recargas enviadas",
    "TOPUPS_DONE_DESCRIPTION": "Todas las recargas se enviaron correctamente y estan en cola para ser procesadas.",
    "DO_MORE_TOPUPS": "Hacer más recargas",
    "GO_TO_TOPUPS": "Ir al listado de recargas",
    "TOPUP_CREATED": "Pendiente",
    "TOPUP_TOPUP_REFUNDED": "Reembolsada",
    "TOPUP_REFUNDED": "Reembolsada",
    "TOPUP_REJECTED": "Rechazada",
    "TOPUP_DONE": "Completada",
    "TOPUP_RETRYING": "Reintentando",
    "RETRYING": "Reintentando",
    "TOPUP_MANUAL_VALIDATION": "Validación manual",
    "MANUAL_VALIDATION": "Validación manual",
    "CONFIRMATION": "Confirmación",
    "TOPUP_PAYMENT_PENDING": "Pendiente",
    "PAYMENT_PENDING": "Pendiente",
    "TOPUP_PAYMENT_REJECTED": "Pago rechazado",
    "PAYMENT_REJECTED": "Pago rechazado",
    "TOPUP_PAYMENT_DONE": "Pago realizado",
    "PAYMENT_DONE": "Pago realizado",
    "TOPUP_DONE_BUT_PENDING_IN_PROVIDER": "Pendiente en proveedor",
    "DONE_BUT_PENDING_IN_PROVIDER": "Pendiente en proveedor",
    "TOPUP_IN_QUEUE": "En cola",
    "TOPUP_ALL_STATUS": "Todos",
    "TOPUP_STATUS_FILTER": "Estado",
    "TOPUP_STATUS_FILTER_PLACEHOLDER": "Seleccione un estado",
    "TOPUP_DATE_FILTER": "Fecha",
    "SINGLE": "Simple"
}

export default values;