import React from 'react';
import {useStyles} from "./ShowOrderStatus.style";
import clsx from "clsx";
import {Tooltip} from "@material-ui/core";
import ShowOrderStatusProperties from "./ShowOrderStatusProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import ProductOrderStatus from "../../../entities/productOrder/ProductOrderStatus";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';


const ShowOrderStatus: React.FC<ShowOrderStatusProperties> = ({
                                                                  status,
                                                                  className = '',
                                                                  tooltip,
                                                                  label,
                                                              }) => {
    const classes = useStyles();
    const i18n = useI18nContext();
    if (!status) return <></>

    const getIcon = () => {
        switch (status) {
            case ProductOrderStatus.REMOVED:
                return <HighlightOffIcon/>;
            case ProductOrderStatus.CANCELED:
                return <HighlightOffIcon/>;
            case ProductOrderStatus.NOT_DELIVERED:
                return <HighlightOffIcon/>;
            case ProductOrderStatus.COMPLETED:
                return <CheckCircleIcon/>;
            case ProductOrderStatus.DELIVERED:
                return <CheckCircleIcon/>;
            case ProductOrderStatus.PAYED:
                return <CreditScoreIcon/>;
            case ProductOrderStatus.PROCESSING:
                return <HourglassBottomIcon/>;
            case ProductOrderStatus.RETRYING:
                return <SyncProblemIcon/>;
            case ProductOrderStatus.PENDING:
                return <HourglassBottomIcon/>;
            case ProductOrderStatus.ASSIGNED:
                return <TransferWithinAStationIcon/>;
        }
    }
    const getClass = () => {
        switch (status) {
            case ProductOrderStatus.REMOVED:
            case ProductOrderStatus.CANCELED:
            case ProductOrderStatus.NOT_DELIVERED:
                return classes.error;
            case ProductOrderStatus.COMPLETED:
            case ProductOrderStatus.DELIVERED:
                return classes.success;
            case ProductOrderStatus.PAYED:
            case ProductOrderStatus.RETRYING:
            case ProductOrderStatus.PENDING:
            case ProductOrderStatus.ASSIGNED:
                return classes.pending;
            case ProductOrderStatus.PROCESSING:
                return classes.warning;
        }
    }

    return (
        <Tooltip title={tooltip || ""}>
            <div className={clsx(classes.display_field_container, className, getClass())}>
                <div className={classes.value}>
                    {getIcon()}
                    {i18n.translate(`${status}`)}
                </div>
            </div>
        </Tooltip>
    )
}
export default ShowOrderStatus;