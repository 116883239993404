import Request from "../../model/Request";
import Response from "../../model/Response";
import {RestClient} from "../../util/RestClient";
import {Config} from "../../util/Config";
import DeliveryOrderCommentResponse from "../../model/productOrder/DeliveryOrderCommentResponse";

export module ProductOrderService {

    export async function getProductOrderComments(id: string): Promise<Response<DeliveryOrderCommentResponse[]>> {
        const url = Config.get("GET_PRODUCT_ORDER_COMMENTS_URL", {id});
        const request = new Request(url)
        const response = await RestClient.get(request);
        if (response.success) {
            const comments = response.data;
            if (comments) {
                const commentsREsult = comments.map((t: DeliveryOrderCommentResponse) => new DeliveryOrderCommentResponse(
                    t.id,
                    t.userId,
                    t.userName,
                    t.comment,
                    t.createdAt
                ))
                return new Response<DeliveryOrderCommentResponse[]>(true, commentsREsult, response.error);
            }
        }
        return new Response<DeliveryOrderCommentResponse[]>(true, undefined, response.error, response.status);
    }
    
    export async function addComment(articleDeliveryId: string, comment: string): Promise<Response<any>> {
        const url = Config.get("ADD_PRODUCT_ORDER_COMMENT_URL", {
            id: articleDeliveryId
        });
        const request = new Request(url, { comment: comment ? comment : '' });
        return await RestClient.post(request);
    }

}