import errors from './error'
import basic from './basic'
import seller from './seller'
import foodPackages from './foodPackages'

export default Object({
    ...errors,
    ...basic,
    ...seller,
    ...foodPackages
})