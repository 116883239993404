import React, {createRef, useEffect, useState} from 'react';
import {useStyles} from "./Select.style";
import {useCommonStyles} from "../common/CommonStyles.style";
import SelectProperties from "./SelectProperties";
import clsx from "clsx";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import SelectOption from "./SelectOption";
import Loading from "../PageLoading/Loading";


const Select: React.FC<SelectProperties> = ({
                                                elements,
                                                error,
                                                label,
                                                selected,
                                                placeholder = '',
                                                helpText,
                                                onChange,
                                                disabled = false,
                                                loading = false,
                                                icon: DefaultIcon,
                                                fullWidth = false,
                                                className = '',
                                            }: SelectProperties) => {
    const classes = useStyles();
    const commonClasses = useCommonStyles();
    const menuRef = createRef<HTMLDivElement>();
    const [open, setOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(selected);

    useEffect(() => {
        setSelectedOption(selected);
    }, [selected]);

    useEffect(() => {
        const clickOutside = (event: any) => {
            if (menuRef && !menuRef.current?.contains(event.target)) {
                setOpen(false);
            }
        }
        document.addEventListener('mousedown', clickOutside);
        return () => {
            document.removeEventListener('mousedown', clickOutside)
        }
    }, [menuRef])

    const onElementClick = (element: SelectOption) => {
        setSelectedOption(element);
        setOpen(false);
        onChange(element);
    }

    const Icon = () => {
        if (selectedOption?.icon) {
            return (
                <div className={classes.icon_container}>
                    <selectedOption.icon/>
                </div>)
        }
        if (DefaultIcon) {
            return (
                <div className={classes.icon_container}>
                    <DefaultIcon/>
                </div>)
        }
        return <></>
    }

    const containerClasses = clsx(classes.select_container,
        className, fullWidth && commonClasses.fullWidth,
        error && classes.input_container_error);

    return (
        <div className={containerClasses} ref={menuRef}>
            <div className={classes.label}>{label}</div>
            <div className={classes.input_container} onClick={() => !disabled && setOpen(loading ? open : !open)}>
                {!loading && <Icon/>}
                {loading && <Loading fontSize={22}/>}
                <div className={classes.info_container}>
                    {selectedOption &&
                    <div className={classes.text_container}>
                        {selectedOption.primaryText && <div>{selectedOption.primaryText}</div>}
                        {selectedOption.secondaryText && <div> {selectedOption.secondaryText}</div>}
                    </div>
                    }
                    {!selectedOption && placeholder}
                </div>
                <div className={classes.expand_icon}>
                    {open && <ExpandLessIcon/>}
                    {!open && <ExpandMoreIcon/>}
                </div>
            </div>
            <div className={clsx(classes.text_hint, error && classes.error)}>{error || helpText}</div>
            {open && !loading &&
            <div className={classes.options_container}>
                {elements.map((el, i) => (
                    <div key={i} className={clsx(classes.option, el.value === selectedOption?.value && classes.active)}
                         onClick={() => onElementClick(el)}>
                        {el.icon && <el.icon/>}
                        <div className={classes.text_container}>
                            <div>{el.primaryText}</div>
                            <div> {el.secondaryText}</div>
                        </div>
                    </div>))}
            </div>
            }
        </div>
    )
}
export default Select;