import User from "../entities/User";
import Request from "../model/Request";
import Response from "../model/Response";
import {RestClient} from "../util/RestClient";
import Token from "../model/Token";
import {Config} from "../util/Config";

export module AuthenticateService {
    export async function login(email: string, password: string): Promise<Response<User>> {
        const url = Config.get("AUTH_URL");
        const request = new Request(url, {email, password})
        const response = await RestClient.post(request)
        if (response.success) {
            const tokenValue = response.data?.token;
            const token = new Token();
            token.replace(tokenValue);
            const user = token.parse();
            console.log(user)
            return new Response<User>(!!user, user, response.error);
        }
        return new Response<User>(false, undefined, response.error, response.status);
    }

}