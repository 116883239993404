import Request from "../model/Request";
import Response from "../model/Response";
import {RestClient} from "../util/RestClient";
import {Config} from "../util/Config";
import Role from "../entities/Role";

export module RoleService {
    export async function get(): Promise<Response<Role[]>> {
        const url = Config.get("GET_ROLE_URL");
        const request = new Request(url)
        const response = await RestClient.get(request)
        if (response.success) {
            const roleData = response.data;
            if (roleData) {
                const data = roleData.map((t: any) => new Role(t.id, t.name, t.value,t.description));
                return new Response<Role[]>(true, data, response.error)
            }
        }
        return new Response<Role[]>(false, undefined, response.error, response.status);
    }
}