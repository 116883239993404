import Currency from "../entities/Currency";

const getSymbol = (currency: string | undefined = undefined): string => {
    switch (currency) {
        case "EURO":
            return "€ {value} EURO";
        case "MN":
            return "$ {value} CUP";
        case "MLC":
            return "$ {value} MLC";
        case "PERCENT":
            return "{value}%";
        default :
            return "$ {value} USD";
    }
}

export const getEnum = (currency: string | undefined = undefined): Currency => {
    switch (currency) {
        case "EURO":
            return Currency.EURO;
        case "MN":
            return Currency.CUP;
        case "MLC":
            return Currency.MLC;
        case "DOLLAR":
            return Currency.DOLLAR;
        default :
            return Currency.DOLLAR;
    }
}

export module CurrencyModule {
    export function format(amount: number, currency: string | undefined = undefined): string {
        amount = amount || 0;
        let symbol = getSymbol(currency)
        symbol = amount >= 0 ? symbol : `-${symbol}`;
        const value = Math.abs(amount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
        return symbol.replace("{value}", value);
    }
}