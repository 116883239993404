const values = {
    "DELIVERY_PERSON": "Repartidores",
    "DELIVERY_PERSON_SINGULAR": "Repartidor",
    "NO_DELIVERY_PERSON_TITLE": "No hay repartidores!!!",
    "NO_DELIVERY_PERSON_SUBTITLE": "Verifica si estas aplicando algún filtro",
    "DELIVERY_PERSON_FILTER_PLACEHOLDER": "Nombre, número, correo",
    "EDIT_DELIVERY_PERSON": "Editar Repartidor",
    "NEW_DELIVERY_PERSON": "Nuevo Repartidor",
    "ERROR_00_400_43": "Ya existe un repartidor con este nombre",
    "ERROR_00_400_44": "Ya existe un repartidor con este teléfono",
    "ERROR_00_400_41": "Ya existe un repartidor con este correo",
    "DELIVERY_COST": "Costos de entrega",
    "DELIVERY_COST_FILTER_PLACEHOLDER": "Ubicación, moneda",
    "NO_DELIVERY_COST_TITLE": "No hay costos de entrega!!!",
    "NO_DELIVERY_COST_SUBTITLE": "Verifica si estas aplicando algún filtro",
    "REMOVE_DELIVERY_PERSON_CONFIRMATION": "¿Está seguro que desea eliminar el repartidor?",
    "REMOVE_DELIVERY_COST_CONFIRMATION": "¿Está seguro que desea eliminar el costo de entrega?",
    "REMOVE_DELIVERY_PERSON": "Eliminar repartidor",
    "REMOVE_DELIVERY_COST": "Eliminar costo de entrega",
    "NEW_DELIVERY_COST": "Nuevo costo de entrega",
    "COST_OF_THE_DELIVERY": "Costo de la entrega",
    "SELECT_DELIVERY_PERSON": "Seleccionar repartidor",
    "SELECT_DELIVERY_PERSON_PLACEHOLDER": "Seleccione Repartidor",
    "NO_DELIVERY_PERSONS_TITLE": "No hay repartidores!!!",
}

export default values;