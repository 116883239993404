import React from 'react';
import {useStyles} from "./Dashboard.style";
import {Observer} from "mobx-react-lite";
import MoneyDeliveryStats from '../../shared_components/Widget/MoneyDeliveryStats/MoneyDeliveryStats';
import Balances from '../../shared_components/Widget/Balances/Balances';
import ArticleDeliveryStats from '../../shared_components/Widget/ArticleDeliveryStats/ArticleDeliveryStats';
import PackageDeliveryStats from '../../shared_components/Widget/PackageDeliveryStats/PackageDeliveryStats';

const Dashboard: React.FC = () => {
    const classes = useStyles();

    return (
        <Observer>{() => (
            <div className={classes.dashboard_container}>
                <div>
                    <Balances/>
                </div>
                <div>
                    <div className={classes.box_container}>
                        <MoneyDeliveryStats/>
                        <ArticleDeliveryStats/>
                        <PackageDeliveryStats/>
                    </div>
                </div>
            </div>
        )}</Observer>
    )
}
export default Dashboard;
