import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    side_menu_container: {
        backgroundColor: `${theme.palette.secondary.light}`,
        boxSizing: 'border-box',
        display: 'flex',
        zIndex: 2,
        justifyContent: "space-between",
        position: 'relative',
        padding: '8px',
    },
    menu_items_container: {
        display: 'flex',
        backgroundColor: theme.palette.common.white,
        position: 'absolute',
        flexDirection: "column",
        boxSizing: 'border-box',
        top: '55px',
        width: "250px",
        height: '100vh',
        left: "-8px"
    },
    menu_button_container: {
        position: 'relative',
        boxSizing: 'border-box',
    },
    menu_button: {
        cursor: 'pointer',
        borderRadius: '8px',
        boxSizing: 'border-box',
        border: `1px solid transparent`,
        "& svg": {
            fontSize: '40px',
            color: theme.palette.common.white,
        },
        "&:hover": {
            border: `1px solid ${theme.palette.common.white}`
        }
    },
    menu_item_container: {
        height: '45px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        textDecoration: 'none',
        padding: '0 16px',
        color: theme.palette.secondary.light,
        fontSize: '20px',
        "&:hover": {
            backgroundColor: `${theme.palette.secondary.main}d1`,
            cursor: 'pointer',
            color: theme.palette.common.white
        },
        "& svg": {
            fontSize: '30px',
        },
        "& div": {
            flexGrow: 1,
            marginLeft: '24px'
        }
    },
    active: {
        backgroundColor: `${theme.palette.primary.light}5c`,
        color: theme.palette.primary.main,
        fontWeight: 800
    },
    main_action_container: {
        display: 'flex',
        height: '50px',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '8px',
        boxSizing: 'border-box'
    },
    user_info_container: {
        color: theme.palette.common.white,
        width: 'fit-content',
        flexWrap: 'nowrap',
        marginRight: '8px'
    },
    logout_btn_container: {
        display: 'flex',
        alignItems: 'center'
    }
}));

export {useStyles}