import DeliveryOrderComment from "../../../entities/productOrder/DeliveryOrderComment";
import ProductOrderStatus from "../../../entities/productOrder/ProductOrderStatus";
import ProductOrderContactResponse from "../ProductOrderContactResponse";
import ArticleDeliveryOrderArticleResponse from "./ArticleDeliveryOrderArticleResponse";

class ArticleDeliveryOrderResponse {
    public id: string;
    public orderNumber: string;
    public agencyId: string;
    public clientId: string;
    public clientName: string;
    public contact: ProductOrderContactResponse;
    public articles: ArticleDeliveryOrderArticleResponse[];
    public createdAt: number;
    public currentStatus: ProductOrderStatus;
    public comments: DeliveryOrderComment[];
    public difficultDelivery: boolean;
    public observations?: string;

    constructor(id: string, orderNumber: string, agencyId: string, clientId: string, clientName: string, contact: ProductOrderContactResponse,
        articles: ArticleDeliveryOrderArticleResponse[], createdAt: number, currentStatus: ProductOrderStatus, comments: DeliveryOrderComment[],
        difficultDelivery: boolean, observations?: string) {
        this.id = id;
        this.orderNumber = orderNumber;
        this.agencyId = agencyId;
        this.clientId = clientId;
        this.clientName = clientName;
        this.contact = contact;
        this.articles = articles;
        this.createdAt = createdAt;
        this.currentStatus = currentStatus;
        this.comments = comments;
        this.difficultDelivery = difficultDelivery;
        this.observations = observations;
    }
}

export default ArticleDeliveryOrderResponse;