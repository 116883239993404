import CommonStore from "../../../stores/CommonStore";
import {action, makeObservable, observable} from "mobx";
import { DeliveryPersonService } from "../../../service/DeliveryPersonService";
import DeliveryPersonBalance from "../../../entities/DeliveryPersonBalance";

class BalancesStore {

    public commonStore: CommonStore;
    public loading: boolean;
    public deliveryPersonId?: string;
    public balances?: DeliveryPersonBalance[];

    constructor(
        commonStore: CommonStore,
        deliveryPersonId?: string
    ) {
        this.commonStore = commonStore;
        this.loading = true;
        this.deliveryPersonId = deliveryPersonId;
        makeObservable(this, {
            loading: observable,
            balances: observable,
        });
    }

    @action
    public async getBalances(): Promise<void> {
        this.loading = true;
        const response = await DeliveryPersonService.getBalances(this.deliveryPersonId);
        if (response.success) {
            this.balances = response.data;
        }
        this.loading = false;
    }
}

export default BalancesStore;