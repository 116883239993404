import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    side_panel_container: {
        "@media print": {
            width: '100%'
        }
    },
    panel_container: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        maxWidth: '90vw',
        "@media print": {
            width: '100%'
        }
    },
    header_container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: 0,
        padding: '16px',
        minWidth: 500,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary.light,
    },
    body_container:{
        display: 'flex',
        flexGrow: 1,
        position: 'relative',
    },
    title: {
        fontSize: "25px",
        fontWeight: 600,
        "@media print": {
            display: 'none'
        }
    },
    closeButton: {
        position: 'absolute',
        right: '20px',
        top: '20px',
        color: theme.palette.common.white,
        cursor: 'pointer',
        borderRadius: '4px',
        display: 'flex',
        "&:hover": {
            backgroundColor: theme.palette.secondary.dark,
        }
    },
    loading: {
        position: 'absolute',
        width: '100%',
        height: '100%'
    }
}));

export {useStyles}