import React from 'react';
import ShowMoneyDeliveryAmountsProperties from "./ShowMoneyDeliveryAmountsProperties";
import {useStyles} from "./ShowMoneyDeliveryAmounts.style";
import {CurrencyModule} from "../../../util/Currency";
import {useI18nContext} from "../../../contexts/I18nContext";
import clsx from "clsx";

const ShowMoneyDeliveryAmounts: React.FC<ShowMoneyDeliveryAmountsProperties> = ({
                                                        valueToReceive,
                                                        currencyReceive,
                                                        className = '',
                                                  }) => {

    const i18n = useI18nContext();
    const classes = useStyles();

    return (
        <div className={clsx(classes.show_price_container, className)}>
            <div className={classes.margin_top_5}>
                <div className={classes.number}>
                    <div className={classes.title}>{`Valor a entregar:`}</div>
                    <div className={classes.value}>{valueToReceive && CurrencyModule.format(valueToReceive, currencyReceive)}</div>
                </div>
                <div className={classes.currency}>{i18n.translate(currencyReceive)}</div>
            </div>
        </div>
    )
}


export default ShowMoneyDeliveryAmounts;