import React from 'react';
import ShowPhoneNumberProperties from "./ShowPhoneNumberProperties";
import {useStyles} from "./ShowPhoneNumber.style";
import {format} from "../../../util/PhoneFormatter";
import CountryFlagIcon from "../../../ui_components/Icon/icons/CountryFlagIcon";

const ShowPhoneNumber: React.FC<ShowPhoneNumberProperties> = ({
                                                                  value,
                                                                  label = '',
                                                                  showFlag = true,
                                                                  className = '',
                                                              }) => {

    // const i18n = useI18nContext();
    const classes = useStyles();

    return (
        <div className={classes.phone_container}>
            {label && <div className={classes.label}>{label}</div>}
            <div className={classes.show_phone_number_container}>
                <div className={classes.number}>{format(value, true)}</div>
                {showFlag && <CountryFlagIcon className={classes.icon} fontSize={15} countryCode={value?.countryCode}/>}
            </div>
        </div>
    )
}


export default ShowPhoneNumber;