import CommonStore from "../../../stores/CommonStore";
import {action, makeObservable, observable} from "mobx";
import { MoneyDeliveryService } from "../../../service/productOrder/MoneyDeliveryService";
import ProductOrderStatsResponse from "../../../model/productOrder/ProductOrderStatsResponse";

class MoneyDeliveryStatsStore {

    public commonStore: CommonStore;
    public loading: boolean;
    public deliveryPersonId?: string;
    public moneyDeliveryStats?: ProductOrderStatsResponse;

    constructor(
        commonStore: CommonStore,
        deliveryPersonId?: string
    ) {
        this.commonStore = commonStore;
        this.loading = true;
        this.deliveryPersonId = deliveryPersonId;
        makeObservable(this, {
            loading: observable,
            moneyDeliveryStats: observable,
        });
    }

    @action
    public async getMoneyDeliveryStats(): Promise<void> {
        this.loading = true;
        const response = await MoneyDeliveryService.getMoneyDeliveryStats(this.deliveryPersonId);
        this.moneyDeliveryStats = response;
        this.loading = false;
    }
}

export default MoneyDeliveryStatsStore;