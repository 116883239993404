import React from 'react';
import {useStyles} from "../PackageDelivery.style";
import clsx from "clsx";
import {motion} from "framer-motion";
import PackageDeliveryRowProperties from "./PackageDeliveryRowProperties";
import ButtonSize from '../../../ui_components/Button/ButtonSize';
import ButtonType from '../../../ui_components/Button/ButtonType';
import Checkbox from '../../../ui_components/Checkbox/Checkbox';
import ShowOrderNumber from '../../../shared_components/Info/ShowOrderNumber/ShowOrderNumber';
import ShowDate from '../../../shared_components/Info/ShowDate/ShowDate';
import ShowText from '../../../shared_components/Info/ShowText/ShowText';
import ViewInvoiceButton from '../../../shared_components/Buttons/MoneyDeliveryPrintButton/MoneyDeliveryPrintButton';
import ProductOrderCommentsButton from '../../../shared_components/Buttons/ProductOrderCommentsButton/ProductOrderCommentsButton';
import ServiceName from '../../../entities/ServiceName';
import ShowPhoneNumber from '../../../shared_components/Info/ShowPhoneNumber/ShowPhoneNumber';
import ShowPackageDeliveryPersonStatus from '../../../shared_components/Info/ShowPackageDeliveryPersonStatus/ShowPackageDeliveryPersonStatus';
import UpdatePackageDeliveryOrderStatusButton from '../../../shared_components/Buttons/UpdatePackageDeliveryOrderStatusButton/UpdatePackageDeliveryOrderStatusButton';


const PackageDeliveryRowSmall: React.FC<PackageDeliveryRowProperties> = ({
                                                                                    packageDeliveryOrder,
                                                                                    selectedDeliveryOrderIds,
                                                                                    onPackageDeliveryOrderSelect,
                                                                                    onUpdate
                                                                                }) => {

    const classes = useStyles();

    const onPackageDeliverySelect = () => {
        onPackageDeliveryOrderSelect(packageDeliveryOrder.id);
    }

    return (
        <div className={clsx(classes.package_info_container_small)}>
            <div className={classes.first_row_small}>
                <div>
                    <div className={classes.order_number_container}>
                    <Checkbox text={''} onChange={onPackageDeliverySelect} selected={selectedDeliveryOrderIds.findIndex(t => t === packageDeliveryOrder.id) >= 0}/>
                        <ShowOrderNumber orderNumber={packageDeliveryOrder.orderNumber}/>
                    </div>
                    <ShowDate label={'Fecha'} timestamp={packageDeliveryOrder.createdAt} className={classes.margin_top_4}/>
                </div>
                <div>
                    <ShowPackageDeliveryPersonStatus status={packageDeliveryOrder.currentDeliveryPersonStatus}/>
                </div>
            </div>
            <div className={classes.second_row_small}>
                <ShowText text={packageDeliveryOrder.clientName} title={'Remitente'}/>
                <div>
                    <ShowText text={packageDeliveryOrder.contact.name + (packageDeliveryOrder.contact.lastName ? ' ' + packageDeliveryOrder.contact.lastName : '')} title={'Destinatario'}/>
                    {packageDeliveryOrder.contact.mobilePhone &&
                        <ShowPhoneNumber value={packageDeliveryOrder.contact.mobilePhone} showFlag={false}/>}
                    {packageDeliveryOrder.contact.homePhone &&
                            <ShowPhoneNumber value={packageDeliveryOrder.contact.homePhone} showFlag={false}/>}
                    {packageDeliveryOrder.contact.secondMobilePhone &&
                            <ShowPhoneNumber value={packageDeliveryOrder.contact.secondMobilePhone} showFlag={false}/>}
                </div>
            </div>
            <div>
                <div>
                    <small>Entrega</small> 
                    <div>
                            <ShowText
                                text={`${packageDeliveryOrder.contact.address}${packageDeliveryOrder.contact.neighborhood ? '. ' + packageDeliveryOrder.contact.neighborhood : ''}`}
                                title={packageDeliveryOrder.contact.geo.name + '. ' + packageDeliveryOrder.contact.geo.parentInfo}/>
                        </div>
                </div>
                {packageDeliveryOrder.observations && <ShowText text={packageDeliveryOrder.observations} title={'Observaciones'}/>}
            </div>
            <div className={classes.menu_container_small}>
                <motion.div animate={{scale: [0, 1]}} transition={{delay: 0.2, duration: 0.2}}>
                    <UpdatePackageDeliveryOrderStatusButton
                        size={ButtonSize.SMALL}
                        serviceName={ServiceName.ARTICLE_DELIVERY}
                        productOrderId={packageDeliveryOrder.id}
                        currentDeliveryPersonStatus={packageDeliveryOrder.currentDeliveryPersonStatus}
                        showText
                        type={ButtonType.SECONDARY}
                        onSave={onUpdate}
                    />
                </motion.div>
                <motion.div animate={{scale: [0, 1]}} transition={{delay: 0.2, duration: 0.2}}>
                    <ViewInvoiceButton
                        size={ButtonSize.SMALL}
                        productOrderIds={[packageDeliveryOrder.id]}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                </motion.div>
                <motion.div animate={{scale: [0, 1]}} transition={{delay: 0.2, duration: 0.2}}>
                    <ProductOrderCommentsButton
                        size={ButtonSize.SMALL}
                        productOrderId={packageDeliveryOrder.id}
                        productOrderNumber={packageDeliveryOrder.orderNumber}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                </motion.div>
            </div>
        </div>
    )
}

export default PackageDeliveryRowSmall;
