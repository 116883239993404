import {action, makeAutoObservable, runInAction} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import Geo from "../../../entities/Geo";
import { GeoService } from "../../../service/GeoService";

class GeoSelectStore {

    public loading: boolean;
    public commonStore: CommonStore;
    public filter?: string;
    public parentIdFilter?: string;
    public elements: Geo[];
    public levels?: number[];
    public selectedGeo?: Geo;
    public isSearchDialogOpen: boolean;

    constructor(commonStore: CommonStore, levels?: number[], selectedGeo?: Geo, parentIdFilter?: string) {
        this.loading = false;
        this.commonStore = commonStore;
        this.elements = [];
        this.levels = levels;
        this.selectedGeo = selectedGeo ? new Geo(selectedGeo.id, selectedGeo.name, selectedGeo?.parentId, selectedGeo?.level, selectedGeo?.hasChildren, selectedGeo?.parentInfo) : undefined;
        this.parentIdFilter = parentIdFilter;
        this.isSearchDialogOpen = false;
        makeAutoObservable(this);
    }

    @action
    public async search(): Promise<void> {
        this.loading = true;
        const response = await GeoService.search(1, 30, this.filter, this.levels, this.parentIdFilter);
        if (response.success) {
            runInAction(() => {
                this.elements = response.data?.data || [];
            })
        }
        this.commonStore.processErrors(response);
        this.loading = false;
    }

    @action
    public toggleSearchDialog(value?: boolean) {
        value = (value === undefined) ? !this.isSearchDialogOpen : value;
        this.isSearchDialogOpen = value;
        if (this.isSearchDialogOpen) {
            this.search();
        }
    }

    get isEmpty(): boolean {
        return this.elements.length === 0;
    }

    @action
    public async setFilter(filter: string) {
        this.filter = filter;
        await this.search();
    }

    @action
    public async setSelectedGeo(geo?: Geo) {
        this.selectedGeo = geo ? new Geo(geo.id, geo.name, geo?.parentId, geo?.level, geo?.hasChildren, geo?.parentInfo) : undefined;
        this.isSearchDialogOpen = false;
    }

}

export default GeoSelectStore;