import {action, makeAutoObservable} from "mobx";
import { MoneyDeliveryService } from "../../../service/productOrder/MoneyDeliveryService";
import CommonStore from "../../../stores/CommonStore";
import ServiceName from "../../../entities/ServiceName";
import { ArticleDeliveryService } from "../../../service/productOrder/ArticleDeliveryService";

const ERROR_MAP = {
    email: ["ERROR_00_400_07", "ERROR_00_400_08", "ERROR_00_400_09"],
    phone: ["ERROR_00_400_10"],
    name: ["ERROR_00_400_06"],
}

class UpdateDeliveryOrderStatusStore {

    public serviceName: ServiceName;
    public productOrderId: string;
    public isModalOpen: boolean;
    public loading: boolean;
    public emailError?: string;
    public phoneError?: string;
    public nameError?: string;
    public commonStore: CommonStore;

    public comment?: string;
    public status?: string = 'DELIVERED';


    constructor(commonStore: CommonStore, productOrderId: string, serviceName: ServiceName) {
        this.serviceName = serviceName;
        this.productOrderId = productOrderId;
        this.isModalOpen = false;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    get enableButton(): boolean {
        return !!(this.status);
    }

    @action
    public async openModal() {
        this.loading = true;
        this.isModalOpen = true;
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    @action
    public async save(): Promise<boolean> {
        this.loading = true;
        const serviceToCall = this.getFunctionToCall();
        const response = await serviceToCall(this.productOrderId, this.status!!, this.comment!!);
        this.commonStore.processErrors(response);
        this.processError(response.error);
        this.loading = false;
        return response.success;
    }

    getFunctionToCall() {
        switch (this.serviceName) {
            case ServiceName.ARTICLE_DELIVERY:
                return ArticleDeliveryService.updateStatus;        
            default:
                return MoneyDeliveryService.updateStatus;
        }
    }

    processError(error?: string) {
        this.emailError = undefined;
        this.phoneError = undefined;
        this.nameError = undefined;
        if (error) {
            if (ERROR_MAP.email.includes(error)) {
                this.emailError = error;
            }
            if (ERROR_MAP.phone.includes(error)) {
                this.phoneError = error;
            }
            if (ERROR_MAP.name.includes(error)) {
                this.nameError = error;
            }
        }

    }
}

export default UpdateDeliveryOrderStatusStore;