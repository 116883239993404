import Request from "../../model/Request";
import Response from "../../model/Response";
import {RestClient} from "../../util/RestClient";
import {Config} from "../../util/Config";
import MoneyDeliveryOrderResponse from "../../model/productOrder/moneyDelivery/MoneyDeliveryOrderResponse";
import ProductOrderStatsResponse from "../../model/productOrder/ProductOrderStatsResponse";
import MoneyDeliveryOrderDetailsResponse from "../../model/productOrder/moneyDelivery/MoneyDeliveryOrderDetailsResponse";

export module MoneyDeliveryService {
    export async function get(
        from: number = 0,
        to: number = 0,
        deliveryPersonId?: string,
        filter?: string,
        currency?: string,
        type?: string,
        geoId?: string
    ): Promise<MoneyDeliveryOrderResponse[]> {
        const url = Config.get("GET_MONEY_DELIVERY_URL", {
            from, to,
            id: deliveryPersonId,
            filter, currency, type, geoId
        });
        const request = new Request(url);
        const response = await RestClient.get(request);
        if (response && response.success) {
            return response.data.map((t: any) => new MoneyDeliveryOrderResponse(
                t.id,
                t.orderNumber,
                t.agencyId,
                t.clientId,
                t.clientName,
                t.contact,
                t.contactBankCardId,
                t.createdAt,
                t.amountToReceive,
                t.currencyToReceive,
                t.currentStatus,
                t.isBankCard,
                t.bankCardType,
                t.bankCardNumber,
                t.comments,
                t.difficultDelivery,
                t.observations
            ));
        }
        return [];
    }

    export async function find(id: string): Promise<Response<MoneyDeliveryOrderDetailsResponse>> {
        const url = Config.get("FIND_MONEY_DELIVERY_URL", {id});
        const request = new Request(url)
        const response = await RestClient.get(request);
        if (response.success) {
            const t = response.data;
            if (t) {
                const moneyRemittance = new MoneyDeliveryOrderDetailsResponse(
                    t.id,
                    t.orderNumber,
                    t.agencyId,
                    t.clientId,
                    t.clientName,
                    t.contactName,
                    t.contactPhone,
                    t.address,
                    t.geo,
                    t.contactBankCardId,
                    t.createdAt,
                    t.totalToReceive,
                    t.currencyToReceive,
                    t.currentStatus,
                    t.isBankCard,
                    t.contactBankCardBank,
                    t.contactBankCardNumber,
                    t.comments,
                    t.difficultDelivery,
                    t.observations
                );
                return new Response<MoneyDeliveryOrderDetailsResponse>(true, moneyRemittance, response.error);
            }
        }
        return new Response<MoneyDeliveryOrderDetailsResponse>(true, undefined, response.error, response.status);
    }

    export async function updateStatus(moneyDeliveryId: string, status: string, comment: string): Promise<Response<any>> {
        const url = Config.get("UPDATE_MONEY_DELIVERY_STATUS_URL", {
            id: moneyDeliveryId,
            status: status
        });
        const request = new Request(url, { comment: comment ? comment : '' });
        return await RestClient.put(request);
    }

    export async function updateBulkStatus(ordersIds: string[], status: string, comment: string): Promise<Response<any>> {
        const url = Config.get("UPDATE_MONEY_DELIVERY_ORDERS_STATUS_URL", {
            status: status
        });
        const request = new Request(url, { ordersIds, comment: comment ? comment : '' });
        return await RestClient.put(request);
    }

    export async function getMoneyDeliveryStats(deliveryPersonId?: string): Promise<ProductOrderStatsResponse | undefined> {
        const url = Config.get("GET_MONEY_DELIVERY_STATS_URL", {
            id: deliveryPersonId
        });
        const request = new Request(url);
        const response = await RestClient.get(request);
        if (response && response.success) {
            return new ProductOrderStatsResponse(response.data.delayedRemittanceAmount, response.data.deliveryAmount);
        }
        return undefined;
    }

}