class ProductOrderStatsResponse {
    public delayedRemittanceAmount: number;
    public deliveryAmount: number;

    constructor(delayedRemittanceAmount: number, deliveryAmount: number) {
        this.delayedRemittanceAmount = delayedRemittanceAmount;
        this.deliveryAmount = deliveryAmount;
    }

}

export default ProductOrderStatsResponse;