const values = {
    "COST": "Costo",
    "PACKAGE_CATEGORY_COST": "Costo de categorias",
    "PACKAGE_CATEGORY_COSTS": "Costos de categorias",
    "NEW_PACKAGE_CATEGORY_COST": "Nuevo costo de categoria",
    "EDIT_PACKAGE_CATEGORY_COST": "Editar costo de categoria",
    "NO_PACKAGE_CATEGORY_COSTS_TITLE": "No hay costo de categorias!!!",
    "NO_PACKAGE_CATEGORY_COSTS_SUBTITLE": "Verifica si estas aplicando algún filtro",
}

export default values;