import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    text_input_container: {
        position: "relative"
    },
    label: {
        position: 'absolute',
        fontSize: '12px',
        color: theme.palette.grey[600],
        backgroundColor: theme.palette.common.white,
        top: -8,
        left: 10,
        padding: " 0 4px"
    },
    input_container: {
        display: 'flex',
        borderRadius: '4px',
        padding: '8px 14px',
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.common.white,
        height: '40px',
        boxSizing: 'border-box',
        "&:hover": {
            border: `1px solid ${theme.palette.secondary.main}`,
            "& $icon_container": {
                "& svg": {
                    color: theme.palette.secondary.main,
                    fill: theme.palette.secondary.main,
                }
            },
            "& $expand_icon": {
                color: theme.palette.secondary.main,
            }
        },
        "&:focus-within": {
            border: `1px solid ${theme.palette.primary.light}`,
            outline: 'none',
            "& $input": {
                outline: 'none'
            },
            "& $start_icon_container,$end_icon_container": {
                "& > *": {
                    color: theme.palette.primary.light,
                    fill: theme.palette.primary.light,
                }
            }
        }
    },
    start_icon_container: {
        "& svg": {
            verticalAlign: 'middle',
            color: theme.palette.secondary.light,
            fill: theme.palette.secondary.light,
            fontSize: '1.5rem'
        }
    },
    end_icon_container: {
        "& svg": {
            verticalAlign: 'middle',
            color: theme.palette.secondary.light,
            fill: theme.palette.secondary.light,
            fontSize: '1.5rem'
        }
    },
    input: {
        border: 'none',
        color: theme.palette.grey[600],
        paddingLeft: '8px',
        flexGrow: 1,
        fontSize: '14px'
    },
    text_hint: {
        fontSize: '12px',
        color: theme.palette.grey[400],
    },
    error: {
        color: theme.palette.error.light
    },
    input_container_error: {
        "& $input_container":{
            color: theme.palette.error.light,
            border: `1px solid ${theme.palette.error.light}`,
        },
        "& svg, $label": {
            color: theme.palette.error.light,
            fill: theme.palette.error.light
        }
    }
}));

export {useStyles}