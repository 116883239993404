import React, {createRef, useEffect, useState} from 'react';
import {useStyles} from "./DateTimeRangeSelect.style";
import DateTimeRangeSelectProperties from "./DateTimeRangeSelectProperties";
import {DateRange} from "@mui/lab/DateRangePicker";
import StaticDateRangePicker from '@mui/lab/StaticDateRangePicker';
import esLocale from "date-fns/locale/es";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {useI18nContext} from "../../contexts/I18nContext";
import DateRangeIcon from '@mui/icons-material/DateRange';
import Button from "../Button/Button";
import ButtonSize from "../Button/ButtonSize";
import ButtonType from "../Button/ButtonType";
import CustomRangeRepository from "./CustomRangeRepository";
import CustomRange from "./CustomRange";
import {DateTime} from "../../util/DateTime";
import clsx from "clsx";


const DateTimeRangeSelect: React.FC<DateTimeRangeSelectProperties> = ({
                                                                          onChange,
                                                                          label,
                                                                          selectedRange,
                                                                          selectedTo = undefined,
                                                                          selectedFrom = undefined,
                                                                          className = '',
                                                                      }: DateTimeRangeSelectProperties) => {
    const classes = useStyles();
    const i18n = useI18nContext();
    const [showPopup, setShowPopup] = useState(false);
    const [value, setValue] = React.useState<DateRange<Date>>([selectedFrom ? new Date(selectedFrom) : null, selectedTo ? new Date(selectedTo) : null]);
    const [selectedValue, setSelectedValue] = React.useState<DateRange<Date>>([selectedFrom ? new Date(selectedFrom) : null, selectedTo ? new Date(selectedTo) : null]);
    const menuRef = createRef<HTMLDivElement>();

    useEffect(() => {
        if (selectedRange) {
            const range = CustomRangeRepository.getRange(i18n, selectedRange);
            setSelectedValue([range?.from || null, range?.to || null]);
            setValue([range?.from || null, range?.to || null]);
        } else {
            setSelectedValue([selectedFrom ? new Date(selectedFrom) : null, selectedTo ? new Date(selectedTo) : null]);
            setValue([selectedFrom ? new Date(selectedFrom) : null, selectedTo ? new Date(selectedTo) : null]);
        }

    }, [selectedFrom, selectedTo, selectedRange, i18n]);

    useEffect(() => {
        const clickOutside = (event: any) => {
            if (menuRef && !menuRef.current?.contains(event.target)) {
                setShowPopup(false);
            }
        }
        document.addEventListener('mousedown', clickOutside);
        return () => {
            document.removeEventListener('mousedown', clickOutside)
        }
    }, [menuRef])

    const customRanges = CustomRangeRepository.getRanges(i18n);

    const togglePopup = () => setShowPopup(!showPopup);

    const onSelect = () => {
        setShowPopup(false);
        setSelectedValue(value);
        onChange(value[0]?.valueOf(), value[1]?.valueOf());
    }
    const onCancel = () => {
        setShowPopup(false);
        setValue([null, null]);
    }

    const selectRange = (range: CustomRange) => setValue([range.from, range.to])
    const isSelectBtnDisable = () => !value[0] || !value[1];
    const getBtnType = (range: CustomRange): ButtonType => range.isCurrentRange(value[0], value[1]) ? ButtonType.SECONDARY : ButtonType.DEFAULT;
    const isAnyValueSelected = () => selectedValue[0] && selectedValue[1];

    const DisplayInfo = () => {
        const selectedRange = customRanges.find(r => r.isCurrentRange(selectedValue[0], selectedValue[1]))
        if (selectedRange) {
            return <div className={classes.selected_range_container}>
                <div>{selectedRange.text}</div>
            </div>
        }
        return <div className={classes.selected_values_container}>
            <div>{DateTime.format(selectedValue[0])}</div>
            <div>{DateTime.format(selectedValue[1])}</div>
        </div>
    }

    return (
        <div className={clsx(classes.date_time_range_select_container, className)} ref={menuRef}>
            <div className={classes.input_container} onClick={togglePopup}>
                <div className={classes.label}>{label}</div>
                <div className={classes.icon_container}>
                    <DateRangeIcon/>
                </div>
                <div className={classes.input}>
                    {isAnyValueSelected() && <DisplayInfo/>}
                    {!isAnyValueSelected() && i18n.translate("SELECT_DATE")}
                </div>
            </div>

            {showPopup && <div className={classes.popup_container}>
                <div className={clsx(classes.row, classes.display_container)}>
                    <div>{i18n.translate("FROM")}: {DateTime.format(value[0])} </div>
                    <div>{i18n.translate("TO")}: {DateTime.format(value[1])}</div>
                </div>
                <div className={classes.row}>
                    <div className={classes.optionsContainer}>
                        {customRanges.map((range, i) => (
                            <Button
                                key={i}
                                type={getBtnType(range)}
                                fullWidth
                                size={ButtonSize.SMALL}
                                text={range.text}
                                onClick={() => selectRange(range)}
                            />
                        ))}
                    </div>
                    <div>
                        <LocalizationProvider locale={esLocale} dateAdapter={AdapterDateFns}>
                            <StaticDateRangePicker
                                allowSameDateSelection
                                className={classes.calendar}
                                calendars={1}
                                inputFormat="DD-MMM-YYYY"
                                displayStaticWrapperAs="desktop"
                                value={value}
                                onChange={(newValue) => {
                                    setValue(newValue);
                                }}
                                renderInput={(startProps, endProps) => (<></>)}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
                <div className={classes.buttons_container}>
                    <Button
                        type={ButtonType.PRIMARY}
                        onClick={onSelect}
                        disabled={isSelectBtnDisable()}
                        text={i18n.translate("SELECT")}
                    />
                    <Button
                        onClick={onCancel}
                        text={i18n.translate("CANCEL")}
                    />
                </div>
            </div>}
        </div>
    )
}
export default DateTimeRangeSelect;