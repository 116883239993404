import {action, makeAutoObservable} from "mobx";
import Response from "../model/Response";
import UserStore from "./UserStore";
import {RoleService} from "../service/RoleService";
import Role from "../entities/Role";

class CommonStore {

    public loadingPage: boolean;
    public roles: Role[];
    private userStore: UserStore;

    constructor(userStore: UserStore) {
        this.loadingPage = false;
        this.roles = [];
        this.userStore = userStore;
        makeAutoObservable(this);
        RoleService.get().then(response => this.roles = response.data || []);
    }

    @action
    public setLoading() {
        this.loadingPage = true;
    }

    @action
    public removeLoading() {
        this.loadingPage = false;
    }

    @action
    public logout() {
        this.userStore.logout();
    }

    public processErrors(response: Response<any>) {
        if (!response.success) {
            switch (response.status) {
                case 401:
                case 403:
                    this.userStore.logout();

            }
        }
    }

}

export default CommonStore;