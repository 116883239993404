import Request from "../../model/Request";
import Response from "../../model/Response";
import {RestClient} from "../../util/RestClient";
import {Config} from "../../util/Config";
import ProductOrderStatsResponse from "../../model/productOrder/ProductOrderStatsResponse";
import ArticleDeliveryOrderResponse from "../../model/productOrder/articleDelivery/ArticleDeliveryOrderResponse";

export module ArticleDeliveryService {
    export async function get(
        from: number = 0,
        to: number = 0,
        deliveryPersonId?: string,
        filter?: string,
        geoId?: string
    ): Promise<ArticleDeliveryOrderResponse[]> {
        const url = Config.get("GET_ARTICLE_DELIVERY_URL", {
            from,
            to,
            id: deliveryPersonId,
            filter,
            geoId
        });
        const request = new Request(url);
        const response = await RestClient.get(request);
        if (response && response.success) {
            return response.data.map((t: any) => new ArticleDeliveryOrderResponse(
                t.id,
                t.orderNumber,
                t.agencyId,
                t.clientId,
                t.clientName,
                t.contact,
                t.articles,
                t.createdAt,
                t.currentStatus,
                t.comments,
                t.difficultDelivery,
                t.observations
            ));
        }
        return [];
    }

    export async function find(id: string): Promise<Response<ArticleDeliveryOrderResponse>> {
        const url = Config.get("FIND_ARTICLE_DELIVERY_URL", {id});
        const request = new Request(url)
        const response = await RestClient.get(request);
        if (response.success) {
            const t = response.data;
            if (t) {
                const articleRemittance = new ArticleDeliveryOrderResponse(
                    t.id,
                    t.orderNumber,
                    t.agencyId,
                    t.clientId,
                    t.clientName,
                    t.contact,
                    t.articles,
                    t.createdAt,
                    t.currentStatus,
                    t.comments,
                    t.difficultDelivery,
                    t.observations
                );
                return new Response<ArticleDeliveryOrderResponse>(true, articleRemittance, response.error);
            }
        }
        return new Response<ArticleDeliveryOrderResponse>(true, undefined, response.error, response.status);
    }

    export async function updateStatus(articleDeliveryId: string, status: string, comment: string): Promise<Response<any>> {
        const url = Config.get("UPDATE_ARTICLE_DELIVERY_STATUS_URL", {
            id: articleDeliveryId,
            status: status
        });
        const request = new Request(url, { comment: comment ? comment : '' });
        return await RestClient.put(request);
    }

    export async function updateBulkStatus(ordersIds: string[], status: string, comment: string): Promise<Response<any>> {
        const url = Config.get("UPDATE_ARTICLE_DELIVERY_ORDERS_STATUS_URL", {
            status: status
        });
        const request = new Request(url, { ordersIds, comment: comment ? comment : '' });
        return await RestClient.put(request);
      }

    export async function getArticleDeliveryStats(deliveryPersonId?: string): Promise<ProductOrderStatsResponse | undefined> {
        const url = Config.get("GET_ARTICLE_DELIVERY_STATS_URL", {
            id: deliveryPersonId
        });
        const request = new Request(url);
        const response = await RestClient.get(request);
        if (response && response.success) {
            return new ProductOrderStatsResponse(response.data.delayedRemittanceAmount, response.data.deliveryAmount);
        }
        return undefined;
    }

}