const values = {
    "AGENCIES": "Agencias",
    "NO_AGENCIES_TITLE": "No hay agencias!!!",
    "NO_AGENCIES_SUBTITLE": "Verifica si estas aplicando algún filtro",
    "AGENCY_FILTER_PLACEHOLDER": "Nombre",
    "EDIT_AGENCY": "Editar Agencia",
    "NEW_AGENCY": "Nueva Agencia",
    "AGENCY_NAME": "Nombre de la Agencia",
    "USER_DATA": "Datos del usuario",
    "ERROR_00_400_02": "Ya existe una agencia con este nombre",
    "REMOVE_AGENCY": "Eliminar agencia",
    "REMOVE_AGENCY_CONFIRMATION": "¿Está seguro que desea eliminar la agencia?",
}

export default values;