import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import { ArticleDeliveryService } from "../../../service/productOrder/ArticleDeliveryService";
import ArticleDeliveryOrderResponse from "../../../model/productOrder/articleDelivery/ArticleDeliveryOrderResponse";

class ArticleDeliveryPrintStore {

    public productOrderIds: string[];
    public articleDeliveryOrders: ArticleDeliveryOrderResponse[];
    public isModalOpen: boolean;
    public loading: boolean;
    public commonStore: CommonStore;


    constructor(commonStore: CommonStore, productOrderIds: string[]) {
        this.productOrderIds = [...productOrderIds];
        this.articleDeliveryOrders = [];
        this.isModalOpen = false;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    @action
    public async loadInvoice() {
        this.loading = true;
        this.articleDeliveryOrders = [];
        for (const productOrderId of this.productOrderIds) {
            const response = await ArticleDeliveryService.find(productOrderId!);
            this.commonStore.processErrors(response);
            if (response.success && response.data) {
                this.articleDeliveryOrders.push(response.data);
            }
        }        
        this.loading = false;
    }

    @action
    public async openModal() {
        this.isModalOpen = true;
        await this.loadInvoice();
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }
}

export default ArticleDeliveryPrintStore;