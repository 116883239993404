import React from 'react';
import SignInPage from "./pages/SignInPage/SignInPage";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import RouteRepository from "./route/RouteRepository";
import MainLayout from "./pages/Layout/MainLayout/MainLayout";
import ProtectedRoute from "./pages/Layout/ProtectedRoute/ProtectedRoute";
import ChangePasswordDeliveryPerson from './pages/ChangePasswordDeliveryPerson/ChangePasswordDeliveryPerson';
import Providers from "./Providers";
import AllowAccess from "./shared_components/Access/AllowAccess/AllowAccess";


function App() {
    const routes = RouteRepository.getRoutes();
    const allRoutes = routes.flatMap(t => [t, ...t.children || []]);
    return (
        <Providers>
            <BrowserRouter>
                <Routes>
                    <Route path='/login' element={<SignInPage/>}/>
                    {/* <Route path='/recover-password' element={<PasswordRecoveryPage/>}/> */}
                    <Route path='/change-password' element={<ChangePasswordDeliveryPerson/>}/>
                    <Route path='/' element={<Navigate to='/login'/>}/>
                    <Route path='/' element={<MainLayout/>}>
                        {allRoutes.map((r, i) => (
                            <AllowAccess key={i} any={r.access}>
                                <Route path={r.path} element={<ProtectedRoute route={r}/>}/>
                            </AllowAccess>
                        ))}
                    </Route>
                </Routes>
            </BrowserRouter>
        </Providers>

    );
}

export default App;
