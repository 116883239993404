import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import DeliveryOrderType from "../../../model/productOrder/DeliveryOrderType";
import { MoneyDeliveryService } from "../../../service/productOrder/MoneyDeliveryService";
import { ArticleDeliveryService } from "../../../service/productOrder/ArticleDeliveryService";

class UpdateDeliveryOrdersStatusStore {

    public deliveryOrderType?: DeliveryOrderType
    public productOrderIds: string[];
    public isModalOpen: boolean;
    public loading: boolean;
    public commonStore: CommonStore;

    public comment?: string;
    public status?: string = 'DELIVERED';

    constructor(commonStore: CommonStore, productOrderIds: string[], deliveryOrderType?: DeliveryOrderType) {
        this.deliveryOrderType = deliveryOrderType
        this.productOrderIds = productOrderIds;
        this.isModalOpen = false;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    get enableButton(): boolean {
        return !!(this.status);
    }

    @action
    public async openModal() {
        this.loading = true;
        this.isModalOpen = true;
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    @action
    public async save(): Promise<boolean> {
        this.loading = true;
        const serviceToCall = this.getServiceToCall();        
        const response = await serviceToCall(this.productOrderIds, this.status!!, this.comment!!);
        this.commonStore.processErrors(response);
        this.loading = false;
        return response.success;
    }

    getServiceToCall() {
        switch (this.deliveryOrderType) {
            case DeliveryOrderType.ARTICLE_DELIVERY:
                return ArticleDeliveryService.updateBulkStatus;  
            default:
                return MoneyDeliveryService.updateBulkStatus;
        }
    }
    
}

export default UpdateDeliveryOrdersStatusStore;