import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    display_field_container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    },
    label: {
        fontSize: '12px',
        color: theme.palette.grey["700"],
        fontWeight: 800
    },
    value: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center'
    },
    error: {
        color: theme.palette.error.main
    },
    success: {
        color: theme.palette.success.main
    },
    warning: {
        color: theme.palette.warning.main
    },
    pending: {
        color: theme.palette.grey["700"],
    }
}));

export {useStyles}