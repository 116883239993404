import React from 'react';
import {useI18nContext} from "../../../contexts/I18nContext";
import RemittanceStatusHistoryStore from "./ProductOrderCommentsInfoStore";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./ProductOrderCommentsButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import Box from "../../../ui_components/Box/Box";
import Width from "../../../ui_components/common/enums/Width";
import Alignment from "../../../ui_components/common/enums/Alignment";
import ProductOrderCommentsButtonProperties from './ProductOrderCommentsButtonProperties';
import SidePanel from "../../../ui_components/SidePanel/SidePanel";
import CommentIcon from '@mui/icons-material/Comment';
import ShowComments from '../../Info/ShowComments/ShowComments';
import { useUserContext } from '../../../contexts/UserContext';

const ProductOrderCommentsButton: React.FC<ProductOrderCommentsButtonProperties> = ({
                                                                          showIcon = true,
                                                                          showText = true,
                                                                          disabled,
                                                                          className = '',
                                                                          productOrderId: moneyDeliveryOrderId,
                                                                          productOrderNumber,
                                                                          rounded = false,
                                                                          size = ButtonSize.DEFAULT,
                                                                          type = ButtonType.DEFAULT
                                                                      }) => {
    const classes = useStyles(showText);

    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const userContext = useUserContext();
    const store = new RemittanceStatusHistoryStore(commonContext, moneyDeliveryOrderId);

    const handleOnclick = async () => {
        await store.openModal();
    }

    const handleNewCommentOnclick = async (comment: string) => {
        store.addComment(comment); 
    }

    const onCloseModal = () => {
        store.closeModal();
    }

    const Icon = CommentIcon;
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? 'Comentarios' : ""}
                text={showText ? 'Comentarios' : ""}
                type={type}
                icon={showIcon ? <Icon/> : <></>}
            />
        }
        return <Button
            text={showText ? 'Comentarios' : ""}
            className={className}
            tooltip={!showText ? 'Comentarios' : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? <Icon/> : <></>}
        />
    }
    
    return <Observer>
        {() => (
            <>
                <SidePanel loading={store.loading} title={'Comentarios'} onClose={onCloseModal} open={store.isModalOpen}
                       className={classes.modal_container}>
                    {store.productOrderComments &&
                        <div className={classes.history_container}>
                            <Box
                                width={Width.FULL_WIDTH}
                                justifyContent={Alignment.START}>
                                {i18n.translate("REMITTANCE_ORDER_NUMBER")}: <strong className={classes.margin_left_4}>{productOrderNumber}</strong>
                            </Box>
                            <div className={classes.elements_container}>
                                <ShowComments
                                    onNewComment={handleNewCommentOnclick}
                                    comments={store.productOrderComments} loguedUserId={userContext.currentUser() ? userContext.currentUser()!!.id : ''}/>
                            </div>
                        </div>
                    }
                </SidePanel>
                <Btn/>
            </>
        )}
    </Observer>
}

export default ProductOrderCommentsButton;
