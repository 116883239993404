import React from 'react';
import {useStyles} from "../Transactions.style";
import clsx from "clsx";
import TransactionsRowProperties from "./TransactionsRowProperties";
import ShowDate from "../../../shared_components/Info/ShowDate/ShowDate";
import TransactionStatus from '../../../entities/TransactionStatus';
import ShowPrice from '../../../shared_components/Info/ShowPrice/ShowPrice';
import ShowTransactionComment from '../../../shared_components/Info/ShowTransactionComment/ShowTransactionComment';

const TransactionsRowMedium: React.FC<TransactionsRowProperties> = ({
                                                                          transaction
                                                                      }) => {


    const classes = useStyles();

    return (
        <>
            <div key={transaction.id} className={clsx(
                classes.contact_info_container,
                transaction.status === TransactionStatus.FAILURE && classes.error_container
            )}>
                <ShowDate timestamp={transaction.createdAt}/>
                <ShowPrice
                    value={transaction.direction === "OUT" && transaction.amount > 0 ?
                        transaction.amount * -1 : transaction.amount}
                    currency={transaction.currency}
                    valueBefore={transaction.balanceToBefore}
                />
                <ShowTransactionComment value={transaction.comment}/>
            </div>
        </>
    )
}

export default TransactionsRowMedium;
