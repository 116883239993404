import React from 'react';
import {useStyles} from "../ArticleDelivery.style";
import clsx from "clsx";
import ArticleDeliveryRowProperties from "./ArticleDeliveryRowProperties";
import ShowDate from "../../../shared_components/Info/ShowDate/ShowDate";
import ShowText from '../../../shared_components/Info/ShowText/ShowText';
import ButtonSize from '../../../ui_components/Button/ButtonSize';
import ButtonType from '../../../ui_components/Button/ButtonType';
import ShowOrderNumber from '../../../shared_components/Info/ShowOrderNumber/ShowOrderNumber';
import { useUserContext } from '../../../contexts/UserContext';
import ExpandoMenu from '../../../ui_components/ExpandoMenu/ExpandoMenu';
import UpdateDeliveryOrderStatusButton from '../../../shared_components/Buttons/UpdateDeliveryOrderStatusButton/UpdateDeliveryOrderStatusButton';
import Checkbox from '../../../ui_components/Checkbox/Checkbox';
import ShowArticleDeliveryArticles from '../../../shared_components/Info/ShowArticleDeliveryArticles/ShowArticleDeliveryArticles';
import ShowOrderStatus from '../../../shared_components/Info/ShowOrderStatus/ShowOrderStatus';
import ArticleDeliveryPrintButton from '../../../shared_components/Buttons/ArticleDeliveryPrintButton/ArticleDeliveryPrintButton';
import ProductOrderCommentsButton from '../../../shared_components/Buttons/ProductOrderCommentsButton/ProductOrderCommentsButton';
import ServiceName from '../../../entities/ServiceName';
import ShowPhoneNumber from '../../../shared_components/Info/ShowPhoneNumber/ShowPhoneNumber';

const ArticleDeliveryRowMedium: React.FC<ArticleDeliveryRowProperties> = ({
                                                                          articleDeliveryOrder,
                                                                          selectedDeliveryOrderIds,
                                                                          onArticleDeliveryOrderSelect,
                                                                          onUpdate
                                                                      }) => {


    const classes = useStyles();
    const userStore = useUserContext();

    const onArticleDeliverySelect = () => {
        onArticleDeliveryOrderSelect(articleDeliveryOrder.id);
    }

    return (
        <div
            className={clsx(!articleDeliveryOrder.difficultDelivery && classes.border_left_green, articleDeliveryOrder.difficultDelivery && classes.border_left_purple, classes.article_info_container, userStore.isRootAgency() ? classes.article_info_container_grid_template_big : classes.article_info_container_grid_template_small)}>
            <div>
                <div className={classes.order_number_container}>
                    <Checkbox text={''} onChange={onArticleDeliverySelect} selected={selectedDeliveryOrderIds.findIndex(t => t === articleDeliveryOrder.id) >= 0}/>
                    <ShowOrderNumber orderNumber={articleDeliveryOrder.orderNumber}/>
                </div>
                <ShowDate label={'Fecha'} timestamp={articleDeliveryOrder.createdAt} className={classes.margin_top_4}/>
            </div>
            <div>
                <ShowText text={articleDeliveryOrder.clientName} title={'Remitente'}/>
                <ShowText text={articleDeliveryOrder.contact.name + (articleDeliveryOrder.contact.lastName ? ' ' + articleDeliveryOrder.contact.lastName : '')} title={'Destinatario'} className={classes.margin_top_8}/>
                {articleDeliveryOrder.contact.mobilePhone &&
                        <ShowPhoneNumber value={articleDeliveryOrder.contact.mobilePhone} showFlag={false}/>}
                {articleDeliveryOrder.contact.homePhone &&
                        <ShowPhoneNumber value={articleDeliveryOrder.contact.homePhone} showFlag={false}/>}
                {articleDeliveryOrder.contact.secondMobilePhone &&
                        <ShowPhoneNumber value={articleDeliveryOrder.contact.secondMobilePhone} showFlag={false}/>}
            </div>
            <div>
                <div>
                    <small>Entrega</small> 
                    <div>
                            <ShowText
                                text={`${articleDeliveryOrder.contact.address}${articleDeliveryOrder.contact.neighborhood ? '. ' + articleDeliveryOrder.contact.neighborhood : ''}`}
                                title={articleDeliveryOrder.contact.geo.name + '. ' + articleDeliveryOrder.contact.geo.parentInfo}/>
                        </div>
                </div>
                {articleDeliveryOrder.observations && <ShowText text={articleDeliveryOrder.observations} title={'Observaciones'}/>}
            </div>
            <ShowArticleDeliveryArticles articles={articleDeliveryOrder.articles}/>
            <ShowOrderStatus status={articleDeliveryOrder.currentStatus} />
            <ExpandoMenu>
                <>
                    <UpdateDeliveryOrderStatusButton
                        size={ButtonSize.SMALL}
                        serviceName={ServiceName.ARTICLE_DELIVERY}
                        productOrderId={articleDeliveryOrder.id}
                        showText
                        type={ButtonType.SECONDARY}
                        onSave={onUpdate}
                    />
                    <ArticleDeliveryPrintButton
                        size={ButtonSize.SMALL}
                        productOrderIds={[articleDeliveryOrder.id]}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                    <ProductOrderCommentsButton
                        size={ButtonSize.SMALL}
                        productOrderId={articleDeliveryOrder.id}
                        productOrderNumber={articleDeliveryOrder.orderNumber}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                </>
            </ExpandoMenu>
        </div>
    )
}

export default ArticleDeliveryRowMedium;
