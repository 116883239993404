import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    total_sales_container: {
        background: theme.palette.common.white,
        padding: '0 10px 10px',
        flexGrow: 1,
    },
    title: {
        textTransform: 'uppercase',
        padding: '4px 8px',
        background: theme.palette.secondary.light,
        color: theme.palette.common.white,
        marginLeft: '-15px',
        marginTop: '-5px',
        marginBottom: '10px'
    },
    balance: {
        fontFamily: 'Alkatra, cursive',
        display: 'flex',
        flexDirection: 'column',
        lineHeight: '12px',
        marginTop: '5px',
        paddingRight: '5px',
        paddingTop: '10px',
        maxHeight: '45px',
        width: '120px'
    },
    body:{
        position: 'relative',
    },
    flex_column: {
        display: "flex",
        flexDirection: "column",
    },
    flex_row: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        gap: "20px",
    },
    '@media (max-width: 768px)': {
        flex_row: {
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
        },
    },
    pay_breakdown: {
        display: 'grid',
        gridTemplateColumns: "repeat( auto-fit, minmax(125px, 1fr))",
        columnGap: '8px',
        rowGap: '8px',
        minHeight: '75px',
        flexGrow: 1,
        padding: '0 8px',
    },
    loading_container: {
        minHeight: '80px',
    },
    field: {
        width: '100% !important',
        background: theme.palette.grey[100],
        padding: '0 0 0 4px',
        boxSizing: 'border-box',
        justifyContent: 'center',
        "& *": {
            color: `${theme.palette.success.dark} !important`,
            fill: `${theme.palette.success.dark} !important`
        }
    },
    box_container: {
        padding: '10px',
        width: 'fit-content',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: '22px',
        "& :last-child": {
            fontSize: '10px',
            fontWeight: '800',
            color: theme.palette.common.white
        },
        "& :first-child": {
            fontSize: '28px',
            color: theme.palette.common.white
        }
    },
    balance_positive: {
        color: theme.palette.success.light
    },
    balance_negative: {
        color: theme.palette.error.light
    },
    error_container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '18px',
        color: theme.palette.error.light,
        textAlign: 'center',
        cursor: 'pointer',
        textDecoration: "underline",
        "& svg": {
            fontSize: '50px'
        }
    },
    no_content_container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '14px',
        flexGrow: 1,
        color: theme.palette.grey[400],
        textAlign: 'center',
        cursor: 'pointer',
        "& svg": {
            fontSize: '50px'
        }
    }
}));

export {useStyles}