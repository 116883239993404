import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import { PackageDeliveryService } from "../../../service/productOrder/PackageDeliveryService";
import PackageDeliveryOrderResponse from "../../../model/productOrder/packageDelivery/PackageDeliveryOrderResponse";

class PackageDeliveryPrintStore {

    public productOrderIds: string[];
    public deliveryPersonId: string;
    public packageDeliveryOrders: PackageDeliveryOrderResponse[];
    public isModalOpen: boolean;
    public loading: boolean;
    public commonStore: CommonStore;


    constructor(commonStore: CommonStore, productOrderIds: string[], deliveryPersonId: string) {
        this.productOrderIds = [...productOrderIds];
        this.deliveryPersonId = deliveryPersonId;
        this.packageDeliveryOrders = [];
        this.isModalOpen = false;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    @action
    public async loadInvoice() {
        this.loading = true;
        this.packageDeliveryOrders = [];
        for (const productOrderId of this.productOrderIds) {
            const response = await PackageDeliveryService.find(productOrderId!);
            this.commonStore.processErrors(response);
            if (response.success && response.data) {
                this.packageDeliveryOrders.push(response.data);
            }
        }        
        this.loading = false;
    }

    @action
    public async openModal() {
        this.isModalOpen = true;
        await this.loadInvoice();
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }
}

export default PackageDeliveryPrintStore;