import React from 'react';
import {useStyles} from "../ArticleDelivery.style";
import clsx from "clsx";
import {motion} from "framer-motion";
import ArticleDeliveryRowProperties from "./ArticleDeliveryRowProperties";
import ButtonSize from '../../../ui_components/Button/ButtonSize';
import ButtonType from '../../../ui_components/Button/ButtonType';
import Checkbox from '../../../ui_components/Checkbox/Checkbox';
import ShowOrderNumber from '../../../shared_components/Info/ShowOrderNumber/ShowOrderNumber';
import ShowDate from '../../../shared_components/Info/ShowDate/ShowDate';
import ShowText from '../../../shared_components/Info/ShowText/ShowText';
import UpdateDeliveryOrderStatusButton from '../../../shared_components/Buttons/UpdateDeliveryOrderStatusButton/UpdateDeliveryOrderStatusButton';
import ViewInvoiceButton from '../../../shared_components/Buttons/MoneyDeliveryPrintButton/MoneyDeliveryPrintButton';
import ShowArticleDeliveryArticles from '../../../shared_components/Info/ShowArticleDeliveryArticles/ShowArticleDeliveryArticles';
import ProductOrderCommentsButton from '../../../shared_components/Buttons/ProductOrderCommentsButton/ProductOrderCommentsButton';
import ServiceName from '../../../entities/ServiceName';
import ShowPhoneNumber from '../../../shared_components/Info/ShowPhoneNumber/ShowPhoneNumber';


const ArticleDeliveryRowSmall: React.FC<ArticleDeliveryRowProperties> = ({
                                                                                    articleDeliveryOrder,
                                                                                    onArticleDeliveryOrderSelect,
                                                                                    onUpdate
                                                                                }) => {

    const classes = useStyles();

    const onArticleDeliverySelect = () => {
        onArticleDeliveryOrderSelect(articleDeliveryOrder.id);
    }

    return (
        <div className={clsx(classes.article_info_container_small)}>
            <div className={classes.first_row_small}>
                <div>
                    <div className={classes.order_number_container}>
                        <Checkbox text={''} onChange={onArticleDeliverySelect}/>
                        <ShowOrderNumber orderNumber={articleDeliveryOrder.orderNumber}/>
                    </div>
                    <ShowDate label={'Fecha'} timestamp={articleDeliveryOrder.createdAt} className={classes.margin_top_4}/>
                </div>
                <ShowArticleDeliveryArticles articles={articleDeliveryOrder.articles}/>
            </div>
            <div className={classes.second_row_small}>
                <ShowText text={articleDeliveryOrder.clientName} title={'Remitente'}/>
                <div>
                    <ShowText text={articleDeliveryOrder.contact.name + (articleDeliveryOrder.contact.lastName ? ' ' + articleDeliveryOrder.contact.lastName : '')} title={'Destinatario'}/>
                    {articleDeliveryOrder.contact.mobilePhone &&
                        <ShowPhoneNumber value={articleDeliveryOrder.contact.mobilePhone} showFlag={false}/>}
                    {articleDeliveryOrder.contact.homePhone &&
                            <ShowPhoneNumber value={articleDeliveryOrder.contact.homePhone} showFlag={false}/>}
                    {articleDeliveryOrder.contact.secondMobilePhone &&
                            <ShowPhoneNumber value={articleDeliveryOrder.contact.secondMobilePhone} showFlag={false}/>}
                </div>
            </div>
            <div>
                <div>
                    <small>Entrega</small> 
                    <div>
                            <ShowText
                                text={`${articleDeliveryOrder.contact.address}${articleDeliveryOrder.contact.neighborhood ? '. ' + articleDeliveryOrder.contact.neighborhood : ''}`}
                                title={articleDeliveryOrder.contact.geo.name + '. ' + articleDeliveryOrder.contact.geo.parentInfo}/>
                        </div>
                </div>
                {articleDeliveryOrder.observations && <ShowText text={articleDeliveryOrder.observations} title={'Observaciones'}/>}
            </div>
            <div className={classes.menu_container_small}>
                <motion.div animate={{scale: [0, 1]}} transition={{delay: 0.2, duration: 0.2}}>
                    <UpdateDeliveryOrderStatusButton
                        size={ButtonSize.SMALL}
                        serviceName={ServiceName.ARTICLE_DELIVERY}
                        productOrderId={articleDeliveryOrder.id}
                        showText
                        type={ButtonType.SECONDARY}
                        onSave={onUpdate}
                    />
                </motion.div>
                <motion.div animate={{scale: [0, 1]}} transition={{delay: 0.2, duration: 0.2}}>
                    <ViewInvoiceButton
                        size={ButtonSize.SMALL}
                        productOrderIds={[articleDeliveryOrder.id]}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                </motion.div>
                <motion.div animate={{scale: [0, 1]}} transition={{delay: 0.2, duration: 0.2}}>
                    <ProductOrderCommentsButton
                        size={ButtonSize.SMALL}
                        productOrderId={articleDeliveryOrder.id}
                        productOrderNumber={articleDeliveryOrder.orderNumber}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                </motion.div>
            </div>
        </div>
    )
}

export default ArticleDeliveryRowSmall;
