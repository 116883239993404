import React from 'react';
import {useStyles} from "./Modal.style";
import ModalProperties from "./ModalProperties";
import CloseIcon from '@material-ui/icons/Close';
import {Dialog} from "@material-ui/core";
import PageLoading from "../PageLoading/PageLoading";
import {Observer} from "mobx-react-lite";
import clsx from "clsx";


const Modal: React.FC<ModalProperties> = ({
                                              className = '',
                                              onClose,
                                              title,
                                              open,
                                              loading = false,
                                              children,
                                              fullScreen = true
                                          }) => {
    const classes = useStyles();

    return (
        <Dialog maxWidth={false} fullScreen={fullScreen} onClose={onClose} open={open}>
            <div className={classes.header_container}>
                <div className={classes.title}>{title}</div>
                <div className={classes.close_button} onClick={onClose}>
                    <CloseIcon/>
                </div>
            </div>
            <div className={clsx(classes.content_container, className)}>
                {children}
                {loading && <Observer>{() => (<PageLoading isLoading={loading}/>)}</Observer>}
            </div>
        </Dialog>
    )
}
export default Modal;