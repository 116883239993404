import React from 'react';
import {useStyles} from "./ShowOrderNumber.style";
import ShowOrderNumberProperties from "./ShowOrderNumberProperties";


const ShowOrderNumber: React.FC<ShowOrderNumberProperties> = ({
                                                          orderNumber,
                                                          className = ''
                                                      }) => {
    const classes = useStyles();

    return (
        <div className={className}>
            <>
                {<div className={classes.text}>{`${orderNumber}`}</div>}
            </>
        </div>
    )
}
export default ShowOrderNumber;