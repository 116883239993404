enum Access {
    NONE = 0,
    LIST_USER = 1,
    CREATE_USER = 2,
    LIST_AGENCY = 4,
    CREATE_AGENCY = 8,
    LIST_DELIVERY_PERSON = 16,
    CREATE_DELIVERY_PERSON = 32,
    ADD_BALANCE_TO_DELIVERY_PERSON = 64,
    GET_BALANCE_TO_DELIVERY_PERSON = 128,
    GET_TRANSACTIONS = 256,
    LIST_ALL_INVOICES = 512,
    LIST_MONEY_DELIVERY_FEE = 1024,
    CREATE_MONEY_DELIVERY_FEE = 2048,
    LIST_MONEY_DELIVERY_EXCHANGE_RATE = 4096,
    CREATE_MONEY_DELIVERY_EXCHANGE_RATE = 8192,
    SET_MONEY_DELIVERY_DELIVERY_PERSON = 16384,
    REMOVE_MONEY_DELIVERY_ORDER = 32768,
    LIST_SALE_PRICE = 65536,
    CREATE_SALE_PRICE = 131072,
    ADD_DELIVERY_COST = 262144,
    GET_AGENCY_SALES = 524288,
    UPDATE_MONEY_DELIVERY_CONTACT = 4194304,
}

export default Access;