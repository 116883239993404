import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import ServiceName from "../../../entities/ServiceName";
import ProductOrderStatus from "../../../entities/productOrder/ProductOrderStatus";
import { PackageDeliveryService } from "../../../service/productOrder/PackageDeliveryService";
import UserStore from "../../../stores/UserStore";

const ERROR_MAP = {
    email: ["ERROR_00_400_07", "ERROR_00_400_08", "ERROR_00_400_09"],
    phone: ["ERROR_00_400_10"],
    name: ["ERROR_00_400_06"],
}

class UpdatePackageDeliveryOrderStatusStore {

    public serviceName: ServiceName;
    public productOrderId: string;
    public isModalOpen: boolean;
    public loading: boolean;
    public emailError?: string;
    public phoneError?: string;
    public nameError?: string;
    public commonStore: CommonStore;
    public userStore: UserStore;

    public comment?: string;
    public currentDeliveryPersonStatus: ProductOrderStatus;


    constructor(
        commonStore: CommonStore,
        userStore: UserStore,
        productOrderId: string,
        serviceName: ServiceName,
        currentDeliveryPersonStatus: ProductOrderStatus
    ) {
        this.serviceName = serviceName;
        this.productOrderId = productOrderId;
        this.currentDeliveryPersonStatus = currentDeliveryPersonStatus;
        this.isModalOpen = false;
        this.loading = false;
        this.commonStore = commonStore;
        this.userStore = userStore;
        makeAutoObservable(this);
    }

    @action
    public async openModal() {
        this.loading = true;
        this.isModalOpen = true;
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    @action
    public async save(): Promise<boolean> {
        this.loading = true;
        const serviceToCall = this.getFunctionToCall();
        const response = await serviceToCall;
        this.commonStore.processErrors(response);
        this.processError(response.error);
        this.loading = false;
        return response.success;
    }

    getFunctionToCall() {
        switch (this.currentDeliveryPersonStatus) {
            case ProductOrderStatus.ASSIGNED:
                return PackageDeliveryService.updateStatusToReceived(this.productOrderId, this.userStore.currentUser()!!.id);
            default:
                return PackageDeliveryService.updateStatusToDelivered(this.productOrderId, this.userStore.currentUser()!!.id, this.comment ?? '');
        }
    }

    processError(error?: string) {
        this.emailError = undefined;
        this.phoneError = undefined;
        this.nameError = undefined;
        if (error) {
            if (ERROR_MAP.email.includes(error)) {
                this.emailError = error;
            }
            if (ERROR_MAP.phone.includes(error)) {
                this.phoneError = error;
            }
            if (ERROR_MAP.name.includes(error)) {
                this.nameError = error;
            }
        }

    }
}

export default UpdatePackageDeliveryOrderStatusStore;