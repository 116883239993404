import React from 'react';
import {useStyles} from "./Header.style";
import TopupAgenciesText from "../../../ui_components/Icon/icons/TopupAgenciesText";
import LogoutButton from "../../../shared_components/Buttons/LogoutButton/LogoutButton";
import ButtonSize from "../../../ui_components/Button/ButtonSize";

const Header: React.FC = () => {
    const classes = useStyles();
    return (
        <div className={classes.header_container}>
            <TopupAgenciesText fontSize={35}/>
            <LogoutButton size={ButtonSize.SMALL} showText={false}/>
        </div>
    );
}

export default Header;