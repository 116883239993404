import CommonStore from "../../../stores/CommonStore";
import {action, makeObservable, observable} from "mobx";
import { PackageDeliveryService } from "../../../service/productOrder/PackageDeliveryService";
import ProductOrderStatsResponse from "../../../model/productOrder/ProductOrderStatsResponse";

class PackageDeliveryStatsStore {

    public commonStore: CommonStore;
    public loading: boolean;
    public deliveryPersonId?: string;
    public packageDeliveryStats?: ProductOrderStatsResponse;

    constructor(
        commonStore: CommonStore,
        deliveryPersonId?: string
    ) {
        this.commonStore = commonStore;
        this.loading = true;
        this.deliveryPersonId = deliveryPersonId;
        makeObservable(this, {
            loading: observable,
            packageDeliveryStats: observable,
        });
    }

    @action
    public async getPackageDeliveryStats(): Promise<void> {
        this.loading = true;
        const response = await PackageDeliveryService.getPackageDeliveryStats(this.deliveryPersonId);
        this.packageDeliveryStats = response;
        this.loading = false;
    }
}

export default PackageDeliveryStatsStore;