import React, {createRef} from 'react';
import ArticleDeliveryPrintButtonProperties from "./ArticleDeliveryPrintButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import ArticleDeliveryPrintStore from "./ArticleDeliveryPrintStore";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./ArticleDeliveryPrintButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import PrintButton from "../PrintButton/PrintButton";
import SidePanel from "../../../ui_components/SidePanel/SidePanel";
import clsx from 'clsx';
import ShowOrderNumber from '../../Info/ShowOrderNumber/ShowOrderNumber';
import ShowDate from '../../Info/ShowDate/ShowDate';
import ArticleDeliveryOrderArticleResponse from '../../../model/productOrder/articleDelivery/ArticleDeliveryOrderArticleResponse';


const ArticleDeliveryPrintButton: React.FC<ArticleDeliveryPrintButtonProperties> = ({
                                                                      onClick = () => {
                                                                      },
                                                                      onSave = () => {
                                                                      },
                                                                      children,
                                                                      showIcon = true,
                                                                      showText = true,
                                                                      disabled,
                                                                      className = '',
                                                                      productOrderIds,
                                                                      rounded = false,
                                                                      showButton = true,
                                                                      size = ButtonSize.DEFAULT,
                                                                      type = ButtonType.DEFAULT
                                                                  }) => {
    const classes = useStyles(showText);

    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const store = new ArticleDeliveryPrintStore(commonContext, productOrderIds);
    const containerRef = createRef<HTMLDivElement>()


    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const onCloseModal = () => {
        store.closeModal();
        if (onSave) {
            onSave();
        }
    }

    const text = "Imprimir";
    const Icon = LocalPrintshopIcon;
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? <Icon/> : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? <Icon/> : <></>}
        />
    }

    const InvoiceData: React.FC<{ signerRole: string }> = ({signerRole}) => {
        return <div className={classes.invoice_data_container}>
            {store.articleDeliveryOrders.map((articleDeliveryOrder, i) => (<div className={clsx(classes.body, i > 0 && classes.order_item_container)}>
                <div className={classes.column_container}>
                    <div className={classes.column}>
                        <div className={classes.row}>
                            <div className={classes.subtitle}>{`${i18n.translate("CLIENT")}:`} </div>
                            &nbsp;
                            <div>{articleDeliveryOrder.clientName}</div>
                        </div>
                        
                        <div className={classes.row}>
                            <div className={classes.width_90}>
                                <div className={classes.sign_container}/>
                                <div className={classes.row}>
                                    <div className={classes.subtitle}>{`CI`} </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.row}>
                            <div className={classes.width_90}>
                                <div className={classes.sign_container}/>
                                <div className={classes.row}>
                                    <div className={classes.subtitle}>{`Firma y Fecha`} </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.column}>
                    <div className={classes.row}>
                            <div className={classes.subtitle}>{`${i18n.translate("ADDRESSEE")}:`} </div>
                        </div>
                        <div className={classes.row}>
                            {articleDeliveryOrder.contact?.name + (articleDeliveryOrder.contact?.lastName ? ' ' + articleDeliveryOrder.contact?.lastName : '')}
                        </div>
                        <div className={classes.row}>
                            {articleDeliveryOrder.contact?.mobilePhone && <span>{'Celular: ' + articleDeliveryOrder.contact?.mobilePhone.number}</span>}
                            {articleDeliveryOrder.contact?.homePhone && <span>{'. Fijo: ' + articleDeliveryOrder.contact?.homePhone.number}</span>}
                        </div>
                        <div className={classes.row}> 
                            <span><strong>Entregar en: </strong> 
                            {articleDeliveryOrder.contact?.address}{articleDeliveryOrder.contact?.neighborhood ? '. ' + articleDeliveryOrder.contact?.neighborhood : ''}. {articleDeliveryOrder.contact?.geo.name + '. ' + articleDeliveryOrder.contact.geo.parentInfo}</span>
                        </div>
                        {articleDeliveryOrder.observations && <div className={classes.row}><span><strong>Observaciones: </strong>{articleDeliveryOrder.observations}</span></div>}
                    </div>
                    <div className={classes.column}>
                        <div>
                            <ShowOrderNumber orderNumber={articleDeliveryOrder.orderNumber}/>
                        </div>
                        <div className={classes.row}>
                            <ShowDate timestamp={articleDeliveryOrder.createdAt} showTime={false} className={classes.margin_top_4}/>
                        </div>
                    </div>
                </div>
                <div className={classes.articles_container}>
                    {articleDeliveryOrder.articles.map((article: ArticleDeliveryOrderArticleResponse) => (
                        <div>
                            <div className={classes.articles_name}>{article.articleName} - Cant: {article.amount}</div>
                            {article.articleDescription.split('\n').map((line: string) => (<div>{line}</div>))}
                        </div>
                    ))}
                </div>
            </div>))}
        </div>
    }

    return <Observer>
        {() => (
            <>
                <SidePanel loading={store.loading} title={`Orden de envio`}
                           onClose={onCloseModal} open={store.isModalOpen} className={classes.modal_container}>
                    <div className={classes.view_invoice_container}>
                        <div className={classes.toolbar_container}>
                            <PrintButton type={ButtonType.DEFAULT} onClick={() => {
                                containerRef.current?.scroll(0, 0);
                            }}/>
                        </div>
                        <div className={classes.invoice_container} ref={containerRef}>
                            <div>
                                <InvoiceData signerRole={i18n.translate("ADDRESSEE")}/>
                            </div>
                        </div>
                    </div>
                </SidePanel>
                {showButton && <Btn/>}
                {!showButton && <div onClick={handleOnclick}>{children}</div>}
            </>
        )}
    </Observer>
}

export default ArticleDeliveryPrintButton;
