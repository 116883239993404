import ProductOrderContactResponse from "../ProductOrderContactResponse";
import DeliveryOrderComment from "../../../entities/productOrder/DeliveryOrderComment";
import ProductOrderStatus from "../../../entities/productOrder/ProductOrderStatus";
import ItemsAndCategories from "./ItemsAndCategories";

class PackageDeliveryOrderResponse {
    public id: string;
    public orderNumber: string;
    public agencyId: string;
    public clientId: string;
    public clientName: string;
    public contact: ProductOrderContactResponse;
    public itemsAndCategories: ItemsAndCategories[];
    public createdAt: number;
    public currentStatus: ProductOrderStatus;
    public comments: DeliveryOrderComment[];
    public difficultDelivery: boolean;
    public observations?: string;
    public currentDeliveryPersonStatus: ProductOrderStatus;

    constructor(
        id: string,
        orderNumber: string,
        agencyId: string,
        clientId: string,
        clientName: string,
        contact: ProductOrderContactResponse,
        itemsAndCategories: ItemsAndCategories[],
        createdAt: number,
        currentStatus: ProductOrderStatus,
        comments: DeliveryOrderComment[],
        difficultDelivery: boolean,
        currentDeliveryPersonStatus: ProductOrderStatus,
        observations?: string,
    ) {
        this.id = id;
        this.orderNumber = orderNumber;
        this.agencyId = agencyId;
        this.clientId = clientId;
        this.clientName = clientName;
        this.contact = contact;
        this.itemsAndCategories = itemsAndCategories;
        this.createdAt = createdAt;
        this.currentStatus = currentStatus;
        this.comments = comments;
        this.difficultDelivery = difficultDelivery;
        this.observations = observations;
        this.currentDeliveryPersonStatus = currentDeliveryPersonStatus;
    }
}

export default PackageDeliveryOrderResponse;