enum ProductOrderStatus {
    PENDING="PENDING",
    PAYED="PAYED",
    COMPLETED="COMPLETED",
    CANCELED="CANCELED",
    REMOVED="REMOVED",
    ASSIGNED="ASSIGNED",
    RETRYING="RETRYING",
    PROCESSING="PROCESSING",
    DELIVERED="DELIVERED",
    NOT_DELIVERED="NOT_DELIVERED",
    RECEIVED="RECEIVED",
}

export default ProductOrderStatus;