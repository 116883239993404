import Route from "./Route";
import DashboardIcon from '@material-ui/icons/Dashboard';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import Dashboard from "../pages/Dashboard/Dashboard";
import Access from "../shared_components/Access/AllowAccess/Access";
import MoneyDelivery from "../pages/MoneyDelivery/MoneyDelivery";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Transactions from "../pages/Transactions/Transactions";
import ArticleDelivery from "../pages/ArticleDelivery/ArticleDelivery";
import PackageDelivery from "../pages/PackageDelivery/PackageDelivery";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

class RouteRepository {
    static getRoutes(): Route[] {
        return [
            new Route("/dashboard", DashboardIcon, "DASHBOARD", Dashboard),

            new Route("/money-remittances", AttachMoneyIcon, "MONEY_REMITTANCES", MoneyDelivery, [Access.NONE]),

            new Route("/article-remittances", FastfoodIcon, "ARTICLE_REMITTANCES", ArticleDelivery, [Access.NONE]),

            new Route("/package-remittances", LocalShippingIcon, "PACKAGE_REMITTANCE", PackageDelivery, [Access.NONE]),

            new Route("/transactions", AccountBalanceIcon, "BALANCE_HISTORY", Transactions, [Access.NONE]),

        ];
    }

    static getRoute(path: string): Route | undefined {
        const routes = this.getRoutes();
        const allRoutes = routes.flatMap(t => [t, ...t.children || []]);
        return allRoutes.find(r => r.path === path);
    }
}

export default RouteRepository;