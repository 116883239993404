const values = {
    "ERROR_00_401_00": "Error inesperado",
    "ERROR_00_401_01": "Correo o clave inválido",
    "ERROR_00_401_02": "Error de autenticación inesperado",
    "ERROR_00_401_03": "Dispositivo no autorizado",
    "ERROR_00_401_04": "No tiene acceso al servicio solicitado",
    "ERROR_00_401_05": "Error de autenticación de agencia inesperado",
    "ERROR_00_400_06": "Nombre de usuario requerido",
    "ERROR_00_400_07": "Email requerido",
    "ERROR_00_400_08": "El correo no es válido",
    "ERROR_00_400_09": "Ya existe un vendedor con este correo",
    "ERROR_00_400_10": "Ya existe un vendedor con este número de teléfono",
    "ERROR_00_400_20": "El correo no es válido",
    "ERROR_00_400_21": "Ya existe un cliente con este correo",
    "ERROR_00_400_22": "Nombre de cliente requerido",
    "ERROR_00_400_23": "Ya existe un cliente con este nombre",
    "ERROR_00_400_24": "Ya existe un cliente con este número de teléfono",
    "ERROR_00_400_30": "Nombre del contacto requerido",
    "ERROR_00_400_31": "Ya existe un contacto con ese nombre",
    "ERROR_00_400_32": "Ya existe un contacto con ese teléfono",
    "ERROR_00_400_33": "Ya existe un combo con ese nombre",
    "ERROR_00_400_34": "El precio no puede ser negativo",
    "ERROR_00_400_35": "El cambio no puede ser negativo",
    "ERROR_00_400_36": "La cantidad no puede ser negativa",
    "ERROR_00_500_52": "Ya existe una tasa de cambio con esta modena",
    "ERROR_00_500_57": "Ya existe un cargo en el mismo rango de valores",

    "AMOUNT_REQUIRED": "Saldo requerido",
    "CURRENCY_REQUIRED": "Tipo de moneda requerido",
    "ERROR": "Error inesperado",
    "SUCCESS": "Saldo actualizado con éxito", 
}

export default values;