import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    show_comments_container: {
        display: 'flex',
        alignItems: 'flex-start',
        width: '100%',
        flexDirection: 'column'
    },
    icon: {
        color: theme.palette.grey[700],
        fontSize: '18px'
    },
    value_container: {
        color: theme.palette.grey[600],
        fontSize: '14px',
        marginLeft: '4px'
    },
    empty: {
        "& $value_container,$icon": {
            fontStyle: 'italic',
            color: theme.palette.grey[400],
        }
    },
    input: {
        marginTop: '16px'
    },
    new_comment_container: {
        display: 'flex',
        width: '100%'
    },
    new_comment_btn_container: {
        display: 'flex',
        paddingTop: '19px',
        marginLeft: '10px'
    },
    comment_container: {
        maxHeight: '500px',
        overflow: 'auto',
        width: '100%',
        padding: '0 5px;'
    },
    no_item_container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

export {useStyles}