import React from 'react';
import PackageDeliveryRowProperties from "./PackageDeliveryRowProperties";
import ViewMedium from "../../../ui_components/MediaView/ViewMedium";
import PackageDeliveryRowMedium from "./PackageDeliveryRowMedium";
import ViewLarge from "../../../ui_components/MediaView/ViewLarge";
import ViewSmall from "../../../ui_components/MediaView/ViewSmall";
import PackageDeliveryRowSmall from "./PackageDeliveryRowSmall";

const PackageDeliveryRow: React.FC<PackageDeliveryRowProperties> = ({
                                                    packageDeliveryOrder,
                                                    selectedDeliveryOrderIds,
                                                    onPackageDeliveryOrderSelect,
                                                    onUpdate
                                                }) => {
    return (
        <>
            <ViewSmall>
                <PackageDeliveryRowSmall onUpdate={onUpdate} onPackageDeliveryOrderSelect={onPackageDeliveryOrderSelect} packageDeliveryOrder={packageDeliveryOrder} selectedDeliveryOrderIds={selectedDeliveryOrderIds}/>
            </ViewSmall>
            <ViewMedium>
                <PackageDeliveryRowMedium onUpdate={onUpdate} onPackageDeliveryOrderSelect={onPackageDeliveryOrderSelect} packageDeliveryOrder={packageDeliveryOrder}  selectedDeliveryOrderIds={selectedDeliveryOrderIds}/>
            </ViewMedium>
            <ViewLarge>
                <PackageDeliveryRowMedium onUpdate={onUpdate} onPackageDeliveryOrderSelect={onPackageDeliveryOrderSelect} packageDeliveryOrder={packageDeliveryOrder}  selectedDeliveryOrderIds={selectedDeliveryOrderIds}/>
            </ViewLarge>
        </>
    )
}

export default PackageDeliveryRow;
