import React, {createRef, useEffect, useState} from 'react';
import {useStyles} from "./ExpandoMenu.style";
import ExpandoMenuProperties from "./ExpandoMenuProperties";
import clsx from "clsx";
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import {motion} from "framer-motion";


const ExpandoMenu: React.FC<ExpandoMenuProperties> = ({
                                                          className = '',
                                                          children
                                                      }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const menuRef = createRef<HTMLDivElement>();
    useEffect(() => {
        const clickOutside = (event: any) => {
            const dialog = document.querySelectorAll(".MuiDialog-container,.MuiPaper-root");
            if (menuRef && !menuRef.current?.contains(event.target) && dialog.length === 0) {
                setIsMenuOpen(false);
            }
        }
        document.addEventListener('mousedown', clickOutside);
        return () => {
            document.removeEventListener('mousedown', clickOutside)
        }
    }, [menuRef])

    const classes = useStyles();
    const variants = {
        open: {opacity: 1},
        closed: {opacity: 0},
    }

    return (
        <div className={clsx(classes.expando_menu_container, className)} ref={menuRef}>
            <div className={clsx(classes.button_container)} onClick={() => setIsMenuOpen(!isMenuOpen)}>
                {!isMenuOpen && <MenuIcon/>}
                {isMenuOpen && <MenuOpenIcon/>}
            </div>
            {isMenuOpen &&
                <motion.nav animate={isMenuOpen ? "open" : "closed"} variants={variants}
                            className={classes.menu_items_container}>
                    {children}
                </motion.nav>}

        </div>
    )
}
export default ExpandoMenu;