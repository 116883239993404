import React from 'react';
import {useStyles} from "./ShowText.style";
import ShowTextProperties from "./ShowTextProperties";


const ShowText: React.FC<ShowTextProperties> = ({
                                                          text,
                                                          title = '',
                                                          className = '',
                                                          buttons = []
                                                      }) => {
    const classes = useStyles();

    return (
        <div className={className}>
            <>
                {title && <div className={classes.title}>{`${title}:`}</div>}
                <div>{text ? text : '-'}</div>
                <div>
                    {buttons && buttons.length > 0 && buttons.map(t => (
                        <span>{t}</span>
                    ))}
                </div>
            </>
        </div>
    )
}
export default ShowText;
