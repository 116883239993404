import React from 'react';
import {useStyles} from "./MessageCard.style";
import MessageCardProperties from "./MessageCardProperties";
import Box from "../Box/Box";
import Width from "../common/enums/Width";
import Alignment from "../common/enums/Alignment";
import clsx from "clsx";
import Direction from "../common/enums/Direction";
import {motion} from "framer-motion"
import Button from "../Button/Button";
import ButtonSize from "../Button/ButtonSize";
import ButtonType from "../Button/ButtonType";


const MessageCard: React.FC<MessageCardProperties> = ({
                                                          className = '',
                                                          icon,
                                                          title,
                                                          subtitle,
                                                          actions = []
                                                      }: MessageCardProperties) => {
    const classes = useStyles();
    const Icon = icon;
    return (
        <Box direction={Direction.COLUMN} className={clsx(classes.empty_list_container, className)}
             width={Width.FULL_WIDTH}
             justifyContent={Alignment.CENTER}
             alignItems={Alignment.CENTER}>
            <motion.div
            ><Icon/></motion.div>
            <div className={classes.title}>{title}</div>
            <div className={classes.subtitle}>{subtitle}</div>
            <div className={classes.actions_container}>
                {actions?.map(a => (
                    <Button
                        onClick={a.onClick}
                        text={a.text}
                        startIcon={a.icon}
                        size={ButtonSize.SMALL}
                        type={ButtonType.SECONDARY}/>
                ))}
            </div>
        </Box>
    )
}
export default MessageCard;