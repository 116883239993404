import Currency from "../../../entities/Currency";
import Geo from "../../../entities/Geo";
import Phone from "../../../entities/Phone";
import DeliveryOrderComment from "../../../entities/productOrder/DeliveryOrderComment";
import ProductOrderStatus from "../../../entities/productOrder/ProductOrderStatus";

class MoneyDeliveryOrderDetailsResponse {
    public id: string;
    public orderNumber: string;
    public agencyId: string;
    public clientId: string;
    public clientName: string;
    public contactName: string;
    public contactPhone: Phone;
    public address: string;
    public geo: Geo;
    public contactBankCardId: string;
    public createdAt: number;
    public totalToReceive: number;
    public currencyToReceive: Currency;
    public currentStatus: ProductOrderStatus;
    public isBankCard: boolean;
    public contactBankCardBank: string;
    public contactBankCardNumber: string;
    public comments: DeliveryOrderComment[];
    public difficultDelivery: boolean;
    public observations?: string;

    constructor(
        id: string,
        orderNumber: string,
        agencyId: string,
        clientId: string,
        clientName: string,
        contactName: string,
        contactPhone: Phone,
        address: string,
        geo: Geo,
        contactBankCardId: string,
        createdAt: number,
        totalToReceive: number,
        currencyToReceive: Currency,
        currentStatus: ProductOrderStatus,
        isBankCard: boolean,
        contactBankCardBank: string,
        contactBankCardNumber: string,
        comments: DeliveryOrderComment[],
        difficultDelivery: boolean,
        observations?: string
    ) {
        this.id = id;
        this.orderNumber = orderNumber;
        this.agencyId = agencyId;
        this.clientId = clientId;
        this.clientName = clientName;
        this.contactName = contactName;
        this.contactPhone = contactPhone;
        this.address = address;
        this.geo = geo;
        this.contactBankCardId = contactBankCardId;
        this.createdAt = createdAt;
        this.totalToReceive = totalToReceive;
        this.currencyToReceive = currencyToReceive;
        this.currentStatus = currentStatus;
        this.isBankCard = isBankCard;
        this.contactBankCardBank = contactBankCardBank;
        this.contactBankCardNumber = contactBankCardNumber;
        this.comments = comments;
        this.difficultDelivery = difficultDelivery;
        this.observations = observations;
    }

}

export default MoneyDeliveryOrderDetailsResponse;