import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    package_container: {
        display: 'flex',
        flexDirection: 'column',
    },
    package_table: {
        display: 'flex',
        flexDirection: 'column',
    },
    filter_buttons_container: {
        display: "flex",
        justifyContent: "end",
        gridColumn: '1 / -1'
    },
    elements_container: {},
    package_info_container_grid_template_big: {
        gridTemplateColumns: '180px 200px auto 200px 150px 100px',
    },
    package_info_container_grid_template_small: {
        gridTemplateColumns: '180px 200px auto 200px 150px 100px',
    },
    package_info_container: {
        position: 'relative',
        marginTop: '4px',
        display: 'grid',
        backgroundColor: theme.palette.common.white,
        borderRadius: 4,
        padding: 8,
        border: `1px solid ${theme.palette.grey["400"]}`,
        "&:hover": {
            backgroundColor: `${theme.palette.grey[200]}`,
            cursor: 'pointer',
            border: `1px solid ${theme.palette.secondary.light}`,
            borderLeft: `8px solid ${theme.palette.secondary.light}`,
        }
    },
    border_left_green: {
        borderLeft: `8px solid ${theme.palette.success.light}`,
    },
    border_left_purple: {
        borderLeft: `8px solid #eb86eb`,
    },
    package_info_container_small: {
        position: 'relative',
        marginTop: '4px',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
        placeItems: 'stretch',
        rowGap: 16,
        columnGap: 16,
        backgroundColor: theme.palette.common.white,
        borderRadius: 4,
        padding: 8,
        border: `1px solid ${theme.palette.grey["400"]}`,
        borderLeft: `8px solid ${theme.palette.success.light}`,
    },
    inactive: {
        borderLeft: `8px solid ${theme.palette.grey["300"]}`,
        backgroundColor: `${theme.palette.grey["300"]}8c`,
        opacity: '0.8'
    },
    menu_container: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        "& > *": {
            marginLeft: '4px'
        }
    },
    menu_container_small: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        "& > *": {
            marginLeft: '4px'
        }
    },
    filter_container: {
        display: "grid",
        marginTop: '16px',
        gridTemplateColumns: "repeat( auto-fit, minmax(280px, 1fr))",
        rowGap: 16,
        columnGap: 8,
        "& button": {
            justifySelf: "end"
        }
    },
    tools_container: {},
    options_buttons_container: {
        display: "flex",
        justifyContent: "start"
    },
    search_btn: {
        justifySelf: 'flex-end',
        marginLeft: '5px'
    },
    margin_top_8: {
        marginTop: '8px'
    },
    margin_top_4: {
        marginTop: '4px'
    },
    order_number_container: {
        display: 'flex',
    },
    first_row_small: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    second_row_small: {
        display: 'flex',
        gap: '20px'
    },
    select_all_checkbox: {
        marginLeft: '8px',
        marginRight: '10px'
    }
}));

export {useStyles}