import React, {useEffect} from 'react';
import BalancesProperties from "./BalancesProperties";
import {useStyles} from "./Balances.style";
import BalancesStore from "./BalancesStore";
import {useCommonContext} from "../../../contexts/CommonContext";
import {Observer} from "mobx-react-lite";
import clsx from "clsx";
import PageLoading from "../../../ui_components/PageLoading/PageLoading";
import {useI18nContext} from "../../../contexts/I18nContext";
import { useUserContext } from '../../../contexts/UserContext';
import { CurrencyModule } from '../../../util/Currency';

const Balances: React.FC<BalancesProperties> = ({
                                                                className = '',
                                                            }) => {

    const i18n = useI18nContext();
    const commonStore = useCommonContext();
    const userStore = useUserContext();
    const store = new BalancesStore(commonStore, userStore.currentUser()?.id);
    const classes = useStyles();

    useEffect(() => {
        store.getBalances();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Observer>{() => (
        <div className={clsx(classes.total_sales_container, classes.flex_column, className)}>
            {<div className={classes.title}>{'Saldo actual'}</div>}
            <div className={clsx(classes.flex_row, classes.body)}>
                {store.balances &&
                    store.balances.map((balance, i) => (
                        <div className={clsx(classes.balance, classes.box_container, classes.flex_column, balance.amount < 0 && classes.balance_negative, balance.amount >= 0 && classes.balance_positive)}>
                            {CurrencyModule.format(balance.amount || 0, balance.currency)}
                        </div>
                    ))}
                <PageLoading className={classes.loading_container} loadingSize={40} isLoading={store.loading}
                             text={`${i18n.translate("LOADING")} ${'Dinero' || ''}`}/>
            </div>
        </div>
    )}</Observer>

}


export default Balances;