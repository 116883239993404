import React, {useEffect, useState} from 'react';
import TextInput from "../../../ui_components/TextInput/TextInput";
import LockIcon from '@material-ui/icons/Lock';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import {useStyles} from "./PasswordInput.style";
import TextInputType from "../../../ui_components/TextInput/TextInputType";
import PasswordInputProperties from "./PasswordInputProperties";
import {useI18nContext} from "../../../contexts/I18nContext";


const PasswordInput: React.FC<PasswordInputProperties> = ({
                                                              value,
                                                              onChange,
                                                              disabled,
                                                              error,
                                                              fullWidth = false,
                                                              className = ''
                                                          }) => {
    const classes = useStyles();
    const i18n = useI18nContext();
    const [password, setPassword] = useState(value);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        setPassword(value);
    }, [value]);

    const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newPassword = event.target.value;
        setPassword(newPassword);
        onChange(newPassword);
    }

    const EndIcon = ()=> (
        <div className={classes.toggle_visibility_container} onClick={() => setShowPassword(!showPassword)}>
            {showPassword && <VisibilityIcon/>}
            {!showPassword && <VisibilityOffIcon/>}
        </div>
    )

    return (
        <TextInput
            label={i18n.translate("PASSWORD")}
            error={error}
            type={showPassword ? TextInputType.TEXT : TextInputType.PASSWORD}
            value={password}
            disabled={disabled}
            onChange={onPasswordChange}
            startIcon={LockIcon}
            endIcon={EndIcon}
            className={className}
            fullWidth={fullWidth}
        />
    )
}

export default PasswordInput;
