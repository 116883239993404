import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    "fontFamily": "Nunito, sans-serif",
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
  },
  palette:{
    primary:{
      main: '#00d0b9',
      light: '#5aeadc',
      dark: '#019d8c'
    },
    secondary:{
      main: '#262728',
      light: '#4c4d4e',
      dark: '#2f3030'
    }
  }
});
 export default theme;
