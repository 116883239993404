import errors from './error'
import basic from './basic'
import seller from './seller'
import client from './client'
import contact from './contact'
import agency from './agency'
import articleDelivery from './articleDelivery'
import moneyRemittances from './moneyDelivery'
import topup from './topup'
import deliveryPerson from './deliveryPerson'
import remittancesAdditionalPrice from './remittancesAdditionalPrice'
import transaction from './transaction'
import moneyExchanges from './moneyExchanges'
import moneyServiceFees from './moneyServiceFees'
import invoice from './invoice'
import contactBankCard from './contactBankCard'
import packageCategoryCosts from './packageCategoryCosts'
import packageCategories from './packageCategories'
import packageRemittances from './packageRemittances'
import salePrices from './salePrices'
import dashboard from './dashboard'


export default Object({
    ...errors,
    ...basic,
    ...seller,
    ...client,
    ...contact,
    ...agency,
    ...articleDelivery,
    ...moneyRemittances,
    ...topup,
    ...remittancesAdditionalPrice,
    ...deliveryPerson,
    ...transaction,
    ...moneyExchanges,
    ...moneyServiceFees,
    ...invoice,
    ...dashboard,
    ...contactBankCard,
    ...packageCategoryCosts,
    ...packageCategories,
    ...packageRemittances,
    ...salePrices
})