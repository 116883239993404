import React from 'react';
import PrintButtonProperties from "./PrintButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import {Observer} from "mobx-react-lite";
import Button from "../../../ui_components/Button/Button";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useStyles} from "./PrintButton.style";
import clsx from "clsx";

const PrintButton: React.FC<PrintButtonProperties> = ({
                                                                                    onClick = () => {
                                                                                    },
                                                                                    showIcon = true,
                                                                                    showText = true,
                                                                                    disabled,
                                                                                    className = '',
                                                                                    rounded = false,
                                                                                    size = ButtonSize.DEFAULT,
                                                                                    type = ButtonType.DEFAULT,
                                                                                    multiPages = false
                                                                                }) => {

    const i18n = useI18nContext();
    const classes = useStyles();

    const handleOnclick = async () => {
        if (multiPages){
            onClick();
        }else{
            onClick();
            window.print();
        }
    }

    const text = i18n.translate("PRINT");
    const Icon = LocalPrintshopIcon;

    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={clsx(classes.print_button, classes)}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                size={size}
                type={type}
                icon={showIcon ? <Icon/> : <></>}
            />
        }
        return <Button
            onClick={handleOnclick}
            className={clsx(classes.print_button, classes)}
            disabled={disabled}
            tooltip={!showText ? text : ""}
            text={showText ? text : ""}
            size={size}
            type={type}
            startIcon={showIcon ? <Icon/> : <></>}
        />
    }
    return <Observer>
        {() => (
            <Btn/>
        )}
    </Observer>
}

export default PrintButton;
