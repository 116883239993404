import React from 'react';
import {useStyles} from "../MoneyDelivery.style";
import clsx from "clsx";
import MoneyDeliveryRowProperties from "./MoneyDeliveryRowProperties";
import ShowDate from "../../../shared_components/Info/ShowDate/ShowDate";
import ShowText from '../../../shared_components/Info/ShowText/ShowText';
import ButtonSize from '../../../ui_components/Button/ButtonSize';
import ButtonType from '../../../ui_components/Button/ButtonType';
import ShowMoneyDeliveryAmounts
    from '../../../shared_components/Info/ShowMoneyDeliveryAmounts/ShowMoneyDeliveryAmounts';
import MoneyDeliveryPrintButton from "../../../shared_components/Buttons/MoneyDeliveryPrintButton/MoneyDeliveryPrintButton";
import ShowOrderNumber from '../../../shared_components/Info/ShowOrderNumber/ShowOrderNumber';
import { useUserContext } from '../../../contexts/UserContext';
import ExpandoMenu from '../../../ui_components/ExpandoMenu/ExpandoMenu';
import ShowContactBankCard from '../../../shared_components/Info/ShowContactBankCard/ShowContactBankCard';
import UpdateDeliveryOrderStatusButton from '../../../shared_components/Buttons/UpdateDeliveryOrderStatusButton/UpdateDeliveryOrderStatusButton';
import Checkbox from '../../../ui_components/Checkbox/Checkbox';
import ShowOrderStatus from '../../../shared_components/Info/ShowOrderStatus/ShowOrderStatus';
import ProductOrderCommentsButton from '../../../shared_components/Buttons/ProductOrderCommentsButton/ProductOrderCommentsButton';
import ServiceName from '../../../entities/ServiceName';
import ShowPhoneNumber from '../../../shared_components/Info/ShowPhoneNumber/ShowPhoneNumber';

const MoneyDeliveryRowMedium: React.FC<MoneyDeliveryRowProperties> = ({
                                                                          moneyDeliveryOrder,
                                                                          selectedDeliveryOrderIds,
                                                                          onMoneyDeliveryOrderSelect,
                                                                          onUpdate
                                                                      }) => {


    const classes = useStyles();
    const userStore = useUserContext();

    const onMoneyDeliverySelect = () => {
        onMoneyDeliveryOrderSelect(moneyDeliveryOrder.id);
    }

    return (
        <div
            className={clsx(!moneyDeliveryOrder.difficultDelivery && classes.border_left_green, moneyDeliveryOrder.difficultDelivery && classes.border_left_purple, classes.money_info_container, userStore.isRootAgency() ? classes.money_info_container_grid_template_big : classes.money_info_container_grid_template_small)}>
            <div>
                <div className={classes.order_number_container}>
                    <Checkbox text={''} onChange={onMoneyDeliverySelect} selected={selectedDeliveryOrderIds.findIndex(t => t === moneyDeliveryOrder.id) >= 0}/>
                    <ShowOrderNumber orderNumber={moneyDeliveryOrder.orderNumber}/>
                </div>
                <ShowDate label={'Fecha'} timestamp={moneyDeliveryOrder.createdAt} className={classes.margin_top_4}/>
            </div>
            <div>
                <ShowText text={moneyDeliveryOrder.clientName} title={'Remitente'}/>
                <ShowText text={moneyDeliveryOrder.contact.name + (moneyDeliveryOrder.contact.lastName ? ' ' + moneyDeliveryOrder.contact.lastName : '')} title={'Destinatario'} className={classes.margin_top_8}/>
                {moneyDeliveryOrder.contact.mobilePhone &&
                        <ShowPhoneNumber value={moneyDeliveryOrder.contact.mobilePhone} showFlag={false}/>}
                {moneyDeliveryOrder.contact.homePhone &&
                        <ShowPhoneNumber value={moneyDeliveryOrder.contact.homePhone} showFlag={false}/>}
                {moneyDeliveryOrder.contact.secondMobilePhone &&
                        <ShowPhoneNumber value={moneyDeliveryOrder.contact.secondMobilePhone} showFlag={false}/>}
            </div>
            <div>
                {moneyDeliveryOrder && moneyDeliveryOrder.isBankCard &&
                    <div><small>Envío de tarjeta</small> <div><ShowContactBankCard bank={moneyDeliveryOrder.bankCardType} cardNumber={moneyDeliveryOrder.bankCardNumber}></ShowContactBankCard></div></div>}

                {moneyDeliveryOrder && !moneyDeliveryOrder.isBankCard &&
                    <div>
                        <small>Entrega</small>
                        <div>
                            <ShowText
                                text={`${moneyDeliveryOrder.contact.address}${moneyDeliveryOrder.contact.neighborhood ? '. ' + moneyDeliveryOrder.contact.neighborhood : ''}`}
                                title={moneyDeliveryOrder.contact.geo.name + '. ' + moneyDeliveryOrder.contact.geo.parentInfo}/>
                        </div>
                    </div>}

                {moneyDeliveryOrder.observations && <ShowText text={moneyDeliveryOrder.observations} title={'Observaciones'}/>}
            </div>
            <ShowMoneyDeliveryAmounts valueToReceive={moneyDeliveryOrder.amountToReceive}
                                      currencyReceive={moneyDeliveryOrder.currencyToReceive}/>

            <ShowOrderStatus status={moneyDeliveryOrder.currentStatus} />
            <ExpandoMenu>
                <>
                    <UpdateDeliveryOrderStatusButton
                        size={ButtonSize.SMALL}
                        serviceName={ServiceName.MONEY_DELIVERY}
                        productOrderId={moneyDeliveryOrder.id}
                        showText
                        type={ButtonType.SECONDARY}
                        onSave={onUpdate}
                    />
                    <MoneyDeliveryPrintButton
                        size={ButtonSize.SMALL}
                        productOrderIds={[moneyDeliveryOrder.id]}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                    <ProductOrderCommentsButton
                        size={ButtonSize.SMALL}
                        productOrderId={moneyDeliveryOrder.id}
                        productOrderNumber={moneyDeliveryOrder.orderNumber}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                </>
            </ExpandoMenu>
        </div>
    )
}

export default MoneyDeliveryRowMedium;
