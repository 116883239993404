import React from 'react';
import {useStyles} from "../PackageDelivery.style";
import clsx from "clsx";
import PackageDeliveryRowProperties from "./PackageDeliveryRowProperties";
import ShowDate from "../../../shared_components/Info/ShowDate/ShowDate";
import ShowText from '../../../shared_components/Info/ShowText/ShowText';
import ButtonSize from '../../../ui_components/Button/ButtonSize';
import ButtonType from '../../../ui_components/Button/ButtonType';
import ShowOrderNumber from '../../../shared_components/Info/ShowOrderNumber/ShowOrderNumber';
import { useUserContext } from '../../../contexts/UserContext';
import ExpandoMenu from '../../../ui_components/ExpandoMenu/ExpandoMenu';
import Checkbox from '../../../ui_components/Checkbox/Checkbox';
import ShowOrderStatus from '../../../shared_components/Info/ShowOrderStatus/ShowOrderStatus';
import PackageDeliveryPrintButton from '../../../shared_components/Buttons/PackageDeliveryPrintButton/PackageDeliveryPrintButton';
import ProductOrderCommentsButton from '../../../shared_components/Buttons/ProductOrderCommentsButton/ProductOrderCommentsButton';
import ServiceName from '../../../entities/ServiceName';
import ShowPhoneNumber from '../../../shared_components/Info/ShowPhoneNumber/ShowPhoneNumber';
import ShowPackageDeliveryPersonStatus from '../../../shared_components/Info/ShowPackageDeliveryPersonStatus/ShowPackageDeliveryPersonStatus';
import UpdatePackageDeliveryOrderStatusButton from '../../../shared_components/Buttons/UpdatePackageDeliveryOrderStatusButton/UpdatePackageDeliveryOrderStatusButton';

const PackageDeliveryRowMedium: React.FC<PackageDeliveryRowProperties> = ({
                                                                          packageDeliveryOrder,
                                                                          selectedDeliveryOrderIds,
                                                                          onPackageDeliveryOrderSelect,
                                                                          onUpdate
                                                                      }) => {


    const classes = useStyles();
    const userStore = useUserContext();

    const onPackageDeliverySelect = () => {
        onPackageDeliveryOrderSelect(packageDeliveryOrder.id);
    }

    return (
        <div
            className={clsx(!packageDeliveryOrder.difficultDelivery && classes.border_left_green, packageDeliveryOrder.difficultDelivery && classes.border_left_purple, classes.package_info_container, userStore.isRootAgency() ? classes.package_info_container_grid_template_big : classes.package_info_container_grid_template_small)}>
            <div>
                <div className={classes.order_number_container}>
                    <Checkbox text={''} onChange={onPackageDeliverySelect} selected={selectedDeliveryOrderIds.findIndex(t => t === packageDeliveryOrder.id) >= 0}/>
                    <ShowOrderNumber orderNumber={packageDeliveryOrder.orderNumber}/>
                </div>
                <ShowDate label={'Fecha'} timestamp={packageDeliveryOrder.createdAt} className={classes.margin_top_4}/>
            </div>
            <div>
                <ShowText text={packageDeliveryOrder.clientName} title={'Remitente'}/>
                <ShowText text={packageDeliveryOrder.contact.name + (packageDeliveryOrder.contact.lastName ? ' ' + packageDeliveryOrder.contact.lastName : '')} title={'Destinatario'} className={classes.margin_top_8}/>
                {packageDeliveryOrder.contact.mobilePhone &&
                        <ShowPhoneNumber value={packageDeliveryOrder.contact.mobilePhone} showFlag={false}/>}
                {packageDeliveryOrder.contact.homePhone &&
                        <ShowPhoneNumber value={packageDeliveryOrder.contact.homePhone} showFlag={false}/>}
                {packageDeliveryOrder.contact.secondMobilePhone &&
                        <ShowPhoneNumber value={packageDeliveryOrder.contact.secondMobilePhone} showFlag={false}/>}
            </div>
            <div>
                <div>
                    <small>Entrega</small> 
                    <div>
                        <ShowText
                            text={`${packageDeliveryOrder.contact.address}${packageDeliveryOrder.contact.neighborhood ? '. ' + packageDeliveryOrder.contact.neighborhood : ''}`}
                            title={packageDeliveryOrder.contact.geo.name + '. ' + packageDeliveryOrder.contact.geo.parentInfo}/>
                    </div>
                </div>
                {packageDeliveryOrder.observations && <ShowText text={packageDeliveryOrder.observations} title={'Observaciones'}/>}
            </div>
            <div>
                <ShowPackageDeliveryPersonStatus status={packageDeliveryOrder.currentDeliveryPersonStatus}/>
            </div>
            <ShowOrderStatus status={packageDeliveryOrder.currentStatus} />
            <ExpandoMenu>
                <>
                    <UpdatePackageDeliveryOrderStatusButton
                        size={ButtonSize.SMALL}
                        serviceName={ServiceName.ARTICLE_DELIVERY}
                        productOrderId={packageDeliveryOrder.id}
                        currentDeliveryPersonStatus={packageDeliveryOrder.currentDeliveryPersonStatus}
                        showText
                        type={ButtonType.SECONDARY}
                        onSave={onUpdate}
                    />
                    <PackageDeliveryPrintButton
                        size={ButtonSize.SMALL}
                        productOrderIds={[packageDeliveryOrder.id]}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                    <ProductOrderCommentsButton
                        size={ButtonSize.SMALL}
                        productOrderId={packageDeliveryOrder.id}
                        productOrderNumber={packageDeliveryOrder.orderNumber}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                </>
            </ExpandoMenu>
        </div>
    )
}

export default PackageDeliveryRowMedium;
