import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    modal_full_container: {
        position: 'absolute'
    },
    header_container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: 0,
        padding: '16px',
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary.light,
    },
    title: {
        fontSize: "25px",
        fontWeight: 600,
        "@media print": {
            display: 'none'
        }
    },
    close_button: {
        position: 'absolute',
        right: '20px',
        top: '20px',
        color: theme.palette.common.white,
        cursor: 'pointer',
        borderRadius: '4px',
        display: 'flex',
        "&:hover": {
            backgroundColor: theme.palette.secondary.dark,
        }
    },
    content_container: {
        width: '100%',
        height: '100%',
        minHeight: 500,
        display: 'flex',
        padding: '16px',
        boxSizing: 'border-box',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: "url(/webb.png)",
    }
}));

export {useStyles}