import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  modal_container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    margin: 0,
    padding: 0,
    "@media print": {
      zoom: "80%",
      width: "100%",
      height: "100%",
    },
  },
  view_invoice_container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    marginBottom: '20px'
  },
  page_break: {
    pageBreakBefore: "always"
  },
  invoice_container: {
    display: "flex",
    flexDirection: "column",
    width: "800px",
    marginTop: "16px",
    height: "calc(100vh - 185px)",
    overflow: "auto",
    overflowX: "hidden",
    backgroundColor: theme.palette.common.white,
    borderRadius: "4px",
    padding: "16px",
    border: `1px solid ${theme.palette.grey[200]}`,
    "@media print": {
      width: "880px",
      marginLeft: "260px",
      boxShadow: "none",
      padding: "0",
      border: `none`,
      height: "100%",
      overflow: "hidden",
      overflowX: "hidden",
      margin: "0cm",
    },
  },
  invoice: {
    padding: '10px'
  },
  toolbar_container: {
    display: "flex",
    width: "100%",
    boxSizing: "border-box",
    justifyContent: "flex-end",
    backgroundColor: theme.palette.common.white,
    padding: "16px",
    "@media print": {
      display: "none",
    },
    "& > *:not(:last-child)": {
      marginRight: "16px",
    },
  },
  invoice_data_container: {
    display: "flex",
    padding: "8px 0",
    flexDirection: "column",
    position: "relative",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    // maxWidth: '50%',
    "& svg": {
      maxWidth: "300px",
      maxHeight: "100px",
    },
  },
  column_container: {
    display: "grid",
    gridTemplateColumns: "250px auto 150px",
    "@media print": {
      gridTemplateColumns: "280px 400px 150px",
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  last_column: {
    alignItems: "flex-end",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    padding: "0 8px",
  },
  title: {
    fontSize: "20px",
    textAlign: "center",
    textTransform: "uppercase",
  },
  order_item_container: {
    marginTop: "10px",
    borderTop: "2px solid #4a4a4a",
    paddingTop: "10px",
    "@media print": {
      borderTop: "none",
    },
  },
  margin_top_4: {
    marginTop: "4px",
  },
  margin_top_8: {
    marginTop: "8px",
  },
  margin_top_8_deliver: {
    marginTop: "8px",
  },
  name: {
    fontWeight: "bold",
    marginRight: "0px",
  },
  columnOne: {
    width: "5%",
  },
  columnTwo: {
    width: "85%",
  },
  columnThree: {
    width: "10%",
  },
  top_header: {
    width:'100%',
    display: 'flex',
    flexDirection: 'row'
  },
  resume_container : {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'end',
    alignSelf:'end',
    alignContent:'end'
  },
  resume : {
    width: '100%',
    height: '100%',
    alignItems: 'end',
    alignContent:'end'
  },
  column_name : {
    height:'100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    marginLeft: 'auto',
    marginRight: '0px',
    marginTop:'0',
    marginBottom:'auto'
  },
  column_data :{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginRight: '0',
    marginTop:'0',
    marginBottom:'auto'
  },
  data : {
    marginLeft: '0px',
    alignItems:'flex-end',
    textOverflow:'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  margin_top_4_deliver: {
    marginTop: '4px'
  },
  phone_text: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  spaced_text: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  additional_text: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '40px',
    marginBottom: '-15px',
  },
  margin_left: {
    marginLeft: '40px',
  },
}));
export { useStyles };
