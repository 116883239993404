import React, {useEffect} from 'react';
import {useI18nContext} from "../../contexts/I18nContext";
import {useStyles} from "./MoneyDelivery.style";
import Box from "../../ui_components/Box/Box";
import Width from "../../ui_components/common/enums/Width";
import Alignment from "../../ui_components/common/enums/Alignment";
import {Observer} from "mobx-react-lite";
import MessageCard from "../../ui_components/MessageCard/MessageCard";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {useCommonContext} from "../../contexts/CommonContext";
import MoneyDeliveryStore from './MoneyDeliveryStore';
import MoneyDeliveryRow from './MoneyDeliveryRow/MoneyDeliveryRow';
import UserStore from '../../stores/UserStore';
import ButtonType from '../../ui_components/Button/ButtonType';
import Checkbox from '../../ui_components/Checkbox/Checkbox';
import MoneyDeliveryPrintButton from '../../shared_components/Buttons/MoneyDeliveryPrintButton/MoneyDeliveryPrintButton';
import FilterInput from '../../shared_components/Inputs/FilterInput/FilterInput';
import DateTimeRangeSelect from '../../ui_components/DateTimeRageSelect/DateTimeRangeSelect';
import CurrencySelect from '../../shared_components/Selects/CurrencySelect/CurrencySelect';
import Button from '../../ui_components/Button/Button';
import RemittanceTypeSelect from '../../shared_components/Selects/RemittanceTypeSelect/RemittanceTypeSelect';
import GeoSelect from '../../shared_components/Selects/GeoSelect/GeoSelect';
import { GeoLevel } from '../../shared_components/Selects/GeoSelect/GeoSelectProperties';
import Currency from '../../entities/Currency';
import Geo from '../../entities/Geo';
import UpdateDeliveryOrdersStatusButton from '../../shared_components/Buttons/UpdateDeliveryOrdersStatusButton/UpdateDeliveryOrdersStatusButton';

const MoneyDelivery: React.FC = () => {
    const commonStore = useCommonContext();
    const userStore = new UserStore();
    const i18n = useI18nContext();
    const store = new MoneyDeliveryStore(commonStore, userStore);
    const classes = useStyles();

    useEffect(() => {
        store.getElements();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onMoneyDeliveryOrderSelect = (moneyRemittanceId: string) => {
        const index = store.selectedMoneyRemittanceIds.findIndex(t => t === moneyRemittanceId);
        store.selectedMoneyRemittanceIds = index >= 0 ?
            store.selectedMoneyRemittanceIds.filter(t => t !== moneyRemittanceId) :
            [...store.selectedMoneyRemittanceIds, moneyRemittanceId];
    }

    const onDateChange = (from: number | undefined, to: number | undefined) => {
        store.setFromFilter(from);
        store.setToFilter(to);
    }

    const onCurrencyChange = (newCurrency: Currency) =>
        (store.setCurrencyFilter(newCurrency));

    const onTypeChange = (newType: string) =>
        (store.setTypeFilter(newType));

    const onGeoChange = (newGeo?: Geo) =>
        (store.setGeoFilter(newGeo));

    const clearFilter = async () => {
        await store.clearFilter()
    }

    const onUpdate = async () => {
        await store.getElements();
    }

    const onUpdateAndUnselectAll = async () => {
        store.selectedMoneyRemittanceIds = [];
        onMoneyDeliveryOrderToggleSelectAll(false);
        await store.getElements();
    }

    const onMoneyDeliveryOrderToggleSelectAll = (value: boolean) => {
        store.selectedMoneyRemittanceIds = value ? store.moneyRemittances.map(t => t.id) : [];
    }

    return (
        <Observer>
            {() => (
                <div className={classes.money_container}>
                    <Box className={classes.filter_container} width={Width.FULL_WIDTH}
                         justifyContent={Alignment.SPACE_BETWEEN}>
                        <FilterInput value={store.filter}
                                     placeholder={i18n.translate("MONEY_DELIVERY_ORDER_FILTER_PLACEHOLDER")}
                                     onChange={(value) => {
                                         store.setFilter(value)
                                     }} onEnter={() => store.search()}/>
                        <DateTimeRangeSelect selectedFrom={store.fromFilter} selectedTo={store.toFilter}
                                             label={i18n.translate("DATES")}
                                             onChange={onDateChange}/>
                        <CurrencySelect
                            showSelectAllOption={true}
                            fullWidth
                            selected={store.currencyFilter}
                            onChange={onCurrencyChange}
                        />
                        <RemittanceTypeSelect selected={store.typeFilter} onChange={onTypeChange}/>
                        <GeoSelect selected={store.geoFilter} levels={[GeoLevel.CITY, GeoLevel.STATE]}
                                   onChange={onGeoChange}/>
                        <div className={classes.filter_buttons_container}>
                            <Button
                                onClick={() => {
                                    store.search()
                                }}
                                type={ButtonType.PRIMARY}
                                className={classes.search_btn}
                                text={i18n.translate("SEARCH")}
                            />
                            <Button
                                onClick={clearFilter}
                                className={classes.search_btn}
                                text={i18n.translate("CLEAN")}
                            />
                        </div>
                    </Box>
                    <Box className={classes.filter_container} width={Width.FULL_WIDTH}
                         justifyContent={Alignment.SPACE_BETWEEN}>                        
                        <div className={classes.options_buttons_container}>
                            <Checkbox
                                text={''}
                                selected={store.selectedMoneyRemittanceIds.length === store.moneyRemittances.length}
                                onChange={onMoneyDeliveryOrderToggleSelectAll}
                                className={classes.select_all_checkbox}/>
                            <MoneyDeliveryPrintButton productOrderIds={store.selectedMoneyRemittanceIds}
                                disabled={store.selectedMoneyRemittanceIds.length === 0}
                                showText={true}
                                type={ButtonType.SECONDARY}/>
                            <UpdateDeliveryOrdersStatusButton
                                className={classes.marginLeft5px}
                                disabled={store.selectedMoneyRemittanceIds.length === 0}
                                onSave={onUpdateAndUnselectAll}
                                type={ButtonType.SECONDARY}
                                productOrderIds={store.selectedMoneyRemittanceIds}/>
                        </div>
                        
                    </Box>
                    {!store.isEmpty &&
                    <div className={classes.money_table}>
                        <div className={classes.elements_container}>
                            {store.moneyRemittances.map((moneyRemittance, i) => (
                                <MoneyDeliveryRow
                                    selectedDeliveryOrderIds={store.selectedMoneyRemittanceIds}
                                    onMoneyDeliveryOrderSelect={onMoneyDeliveryOrderSelect}
                                    moneyDeliveryOrder={moneyRemittance} key={i}
                                    onUpdate={onUpdate}
                                />
                            ))}
                        </div>
                    </div>}
                    {store.isEmpty &&
                    <MessageCard icon={AttachMoneyIcon} title={i18n.translate("NO_MONEY_REMITTANCES_TITLE")}
                                subtitle={i18n.translate("NO_MONEY_REMITTANCES_SUBTITLE")}/>}
                </div>
            )}
        </Observer>
    )
}

export default MoneyDelivery;
