import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    side_menu_container: {
        backgroundColor: `${theme.palette.secondary.light}`,
        boxSizing: 'border-box',
        height: '100%',
        width: '250px',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1
    },
    menu_items_container: {
        marginTop: '8px'
    },
    main_tools_container: {
        display: 'flex'
    },
    menu_item_container: {
        flexDirection: 'column',
        display: 'flex',
        textDecoration: 'none',
        boxSizing: 'border-box',
    },
    menu_item_title: {
        display: 'flex',
        justifyContent: 'flex-start',
        boxSizing: 'border-box',
        alignItems: 'center',
        color: theme.palette.common.white,
        fontSize: '16px',
        padding: '0 16px',
        height: '30px',
        "&:hover": {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.secondary.light,
            cursor: 'pointer',
        },
        "& svg": {
            fontSize: '20px',
            marginRight: '16px'
        },
    },
    title_with_children: {
        width: '100%',
        backgroundColor: theme.palette.secondary.dark,
        borderRadius: 0,
        color: theme.palette.common.white,
        "&:hover": {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.secondary.light,
            cursor: 'pointer'
        },
    },
    menu_item_children: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10px'
    },
    title_with_children_active:{
        fontWeight: 800,
        color: theme.palette.primary.main
    },
    active: {
        borderRight: '3px solid',
        boxSizing: 'border-box',
        color: theme.palette.primary.main,
        fontWeight: 800
    },
    main_action_container: {
        display: 'flex',
        height: '50px',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '8px',
        boxSizing: 'border-box'
    },
    user_info_container: {
        height: '60px',
        backgroundColor: theme.palette.grey["100"],
        padding: '8px',
    }
}));

export {useStyles}