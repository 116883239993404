const values = {
    HOST: 'https://api.alocubatravel.com',
    SEARCH_GEO_URL: "{HOST}/geo?page={page}&pageSize={pageSize}&filter={filter}&level={level}&parentIdFilter={parentIdFilter}",
    AUTH_URL: "{HOST}/authenticate/delivery-person",
    PASSWORD_VALIDATE_CODE_URL: "{HOST}/validate-password-code/delivery-person",
    GET_TRANSACTION_URL: "{HOST}/transaction/{id}",
    GET_DELIVERY_PERSON_BALANCE_URL: "{HOST}/delivery-person/{id}/balance",
    CHANGE_PASSWORD_DELIVERY_PERSON_URL: "{HOST}/change-password/delivery-person",

    GET_DELIVERY_PERSON_BALANCES_URL: "{HOST}/delivery-person/{id}/balance",

    GET_PRODUCT_ORDER_COMMENTS_URL: "{HOST}/product-order/{id}/comments",
    ADD_PRODUCT_ORDER_COMMENT_URL: "{HOST}/product-order/{id}/comment",

    // MONEY DELIVERY ORDER
    GET_MONEY_DELIVERY_STATS_URL: "{HOST}/service/app/money-delivery-app/delivery-person/{id}/stats",
    GET_MONEY_DELIVERY_URL: "{HOST}/service/app/money-delivery-app/delivery-person/{id}?from={from}&to={to}&filter={filter}&currency={currency}&type={type}&geoId={geoId}",
    FIND_MONEY_DELIVERY_URL: "{HOST}/service/app/money-remittance-app/{id}",    
    UPDATE_MONEY_DELIVERY_STATUS_URL: "{HOST}/product-order/money-delivery/{id}/status/{status}",
    UPDATE_MONEY_DELIVERY_ORDERS_STATUS_URL: "{HOST}/product-order/money-delivery/status/{status}",

    // ARTICLE DELIVERY ORDER
    GET_ARTICLE_DELIVERY_STATS_URL: "{HOST}/service/app/article-delivery-app/delivery-person/{id}/stats",
    GET_ARTICLE_DELIVERY_URL: "{HOST}/service/app/article-delivery-app/delivery-person/{id}?from={from}&to={to}&filter={filter}&geoId={geoId}",
    FIND_ARTICLE_DELIVERY_URL: "{HOST}/service/app/article-delivery-app/{id}",
    UPDATE_ARTICLE_DELIVERY_STATUS_URL: "{HOST}/product-order/article-delivery/{id}/status/{status}",
    UPDATE_ARTICLE_DELIVERY_ORDERS_STATUS_URL: "{HOST}/product-order/article-delivery/status/{status}",

    // PACKAGE DELIVERY ORDER
    GET_PACKAGE_DELIVERY_STATS_URL: "{HOST}/service/app/package-delivery-app/delivery-person/{id}/stats",
    GET_PACKAGE_DELIVERY_URL: "{HOST}/service/app/package-delivery-app/delivery-person/{id}?from={from}&to={to}&filter={filter}&geoId={geoId}",
    FIND_PACKAGE_DELIVERY_URL: "{HOST}/service/app/package-delivery-app/{id}/delivery-person/{deliveryPersonId}",
    UPDATE_PACKAGE_DELIVERY_STATUS_TO_RECEIVED_URL: "{HOST}/product-order/package-deliveries/delivery-persons-info/{deliveryPersonId}/received",
    UPDATE_PACKAGE_DELIVERY_STATUS_TO_DELIVERED_URL: "{HOST}/product-order/package-deliveries/delivery-persons-info/{deliveryPersonId}/delivered",
    
};

export default values;
