const values = {
    "CREDIT_CARD_NUMBER": "Número de la tarjeta",
    "NEW_CONTACT_BANK_CARD": "Nueva tarjeta",
    "EDIT_CONTACT_BANK_CARD": "Editar tarjeta",
    "CARD": "Tarjeta",
    "CARDS": "Tarjetas",
    "CONTACT_CARDS": "Tarjetas del contacto",
    "CARD_PLACEHOLDER": "Seleccione tarjeta",
    "BANDEC": "BANDEC",
    "CONTACT_BANK_CARD_FILTER": "Tipo de tarjeta",
    "CONTACT_BANK_CARD_FILTER_PLACEHOLDER": "Seleccione tipo de tarjeta",
    "NO_CONTACT_BANK_CARD_TITLE": "No hay tarjetas!!!",
    "NO_CONTACT_BANK_CARD_SUBTITLE": "Verifica si estas aplicando algún filtro",
    "REMOVE_CONTACT_BANK_CARD": "Eliminar tarjeta",
    "REMOVE_CONTACT_BANK_CARD_CONFIRMATION": "¿Está seguro que desea eliminar la tarjeta?"
}

export default values;