import Request from "../../model/Request";
import Response from "../../model/Response";
import {RestClient} from "../../util/RestClient";
import {Config} from "../../util/Config";
import ProductOrderStatsResponse from "../../model/productOrder/ProductOrderStatsResponse";
import PackageDeliveryOrderResponse from "../../model/productOrder/packageDelivery/PackageDeliveryOrderResponse";

export module PackageDeliveryService {
    export async function get(
        from: number = 0,
        to: number = 0,
        deliveryPersonId?: string,
        filter?: string,
        geoId?: string
    ): Promise<PackageDeliveryOrderResponse[]> {
        const url = Config.get("GET_PACKAGE_DELIVERY_URL", {
            from,
            to,
            id: deliveryPersonId,
            filter,
            geoId
        });
        const request = new Request(url);
        const response = await RestClient.get(request);
        if (response && response.success) {
            return response.data.map((t: any) => new PackageDeliveryOrderResponse(
                t.id,
                t.orderNumber,
                t.agencyId,
                t.clientId,
                t.clientName,
                t.contact,
                t.itemsAndCategories,
                t.createdAt,
                t.currentStatus,
                t.comments,
                t.difficultDelivery,
                t.currentDeliveryPersonStatus,
                t.observations
            ));
        }
        return [];
    }

    export async function find(id: string, deliveryPersonId?: string,): Promise<Response<PackageDeliveryOrderResponse>> {
        const url = Config.get("FIND_PACKAGE_DELIVERY_URL", {id, deliveryPersonId});
        const request = new Request(url)
        const response = await RestClient.get(request);
        if (response.success) {
            const t = response.data;
            if (t) {
                const packageRemittance = new PackageDeliveryOrderResponse(
                    t.id,
                    t.orderNumber,
                    t.agencyId,
                    t.clientId,
                    t.clientName,
                    t.contact,
                    t.itemsAndCategories,
                    t.createdAt,
                    t.currentStatus,
                    t.comments,
                    t.difficultDelivery,
                    t.currentDeliveryPersonStatus,
                    t.observations
                );
                return new Response<PackageDeliveryOrderResponse>(true, packageRemittance, response.error);
            }
        }
        return new Response<PackageDeliveryOrderResponse>(true, undefined, response.error, response.status);
    }

    export async function updateStatusToReceived(packageDeliveryId: string, deliveryPersonId: string): Promise<Response<any>> {
        const url = Config.get("UPDATE_PACKAGE_DELIVERY_STATUS_TO_RECEIVED_URL", {
            deliveryPersonId
        });
        const packageDeliveryIds = [packageDeliveryId];
        const request = new Request(url, packageDeliveryIds);
        return await RestClient.put(request);
    }

    export async function updateStatusToDelivered(packageDeliveryId: string, deliveryPersonId: string, comment: string): Promise<Response<any>> {
        const url = Config.get("UPDATE_PACKAGE_DELIVERY_STATUS_TO_DELIVERED_URL", {
            deliveryPersonId
        });
        const request = new Request(url, { comment, packageDeliveryIds: [packageDeliveryId] });
        return await RestClient.put(request);
    }

    export async function getPackageDeliveryStats(deliveryPersonId?: string): Promise<ProductOrderStatsResponse | undefined> {
        const url = Config.get("GET_PACKAGE_DELIVERY_STATS_URL", {
            id: deliveryPersonId
        });
        const request = new Request(url);
        const response = await RestClient.get(request);
        if (response && response.success) {
            return new ProductOrderStatsResponse(response.data.delayedRemittanceAmount, response.data.deliveryAmount);
        }
        return undefined;
    }

}