import {action, computed, makeObservable, observable} from "mobx";
import CommonStore from "../../stores/CommonStore";
import { PackageDeliveryService } from "../../service/productOrder/PackageDeliveryService";
import PackageDeliveryOrderResponse from "../../model/productOrder/packageDelivery/PackageDeliveryOrderResponse";
import UserStore from "../../stores/UserStore";
import Geo from "../../entities/Geo";

class PackageDeliveryStore {

    public packageRemittances: PackageDeliveryOrderResponse[];
    public selectedPackageRemittanceIds: string[];

    public filter?: string;
    public fromFilter: number | undefined;
    public toFilter: number | undefined;
    public geoFilter?: Geo;

    public commonStore: CommonStore;
    public userStore: UserStore;

    constructor(commonStore: CommonStore, userStore: UserStore) {
        this.packageRemittances = [];
        this.selectedPackageRemittanceIds = [];
        this.commonStore = commonStore;
        this.userStore = userStore;
        makeObservable(this, {
            selectedPackageRemittanceIds: observable,
            packageRemittances: observable,
            isEmpty: computed
        });
        
    }

    get isEmpty(): boolean {
        return this.packageRemittances.length === 0;
    }

    public setFilter(filter: string) {
        this.filter = filter;
    }

    public setFromFilter(from?: number) {
        this.fromFilter = from;
    }

    public setToFilter(to?: number) {
        this.toFilter = to;
    }

    public setGeoFilter(newGeo?: Geo) {
        this.geoFilter = newGeo;
    }

    @action
    public async clearFilter() {
        this.geoFilter = undefined;
        this.fromFilter = undefined;
        this.toFilter = undefined;
        this.filter = undefined;
        await this.getElements();
    }

    @action
    public async search() {
        await this.getElements();
    }

    @action
    public async getElements(): Promise<void> {
        this.commonStore.setLoading();
        const response = await PackageDeliveryService.get(
            this.fromFilter,
            this.toFilter,
            this.userStore.currentUser()?.id,
            this.filter,
            this.geoFilter?.id
        );
        this.packageRemittances = response;
        this.commonStore.removeLoading();
    }

}

export default PackageDeliveryStore;