import React, {useState} from 'react';
import {useLocation} from 'react-router';
import EmailInput from "../../shared_components/Inputs/EmailInput/EmailInput";
import PasswordInput from "../../shared_components/Inputs/PasswordInput/PasswordInput";
import Button from "../../ui_components/Button/Button";
import ButtonType from "../../ui_components/Button/ButtonType";
import {useStyles} from "./SignInPage.style";
import Direction from "../../ui_components/common/enums/Direction";
import FlexContainer from "../../ui_components/FlexContainer/FlexContainer";
import {useI18nContext} from "../../contexts/I18nContext";
import {useCommonStyles} from "../../ui_components/common/CommonStyles.style";
import {useUserContext} from "../../contexts/UserContext";
import {Observer} from "mobx-react-lite";
import {motion} from "framer-motion";
// import Link from "../../ui_components/Link/Link";
// import HelpIcon from '@mui/icons-material/Help';
import useOnEnterPress from '../../hooks/EnterPress';
import TopupAgenciesTextLogo from '../../ui_components/Icon/icons/TopupAgenciesTextLogo';


const SignInPage: React.FC = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const i18n = useI18nContext();
    const userStore = useUserContext();
    const classes = useStyles();
    const commonClasses = useCommonStyles();

    const location = useLocation();
    const urlLabel = new URLSearchParams(location.search).get("label");
    const label = urlLabel ? urlLabel : undefined

    if (userStore.isAuthenticated()) {
        window.location.href = "/dashboard";
    }

    const onclick = async () => {
        await userStore.login(email, password);
    }
    useOnEnterPress(onclick,[userStore, email, password]);

    const shouldShowLabel = () => label && i18n.translate(label) !== label && !userStore.error

    const renderNotification = (translateKey: string, clazz: any) => (
        <motion.div className={clazz} exit={{opacity: 0, y: -50}} initial={{opacity: 0, y: -50}}
                    animate={{opacity: 1, y: 0}}>
            {i18n.translate(translateKey)}
        </motion.div>
    )

    return (
        <Observer>
            {() => (
                <FlexContainer direction={Direction.COLUMN} fullHeight className={classes.sign_in_page_container}>
                    <div className={classes.form_container}>
                        <div className={classes.header}>
                            <TopupAgenciesTextLogo fontSize={120}/>
                        </div>
                        <div className={classes.body}>
                            <EmailInput value={email} onChange={setEmail}/>
                            <PasswordInput className={commonClasses.marginTop}
                                           onChange={setPassword} value={password}/>
                            <Button
                                loading={userStore.loading}
                                className={classes.sign_in_btn}
                                fullWidth
                                type={ButtonType.SECONDARY}
                                onClick={onclick}
                                text={i18n.translate("SIGN_IN")}
                            />
                            {/* <Link startIcon={HelpIcon} className={commonClasses.marginTop}
                                  text={i18n.translate("FORGOT_PASSWORD")} to={'/recover-password'}/> */}
                        </div>
                        <div className={classes.notification_container}>
                            {shouldShowLabel() && renderNotification(label!!, classes.label)}
                        </div>
                        <div className={classes.notification_container}>
                            {userStore.error && renderNotification(userStore.error, classes.error)}
                        </div>
                    </div>
                </FlexContainer>
            )}
        </Observer>
    )
}

export default SignInPage;
