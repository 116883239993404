const values = {
    "APP_NAME": "Topup Agencias",
    "EMAIL": "Email",
    "PHONE": "Phone",
    "PASSWORD": "Password",
    "NAME": "Name",
    "LASTNAME": "Lastname",
    "DESCRIPTION": "Description",
    "CONFIRM_PASSWORD": "Confirm password",
    "SIGN_IN": "Sign in",
    "SELLERS": "Sellers",
    "DASHBOARD": "Dashboard",
    "FILTER": "Filter",
    "LOADING": "Loading",
    "MONTH_1": "January",
    "MONTH_2": "February",
    "MONTH_3": "March",
    "MONTH_4": "April",
    "MONTH_5": "May",
    "MONTH_6": "June",
    "MONTH_7": "Julio",
    "MONTH_8": "August",
    "MONTH_9": "September",
    "MONTH_10": "October",
    "MONTH_11": "November",
    "BALANCE": "Balance",
    "MONTH_12": "December",
    "ITEMS_PER_PAGE": "Items per page",
    "SEARCH": "Search",
    "FIRST": "First",
    "LAST": "Last",
    "NEXT": "Next",
    "PREVIOUS": "Previous",
    "STATUS": "Status",
    "SAVE": "Save",
    "NO": "No",
    "YES": "Yes",
}

export default values