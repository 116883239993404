import React from 'react';
import ShowArticleDeliveryArticlesProperties from "./ShowArticleDeliveryArticlesProperties";
import {useStyles} from "./ShowArticleDeliveryArticles.style";
import clsx from "clsx";

const ShowArticleDeliveryArticles: React.FC<ShowArticleDeliveryArticlesProperties> = ({
                                                        articles,
                                                        className = '',
                                                  }) => {

    const classes = useStyles();

    return (
        <div className={clsx(classes.show_price_container, className)}>
            <div>
                <div className={classes.number}>
                    <div className={classes.title}>{`Artículos:`}</div>
                    <div className={classes.value}>
                        {articles.map(article => (
                            <div className={classes.article_item}>{article.articleName} - Cant: {article.amount}</div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ShowArticleDeliveryArticles;