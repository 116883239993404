import esLocale from "date-fns/locale/es";
import {format as fnFormat} from 'date-fns';

export module DateTime {
    export function format(date: Date | null): string {
        if (date) {
            return fnFormat(date, 'EEEE, d MMMM yyyy hh:mm aa', {locale: esLocale});
        }
        return "-";
    }
}