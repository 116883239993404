const values = {
    "CONTACTS": "Contactos",
    "CONTACT": "Contacto",
    "CLIENT_CONTACTS": "Contactos del cliente",
    "NEW_CONTACT": "Nuevo contacto",
    "EDIT_CONTACT": "Editar contacto",
    "CLIENT_CONTACT_FILTER_PLACEHOLDER": "Nombre, teléfono",
    "NO_CONTACTS_TITLE": "No hay contactos!!!",
    "NO_CONTACTS_SUBTITLE": "Verifica si estas aplicando algún filtro",
    "REMOVE_CONTACT": "Eliminar contacto",
    "REMOVE_CONTACT_CONFIRMATION": "¿Está seguro que desea eliminar el contacto?",
    "SEARCH_CONTACT_PLACEHOLDER": "Nombre, Número",
    "NO_CONTACTS_SEARCH": "No se encontraron contactos",
    "SELECT_CONTACT_PLACEHOLDER": "Seleccione Contacto",
    "ADDRESS": "Dirección",
    "SEARCH_CONTACTS_PLACEHOLDER": "Puedes buscar contactos por su nombre, teléfono o nombre del cliente",
    "NO_CLIENT_SELECT_PLACEHOLDER": "Seleccione primero un cliente",
    "NO_CLIENT_SELECT_HINT": "Click en Seleccione Cliente",
}

export default values;