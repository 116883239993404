import React from 'react';
import ButtonProperties from "./ButtonProperties";
import ButtonType from "./ButtonType";
import ButtonVariant from "./ButtonVariant";
import ButtonSize from "./ButtonSize";
import clsx from "clsx";
import {useStyles} from "./Button.style";
import Loading from "../PageLoading/Loading";
import {Tooltip} from "@material-ui/core";


const Button: React.FC<ButtonProperties> = ({
                                                text,
                                                onClick,
                                                disabled = false,
                                                type = ButtonType.DEFAULT,
                                                variant = ButtonVariant.DEFAULT,
                                                tooltip,
                                                size = ButtonSize.DEFAULT,
                                                startIcon = <></>,
                                                endIcon = <></>,
                                                fullWidth = false,
                                                className = '',
                                                loading = false
                                            }: ButtonProperties) => {
    const classes = useStyles();
    const getSizeClass = () => {
        switch (size) {
            default:
            case ButtonSize.DEFAULT:
                return classes.medium;
            case ButtonSize.SMALL:
                return classes.small;
            case ButtonSize.LARGE:
                return classes.large;
        }
    }

    const getTypeClass = () => {
        switch (type) {
            default:
            case ButtonType.DEFAULT:
                return classes.default;
            case ButtonType.PRIMARY:
                return classes.primary;
            case ButtonType.SECONDARY:
                return classes.secondary;
        }
    }

    const handleClick = () => {
        if (!disabled && !loading) {
            onClick();
        }
    }

    const containerClasses = clsx(
        classes.button_container,
        getSizeClass(),
        getTypeClass(),
        fullWidth && classes.full_width,
        (disabled || loading) && classes.disabled,
        className)
    return (
        <Tooltip title={tooltip || ""}>
            <div
                className={containerClasses}
                onClick={handleClick}>
                <div className={classes.start_icon}>{loading ? <Loading/> : startIcon}</div>
                {text && <div className={classes.text}>{text}</div>}
                <div className={classes.end_icon}>{endIcon}</div>
            </div>
        </Tooltip>
    )
}

export default Button;