import React from 'react';
import ArticleDeliveryRowProperties from "./ArticleDeliveryRowProperties";
import ViewMedium from "../../../ui_components/MediaView/ViewMedium";
import ArticleDeliveryRowMedium from "./ArticleDeliveryRowMedium";
import ViewLarge from "../../../ui_components/MediaView/ViewLarge";
import ViewSmall from "../../../ui_components/MediaView/ViewSmall";
import ArticleDeliveryRowSmall from "./ArticleDeliveryRowSmall";

const ArticleDeliveryRow: React.FC<ArticleDeliveryRowProperties> = ({
                                                    articleDeliveryOrder,
                                                    selectedDeliveryOrderIds,
                                                    onArticleDeliveryOrderSelect,
                                                    onUpdate
                                                }) => {
    return (
        <>
            <ViewSmall>
                <ArticleDeliveryRowSmall onUpdate={onUpdate} onArticleDeliveryOrderSelect={onArticleDeliveryOrderSelect} articleDeliveryOrder={articleDeliveryOrder} selectedDeliveryOrderIds={selectedDeliveryOrderIds}/>
            </ViewSmall>
            <ViewMedium>
                <ArticleDeliveryRowMedium onUpdate={onUpdate} onArticleDeliveryOrderSelect={onArticleDeliveryOrderSelect} articleDeliveryOrder={articleDeliveryOrder}  selectedDeliveryOrderIds={selectedDeliveryOrderIds}/>
            </ViewMedium>
            <ViewLarge>
                <ArticleDeliveryRowMedium onUpdate={onUpdate} onArticleDeliveryOrderSelect={onArticleDeliveryOrderSelect} articleDeliveryOrder={articleDeliveryOrder}  selectedDeliveryOrderIds={selectedDeliveryOrderIds}/>
            </ViewLarge>
        </>
    )
}

export default ArticleDeliveryRow;
