import Request from "../model/Request";
import Response from "../model/Response";
import {RestClient} from "../util/RestClient";
import {Config} from "../util/Config";
import DeliveryPersonBalance from "../entities/DeliveryPersonBalance";

export module DeliveryPersonService {
    export async function getBalances(deliveryPersonId?: string): Promise<Response<DeliveryPersonBalance[]>> {
        const url = Config.get("GET_DELIVERY_PERSON_BALANCES_URL", { id: deliveryPersonId });
        const request = new Request(url)
        const response = await RestClient.get(request)
        console.log(777, response)
        if (response.success) {
            const responseData = response.data;
            if (responseData) {
                const data = responseData.map((t: any) => new DeliveryPersonBalance(
                    t.id,
                    t.agencyId,
                    t.amount,
                    t.currency,
                    t.entityId));
                return new Response<DeliveryPersonBalance[]>(true, data, response.error)
            }
        }
        return new Response<DeliveryPersonBalance[]>(false, undefined, response.error, response.status);
    }

}