import React from 'react';
import MoneyDeliveryRowProperties from "./MoneyDeliveryRowProperties";
import ViewMedium from "../../../ui_components/MediaView/ViewMedium";
import MoneyDeliveryRowMedium from "./MoneyDeliveryRowMedium";
import ViewLarge from "../../../ui_components/MediaView/ViewLarge";
import ViewSmall from "../../../ui_components/MediaView/ViewSmall";
import MoneyDeliveryRowSmall from "./MoneyDeliveryRowSmall";

const MoneyDeliveryRow: React.FC<MoneyDeliveryRowProperties> = ({
                                                    moneyDeliveryOrder,
                                                    selectedDeliveryOrderIds,
                                                    onMoneyDeliveryOrderSelect,
                                                    onUpdate
                                                }) => {
    return (
        <>
            <ViewSmall>
                <MoneyDeliveryRowSmall onUpdate={onUpdate} onMoneyDeliveryOrderSelect={onMoneyDeliveryOrderSelect} moneyDeliveryOrder={moneyDeliveryOrder} selectedDeliveryOrderIds={selectedDeliveryOrderIds}/>
            </ViewSmall>
            <ViewMedium>
                <MoneyDeliveryRowMedium onUpdate={onUpdate} onMoneyDeliveryOrderSelect={onMoneyDeliveryOrderSelect} moneyDeliveryOrder={moneyDeliveryOrder}  selectedDeliveryOrderIds={selectedDeliveryOrderIds}/>
            </ViewMedium>
            <ViewLarge>
                <MoneyDeliveryRowMedium onUpdate={onUpdate} onMoneyDeliveryOrderSelect={onMoneyDeliveryOrderSelect} moneyDeliveryOrder={moneyDeliveryOrder}  selectedDeliveryOrderIds={selectedDeliveryOrderIds}/>
            </ViewLarge>
        </>
    )
}

export default MoneyDeliveryRow;
