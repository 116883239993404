import React from 'react';
import {useStyles} from "./ShowPackageDeliveryPersonStatus.style";
import clsx from "clsx";
import {Tooltip} from "@material-ui/core";
import ShowPackageDeliveryPersonStatusProperties from "./ShowPackageDeliveryPersonStatusProperties";
import ProductOrderStatus from "../../../entities/productOrder/ProductOrderStatus";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LocationOnIcon from '@mui/icons-material/LocationOn';


const ShowPackageDeliveryPersonStatus: React.FC<ShowPackageDeliveryPersonStatusProperties> = ({
                                                                  status,
                                                                  className = '',
                                                                  tooltip,
                                                                  label,
                                                              }) => {
    const classes = useStyles();
    if (!status) return <></>

    const getIcon = () => {
        switch (status) {
            case ProductOrderStatus.ASSIGNED:
                return <LocalShippingIcon/>;
            case ProductOrderStatus.RECEIVED:
                return <LocationOnIcon/>;
        }
    }
    const getText = () => {
        switch (status) {
            case ProductOrderStatus.ASSIGNED:
                return 'Envío en camino hacia usted. Cuando lo recibas, actualiza el estado, por favor.';
            case ProductOrderStatus.RECEIVED:
                return 'Envío en sus manos. Cuando lo entregues, actualiza el estado, por favor.';
        }
    }

    return (
        <Tooltip title={tooltip || ""}>
            <div className={clsx(classes.display_field_container, className)}>
                <div className={classes.value}>
                    {getIcon()}
                    {getText()}
                </div>
            </div>
        </Tooltip>
    )
}
export default ShowPackageDeliveryPersonStatus;