class DeliveryPersonBalance {
    public id: string;
    public agencyId: string;
    public amount: number;
    public currency: string;
    public entityId: string;


    constructor(
        id: string,
        agencyId: string,
        amount: number,
        currency: string,
        entityId: string
    ) {
        this.id = id;
        this.agencyId = agencyId;
        this.amount = amount;
        this.currency = currency;
        this.entityId = entityId;
    }

}

export default DeliveryPersonBalance;