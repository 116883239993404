import { Observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useCommonContext } from '../../contexts/CommonContext';
import { useI18nContext } from '../../contexts/I18nContext';
import { useUserContext } from '../../contexts/UserContext';
import CurrencySelect from '../../shared_components/Selects/CurrencySelect/CurrencySelect';
import Box from '../../ui_components/Box/Box';
import Button from '../../ui_components/Button/Button';
import Alignment from '../../ui_components/common/enums/Alignment';
import DateTimeRangeSelect from '../../ui_components/DateTimeRageSelect/DateTimeRangeSelect';
import MessageCard from '../../ui_components/MessageCard/MessageCard';
import ShowPagination from '../../ui_components/ShowPagination/ShowPagination';
import { useStyles } from './Transactions.style';
import TransactionsStore from './TransactionsStore';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Width from '../../ui_components/common/enums/Width';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import TransactionsRow from './TransactionsRow/TransactionsRow';

const Transactions: React.FC = () => {
    const classes = useStyles();
    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const userStore = useUserContext();
    const store = new TransactionsStore(commonContext, userStore.currentUser()!!.id);

    useEffect(() => {
        store.search();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onPaginationChange = async (page: number, pageSize: number) => {
        await store.goToPage(page, pageSize);
    }

    return <Observer>
        {() => (
            <>
                <div className={classes.contacts_container}>
                        <div className={classes.body}>
                            <Box className={classes.filter_container} width={Width.FULL_WIDTH}
                                 justifyContent={Alignment.SPACE_BETWEEN}>
                                <DateTimeRangeSelect
                                    selectedFrom={store.from}
                                    selectedTo={store.to}
                                    label={i18n.translate("DATE_RANGE")}
                                    onChange={(from, to) => {
                                        store.from = from;
                                        store.to = to;
                                    }}/>
                                <CurrencySelect selected={store.currency} onChange={(value) => {
                                    store.currency = value
                                }}/>
                                <div className={classes.btn_container}>
                                    <Button
                                        onClick={() => {
                                            store.search()
                                        }}
                                        className={classes.search_btn}
                                        text={i18n.translate("SEARCH")}
                                    />
                                    <Button
                                        onClick={() => {
                                            store.cleanFilter()
                                        }}
                                        className={classes.search_btn}
                                        startIcon={<FilterAltOffIcon/>}
                                        tooltip={i18n.translate("CLEAN_FILTER")}
                                    />
                                </div>
                            </Box>
                            
                            {!store.isEmpty &&
                            <div className={classes.contacts_table}>
                                <ShowPagination onChange={onPaginationChange}
                                                elementLabel={i18n.translate("TRANSACTIONS")}
                                                pagination={store.pagination}/>
                                <div>
                                    {store.transactions.map((transaction, i) => (
                                        <TransactionsRow transaction={transaction}/>
                                    ))}
                                </div>
                                <ShowPagination
                                    onChange={onPaginationChange}
                                    elementLabel={i18n.translate("TRANSACTIONS")}
                                    pagination={store.pagination}/>
                            </div>
                            }
                            {store.isEmpty &&
                            <MessageCard icon={AccountBalanceIcon} title={i18n.translate("NO_TRANSACTIONS_TITLE")}
                                         subtitle={i18n.translate("NO_TRANSACTIONS_SUBTITLE")}/>}
                        </div>
                    </div>
            </>
        )}
    </Observer>
}

export default Transactions;
