import React from 'react';
import {ThemeProvider} from '@material-ui/styles';
import theme from './theme/default';
import {I18nContextProvider} from "./contexts/I18nContext";
import {UserContextProvider} from "./contexts/UserContext";
import {CommonContextProvider} from "./contexts/CommonContext";
import I18nStore from "./stores/I18nStore";
import Language from "./i18n/Language";
import {BreakpointProvider} from 'react-socks';
import UserStore from "./stores/UserStore";
import CommonStore from "./stores/CommonStore";
import {ConfirmProvider} from "material-ui-confirm";
import { ToastProvider } from 'react-toast-notifications';

const userStore = new UserStore();
const commonStore = new CommonStore(userStore);
const i18NStore = new I18nStore(Language.ES);


const Providers: React.FC = ({
                                 children
                             }
) => {
    //LLAMA EL HOOK AQUI
    return (
        <I18nContextProvider value={i18NStore}>
            <UserContextProvider value={userStore}>
                <CommonContextProvider value={commonStore}>
                    <ThemeProvider theme={theme}>
                        <ConfirmProvider>
                            <BreakpointProvider>
                                <ToastProvider>
                                    {children}
                                </ToastProvider>
                            </BreakpointProvider>
                        </ConfirmProvider>
                    </ThemeProvider>
                </CommonContextProvider>
            </UserContextProvider>
        </I18nContextProvider>

    );
}

export default Providers;
