import {action, makeAutoObservable, runInAction} from "mobx";
import Currency from "../../entities/Currency";
import Pagination from "../../entities/Pagination";
import Transaction from "../../entities/Transaction";
import TransactionStatus from "../../entities/TransactionStatus";
import { TransactionService } from "../../service/TransactionService";
import CommonStore from "../../stores/CommonStore";

class ViewTransactionsStore {

    public deliveryPersonId: string;
    public isModalOpen: boolean;
    public loading: boolean;
    public commonStore: CommonStore;
    public pagination: Pagination;
    public transactions: Transaction[];
    public from?: number;
    public to?: number;
    public status?: TransactionStatus;
    public currency?: Currency;


    constructor(commonStore: CommonStore, deliveryPersonId: string) {
        this.deliveryPersonId = deliveryPersonId;
        this.isModalOpen = false;
        this.loading = false;
        this.commonStore = commonStore;
        this.pagination = new Pagination(1, 15, 0, 0);
        this.transactions = [];
        makeAutoObservable(this);
    }

    @action
    public async search(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        this.loading = true;
        const response = await TransactionService.get(
            this.deliveryPersonId,
            page,
            pageSize,
            this.from,
            this.to,
            this.status,
            this.currency
        );
        if (response.success) {
            runInAction(() => {
                this.transactions = response.data?.data || [];
                this.pagination = response.data?.pagination || this.pagination;
            })
        }
        this.commonStore.processErrors(response);
        this.loading = false;
    }

    @action
    public async openModal() {
        this.isModalOpen = true;
        await this.search();
    }

    @action
    public async cleanFilter() {
        this.currency = undefined;
        this.from = undefined;
        this.to = undefined;
        await this.search();
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    get isEmpty(): boolean {
        return this.transactions.length === 0;
    }

    @action
    public async goToPage(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        await this.search(page, pageSize);
    }

}

export default ViewTransactionsStore;