const values = {
    "INVOICE": "Facturas",
    "INVOICE_DATE_FILTER": "Seleccione fecha",
    "NO_INVOICE_TITLE": "No hay facturas!!!",
    "NO_INVOICE_SUBTITLE": "Verifica si estas aplicando algún filtro",
    "INVOICE_PENDING": "Pendiente de pago",
    "INVOICE_PAYED": "Pagada",
    "INVOICE_CANCELED": "Cancelada",
    "VIEW_INVOICE": "Ver factura",
    "INVOICE_NO": "Factura número",
    "INVOICE_STATUS_FILTER": "Estado de la factura",
    "INVOICE_STATUS_FILTER_PLACEHOLDER": "Seleccione estado",
    "INVOICE_ALL_DESCRIPTION": "Todas las facturas",
    "INVOICE_PENDING_DESCRIPTION": "Facturas pendientes de pago",
    "INVOICE_PAYED_DESCRIPTION": "Facturas pagadas",
    "INVOICE_CANCELED_DESCRIPTION": "Facturas canceladas",
    "ALL_INVOICES": "Todas",
    "PAYMENT_PENDING_INVOICE_TITLE": "Factura pendiente de pago",
    "PAYMENT_DONE_INVOICE_TITLE": "Comprobante de pago",
    "INVOICE_CANCELLED_TITLE": "Factura cancelada",
    "PAYMENT_DONE_INVOICE_WATERMARK_TITLE": "Pagada",
    "INVOICE_CANCELLED_WATERMARK_TITLE": "Cancelada",
    "PAY_INVOICE": "Pagar factura",
    "PAYMENT_TYPE_CASH": "Efectivo",
    "PAYMENT_TYPE_CASH_DESCRIPTION": "Pago con efectivo",
    "PAYMENT_TYPE_CHECK": "Cheque",
    "PAYMENT_TYPE_CHECK_DESCRIPTION": "Pago con cheque",
    "PAYMENT_TYPE_CREDIT": "Crédito",
    "PAYMENT_TYPE_CREDIT_DESCRIPTION": "Pago con tarjeta de crédito",
    "PAYMENT_TYPE_DEBIT": "Débito",
    "PAYMENT_TYPE_DEBIT_DESCRIPTION": "Pago con tarjeta de débito",
    "PAYMENT_TYPE_WIRE_TRANSFER": "Transferencia",
    "PAYMENT_TYPE_WIRE_TRANSFER_DESCRIPTION": "Pago con transferencia bancaria",
    "PAYMENT_TYPE_ZELLE": "Zelle",
    "PAYMENT_TYPE_ZELLE_DESCRIPTION": "Pago con Zelle",
    "PAYMENT_TYPE_DISTANCE": "A distancia",
    "PAYMENT_TYPE_DISTANCE_DESCRIPTION": "Pago a distancia",
    "PAYMENT_TYPE_FILTER": "Tipo de pago",
    "PAYMENT_TYPE_FILTER_PLACEHOLDER": "Seleccione tipo de pago",
    "ADD_PAYMENT": "Adicionar pago",
    "NO_PAYMENTS_TITLE": "No hay pagos aún!!!",
    "NO_PAYMENT_SUBTITLE": "Puedes adicionar varios pagos hasta cubrir el valor total de la factura",
    "REMAINING_PAYMENT": "Cantidad pendiente",
    "OVERPAYMENT": "La cantidad establecida supera el costo de la factura",
    "ALL_PAYMENTS_ADDED": "El costo de la factura está cubierto",
    "SELLER": "Vendedor",
    "ADDRESSEE": "Destinatario",
    "SERVICE": "Servicio",
    "PAYMENT": "Pago",
    "WITH_FEE": "Costo adicional de",
    "NO_FEE": "Sin costo extra",
    "INVOICE_AMOUNT": "Valor de la factura",
    "TOTAL_TAXES": "Total de impuestos",
    "TOTAL_TO_PAY": "Total a pagar",
    "PAYMENT_TYPE": "Tipo de pago",
}
export default values;