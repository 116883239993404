import React from 'react';
import {useStyles} from "../MoneyDelivery.style";
import clsx from "clsx";
import {motion} from "framer-motion";
import MoneyDeliveryRowProperties from "./MoneyDeliveryRowProperties";
import ButtonSize from '../../../ui_components/Button/ButtonSize';
import ButtonType from '../../../ui_components/Button/ButtonType';
import Checkbox from '../../../ui_components/Checkbox/Checkbox';
import ShowOrderNumber from '../../../shared_components/Info/ShowOrderNumber/ShowOrderNumber';
import ShowDate from '../../../shared_components/Info/ShowDate/ShowDate';
import ShowText from '../../../shared_components/Info/ShowText/ShowText';
import ShowMoneyDeliveryAmounts from '../../../shared_components/Info/ShowMoneyDeliveryAmounts/ShowMoneyDeliveryAmounts';
import ShowContactBankCard from '../../../shared_components/Info/ShowContactBankCard/ShowContactBankCard';
import UpdateDeliveryOrderStatusButton from '../../../shared_components/Buttons/UpdateDeliveryOrderStatusButton/UpdateDeliveryOrderStatusButton';
import ViewInvoiceButton from '../../../shared_components/Buttons/MoneyDeliveryPrintButton/MoneyDeliveryPrintButton';
import ProductOrderCommentsButton from '../../../shared_components/Buttons/ProductOrderCommentsButton/ProductOrderCommentsButton';
import ServiceName from '../../../entities/ServiceName';
import ShowPhoneNumber from '../../../shared_components/Info/ShowPhoneNumber/ShowPhoneNumber';


const MoneyDeliveryRowSmall: React.FC<MoneyDeliveryRowProperties> = ({
                                                                                    moneyDeliveryOrder,
                                                                                    onMoneyDeliveryOrderSelect,
                                                                                    onUpdate
                                                                                }) => {

    const classes = useStyles();

    const onMoneyDeliverySelect = () => {
        onMoneyDeliveryOrderSelect(moneyDeliveryOrder.id);
    }

    return (
        <div className={clsx(classes.money_info_container_small)}>
            <div className={classes.first_row_small}>
                <div>
                    <div className={classes.order_number_container}>
                        <Checkbox text={''} onChange={onMoneyDeliverySelect}/>
                        <ShowOrderNumber orderNumber={moneyDeliveryOrder.orderNumber}/>
                    </div>
                    <ShowDate label={'Fecha'} timestamp={moneyDeliveryOrder.createdAt} className={classes.margin_top_4}/>
                </div>
                <ShowMoneyDeliveryAmounts valueToReceive={moneyDeliveryOrder.amountToReceive}
                                      currencyReceive={moneyDeliveryOrder.currencyToReceive}/>
            </div>
            <div className={classes.second_row_small}>
                <ShowText text={moneyDeliveryOrder.clientName} title={'Remitente'}/>
                <div>
                    <ShowText text={moneyDeliveryOrder.contact.name + (moneyDeliveryOrder.contact.lastName ? ' ' + moneyDeliveryOrder.contact.lastName : '')} title={'Destinatario'}/>
                    {moneyDeliveryOrder.contact.mobilePhone &&
                        <ShowPhoneNumber value={moneyDeliveryOrder.contact.mobilePhone} showFlag={false}/>}
                    {moneyDeliveryOrder.contact.homePhone &&
                            <ShowPhoneNumber value={moneyDeliveryOrder.contact.homePhone} showFlag={false}/>}
                    {moneyDeliveryOrder.contact.secondMobilePhone &&
                            <ShowPhoneNumber value={moneyDeliveryOrder.contact.secondMobilePhone} showFlag={false}/>}
                </div>
            </div>
            <div>
                {moneyDeliveryOrder && moneyDeliveryOrder.isBankCard &&
                    <div><small>Envío de tarjeta</small> <div><ShowContactBankCard bank={moneyDeliveryOrder.bankCardType} cardNumber={moneyDeliveryOrder.bankCardNumber}></ShowContactBankCard></div></div>}

                {moneyDeliveryOrder && !moneyDeliveryOrder.isBankCard &&
                    <div>
                        <small>Entrega</small> 
                        <div>
                            <ShowText
                                text={`${moneyDeliveryOrder.contact.address}${moneyDeliveryOrder.contact.neighborhood ? '. ' + moneyDeliveryOrder.contact.neighborhood : ''}`}
                                title={moneyDeliveryOrder.contact.geo.name + '. ' + moneyDeliveryOrder.contact.geo.parentInfo}/>
                        </div>
                    </div>}

                {moneyDeliveryOrder.observations && <ShowText text={moneyDeliveryOrder.observations} title={'Observaciones'}/>}
            </div>
            <div className={classes.menu_container_small}>
                <motion.div animate={{scale: [0, 1]}} transition={{delay: 0.2, duration: 0.2}}>
                    <UpdateDeliveryOrderStatusButton
                        size={ButtonSize.SMALL}
                        serviceName={ServiceName.MONEY_DELIVERY}
                        productOrderId={moneyDeliveryOrder.id}
                        showText
                        type={ButtonType.SECONDARY}
                        onSave={onUpdate}
                    />
                </motion.div>
                <motion.div animate={{scale: [0, 1]}} transition={{delay: 0.2, duration: 0.2}}>
                    <ViewInvoiceButton
                        size={ButtonSize.SMALL}
                        productOrderIds={[moneyDeliveryOrder.id]}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                </motion.div>
                <motion.div animate={{scale: [0, 1]}} transition={{delay: 0.2, duration: 0.2}}>
                    <ProductOrderCommentsButton
                        size={ButtonSize.SMALL}
                        productOrderId={moneyDeliveryOrder.id}
                        productOrderNumber={moneyDeliveryOrder.orderNumber}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                </motion.div>
            </div>
        </div>
    )
}

export default MoneyDeliveryRowSmall;
