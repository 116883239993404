import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import { ProductOrderService } from "../../../service/productOrder/ProductOrderService";
import DeliveryOrderCommentResponse from "../../../model/productOrder/DeliveryOrderCommentResponse";

class ProductOrderCommentsStore {

    public productOrderId: string;
    public productOrderComments?: DeliveryOrderCommentResponse[];
    public isModalOpen: boolean;
    public loading: boolean;
    public commonStore: CommonStore;

    constructor(commonStore: CommonStore, productOrderId: string) {
        this.productOrderId = productOrderId;
        this.productOrderComments = [];
        this.isModalOpen = false;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    @action
    public async loadProductOrderComments() {
        const response = await ProductOrderService.getProductOrderComments(this.productOrderId!);
        if (response.success && response.data) {
            this.productOrderComments = response.data;
        }
    }

    @action
    public async addComment(comment: string) {
        this.loading = true;
        const response = await ProductOrderService.addComment(this.productOrderId!, comment);
        if (response.success && response.data) {
            this.productOrderComments = response.data.comments;
        }
        this.loading = false;
    }

    @action
    public async openModal() {
        this.loading = true;
        this.isModalOpen = true;
        await this.loadProductOrderComments();
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }
}

export default ProductOrderCommentsStore;