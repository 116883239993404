import UpdateDeliveryOrderStatusButtonProperties from "./UpdateDeliveryOrderStatusButtonProperties";
import UpdateDeliveryOrderStatusStore from "./UpdateDeliveryOrderStatusStore";
import Modal from "../../../ui_components/Modal/Modal";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./UpdateDeliveryOrderStatusButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import useOnEnterPress from '../../../hooks/EnterPress';
import CommentInput from '../../Inputs/CommentInput/CommentInput';
import ProductOrderStatusSelect from "../../Selects/ProductOrderStatusSelect/ProductOrderStatusSelect";
import React from "react";
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import ButtonSize from "../../../ui_components/Button/ButtonSize";


const UpdateDeliveryOrderStatusButton: React.FC<UpdateDeliveryOrderStatusButtonProperties> = ({
                                                                  onClick = () => {
                                                                  },
                                                                  onSave = () => {
                                                                  },
                                                                  children,
                                                                  showIcon = true,
                                                                  showText = true,
                                                                  disabled,
                                                                  className = '',
                                                                  serviceName,
                                                                  productOrderId,
                                                                  rounded = false,
                                                                  showButton = true,
                                                                  size = ButtonSize.DEFAULT,
                                                                  type = ButtonType.DEFAULT
                                                              }) => {
    const classes = useStyles();

    const commonContext = useCommonContext();
    const store = new UpdateDeliveryOrderStatusStore(commonContext, productOrderId, serviceName);

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const onCloseModal = () => {
        store.closeModal();
    }

    const onCommentChange = (newComment: string) => (store.comment = newComment);
    const onStatusChange = (option: string) => {
       store.status = option;
    }

    const onOrderStatusSave = async () => {
        const saved = await store.save();
        if (saved) {
            store.closeModal();
            if (onSave) {
                onSave();
            }
        }
    }
    useOnEnterPress(onSave,[store]);

    const text = "Entregar";
    const Icon = PlaylistAddCheckIcon;
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? <Icon/> : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? <Icon/> : <></>}
        />
    }

    return <Observer>
        {() => (
            <>
                <Modal fullScreen={false} loading={store.loading} title={'Editar estado'} onClose={onCloseModal} open={store.isModalOpen}>
                    <div className={classes.modal_container}>
                        <ProductOrderStatusSelect fullWidth selected={store.status} onChange={onStatusChange} disabled={true}/>
                        <CommentInput
                            fullWidth
                            lines={3}
                            onChange={onCommentChange}
                            value={store.comment}
                            className={classes.input}/>
                        <Button
                            disabled={!store.enableButton}
                            loading={store.loading} className={classes.input} onClick={onOrderStatusSave}
                            type={ButtonType.SECONDARY}
                            text={'ENVIAR'}/>
                    </div>
                </Modal>
                <Btn/>
            </>
        )}
    </Observer>
}

export default UpdateDeliveryOrderStatusButton;
