const values = {
    "CATEGORIES": "Categorias",
    "PACKAGE_CATEGORY": "Categoria de paquete",
    "PACKAGE_CATEGORIES": "Categoria de paquetes",
    "NEW_PACKAGE_CATEGORY": "Nuevo categoria",
    "EDIT_PACKAGE_CATEGORY": "Editar categoria",
    "NO_PACKAGE_CATEGORY_TITLE": "No hay categorias!!!",
    "NO_PACKAGE_CATEGORY_SUBTITLE": "Verifica si estas aplicando algún filtro",
}

export default values;