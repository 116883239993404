import Currency from "../../../entities/Currency";
import DeliveryOrderComment from "../../../entities/productOrder/DeliveryOrderComment";
import ProductOrderStatus from "../../../entities/productOrder/ProductOrderStatus";
import ProductOrderContactResponse from "../ProductOrderContactResponse";

class MoneyDeliveryOrderResponse {
    public id: string;
    public orderNumber: string;
    public agencyId: string;
    public clientId: string;
    public clientName: string;
    public contact: ProductOrderContactResponse;
    public contactBankCardId: string;
    public createdAt: number;
    public amountToReceive: number;
    public currencyToReceive: Currency;
    public currentStatus: ProductOrderStatus;
    public isBankCard: boolean;
    public bankCardType: string;
    public bankCardNumber: string;
    public comments: DeliveryOrderComment[];
    public difficultDelivery: boolean;
    public observations?: string;

    constructor(id: string, orderNumber: string, agencyId: string, clientId: string, clientName: string, contact: ProductOrderContactResponse,
        contactBankCardId: string, createdAt: number, amountToReceive: number, currencyToReceive: Currency, currentStatus: ProductOrderStatus,
        isBankCard: boolean, bankCardType: string, bankCardNumber: string, comments: DeliveryOrderComment[], difficultDelivery: boolean, observations?: string) {
        this.id = id;
        this.orderNumber = orderNumber;
        this.agencyId = agencyId;
        this.clientId = clientId;
        this.clientName = clientName;
        this.contact = contact;
        this.contactBankCardId = contactBankCardId;
        this.createdAt = createdAt;
        this.amountToReceive = amountToReceive;
        this.currencyToReceive = currencyToReceive;
        this.currentStatus = currentStatus;
        this.isBankCard = isBankCard;
        this.bankCardType = bankCardType;
        this.bankCardNumber = bankCardNumber;
        this.comments = comments;
        this.difficultDelivery = difficultDelivery;
        this.observations = observations;
    }

}

export default MoneyDeliveryOrderResponse;