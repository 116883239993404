const values = {
    "CLIENTS": "Clientes",
    "CLIENT": "Cliente",
    "NEW_CLIENT": "Nuevo cliente",
    "EDIT_CLIENT": "Editar cliente",
    "CLIENT_FILTER_PLACEHOLDER": "Nombre, Correo",
    "NO_CLIENTS_TITLE": "No hay clientes!!!",
    "NO_CLIENTS_SUBTITLE": "Verifica si estas aplicando algún filtro",
    "REMOVE_CLIENT": "Eliminar cliente",
    "REMOVE_CLIENT_CONFIRMATION": "¿Está seguro que desea eliminar el cliente?",
    "SELECT_CLIENT_PLACEHOLDER": "Seleccione Cliente",
}
export default values;