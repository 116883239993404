import React from 'react';
import {useStyles} from "./ShowDate.style";
import ShowDateProperties from "./ShowDateProperties";
import clsx from "clsx";
import {useI18nContext} from "../../../contexts/I18nContext";
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';


const ShowDate: React.FC<ShowDateProperties> = ({
                                                          className = '',
                                                          timestamp,
                                                          label,
                                                          showTime = true
                                                      }) => {
    const classes = useStyles();
    const i18n = useI18nContext();
    if (!timestamp) {
        return <>-</>;
    }

    const formatTime = (timestamp: number) => {
        const date: Date = new Date(timestamp);
        const hour = date.getHours().toString().padStart(2, '0');
        const min = date.getMinutes().toString().padStart(2, '0');
        return `${hour}:${min}`;
    };

    const formatDate = (timestamp: number) => {
        const date = new Date(timestamp);
        const month = date.getMonth();
        const year = date.getFullYear();
        const day = date.getDate();
        return `${i18n.translate(`MONTH_${month + 1}`)} ${day}, ${year}`;
    };
    return (
        <div className={clsx(classes.date_container, className)}>
            <>
                {label && <div className={classes.label}>{`${label}:`}</div>}
                <div className={classes.date}><EventAvailableIcon/>{formatDate(timestamp)}</div>
                {showTime && <div className={classes.date}><QueryBuilderIcon/>{formatTime(timestamp)}</div>}
            </>
        </div>
    )
}
export default ShowDate;